import React from 'react';
import ConfigurationLayout from '../../../../common/layouts/ConfigurationLayout';
import VPSContent from './content';
import useAgency from '../../hooks/useAgency';

const VPSPage = () => {
  const { agency } = useAgency();

  return (
    <ConfigurationLayout>
      <VPSContent agencyName={agency?.name} />
    </ConfigurationLayout>
  );
};

export default VPSPage;
