import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Tabs, Form, Select, Switch } from 'antd';
import Button from '../../../../common/components/Button';
import { CHANNEL_TYPES } from '../../constants';
import {
  setIntersectionOutputs,
  setIntersectionSaved,
} from '../../store/slice';
import './style.css';
import { UserRole } from '../../../../common/enums';
import useUser from '../../../userAuth/hooks/useUser';
import { RequesterCommunicationType, RequesterModel } from '../../enums';

const { TabPane } = Tabs;

const LOW_PRIORITY_CENTRALIZED_OUTPUT_TYPES = [
  {
    key: 'NTCIP 1211',
    value: 'NTCIP 1211',
  },
  {
    key: 'NTCIP 1202',
    value: 'NTCIP 1202 (deviation from the standards)',
  },
];

const HIGH_PRIORITY_CENTRALIZED_OUTPUT_TYPES = [
  {
    key: 'NTCIP 1202',
    value: 'NTCIP 1202',
  },
  {
    key: 'NTCIP 1211',
    value: 'NTCIP 1211 (deviation from the standards)',
  },
];

const OUTPUT_TYPES = [
  { value: 0, label: 'None' },
  { value: 1, label: 'Rear 1 (F)(Gry/Wht)' },
  { value: 2, label: 'Rear 2 (W)(Blu/Wht)' },
  { value: 3, label: 'Rear 3 (S)(Vio/Wht)' },
  { value: 4, label: 'Rear 4 (Y)(Brn/Wht)' },
  { value: 5, label: 'AIP 1' },
  { value: 6, label: 'AIP 2' },
  { value: 7, label: 'AIP 3' },
  { value: 8, label: 'AIP 4' },
  { value: 9, label: 'AIP 5' },
  { value: 10, label: 'AIP 6' },
  { value: 11, label: 'AIP 7' },
  { value: 12, label: 'AIP 8' },
  { value: 13, label: 'AIP 9' },
  { value: 14, label: 'AIP 10' },
  { value: 15, label: 'AIP 11' },
  { value: 16, label: 'AIP 12' },
  { value: 17, label: 'AIP 13' },
  { value: 18, label: 'AIP 14' },
  { value: 19, label: 'AIP 15' },
  { value: 20, label: 'AIP 16' },
];

const CORE_OUTPUT_TYPES = [
  { value: 1, label: 'Sig 0' },
  { value: 2, label: 'Sig 1' },
  { value: 3, label: 'Sig 2' },
  { value: 4, label: 'Sig 3' },
  { value: 5, label: 'Sig 4' },
  { value: 6, label: 'Sig 5' },
  { value: 7, label: 'Sig 6' },
  { value: 8, label: 'Sig 7' },
];

const HIGH_PRIORITY_OUTPUT_TYPES = OUTPUT_TYPES;

const LOW_PRIORITY_OUTPUT_TYPES = [
  ...OUTPUT_TYPES,
  { value: 21, label: 'Class' },
  { value: 22, label: 'Conditional Priority' },
];

const OUTPUT_MODES = [
  { value: 1, label: 'First Come, First Served' },
  { value: 2, label: 'All Active Channels' },
];

const OUTPUT_ACTIVE_STATES = [
  { value: 0, label: 'Pulsing' },
  { value: 1, label: 'Solid' },
];

const ChannelOutputForm = ({
  label,
  name,
  outputTypes,
  isEditor,
  communicationType,
  numCentralizedOutputs = 255,
}) => {
  const showCentralizedOutputs = useMemo(
    () =>
      [
        RequesterCommunicationType.OFF,
        RequesterCommunicationType.CENTRALIZED,
        RequesterCommunicationType.NTCIP,
        RequesterCommunicationType.DISCRETE_NTCIP,
        RequesterCommunicationType.DISCRETE_CENTRALIZEDm,
      ].includes(communicationType),
    [communicationType]
  );
  const showOutputs = useMemo(
    () =>
      [
        RequesterCommunicationType.OFF,
        RequesterCommunicationType.DISCRETE,
        RequesterCommunicationType.DISCRETE_NTCIP,
        RequesterCommunicationType.DISCRETE_CENTRALIZED,
      ].includes(communicationType),
    [communicationType]
  );

  return (
    <Form.Item label={label}>
      <div
        className={`outputs-form__output-options ${+showCentralizedOutputs + +showOutputs === 1 &&
          'outputs-form__output-options--single'
          }`}
      >
        <Form.Item
          name={[...name, 'centralizedOutput']}
          hidden={!showCentralizedOutputs}
        >
          <Select disabled={!isEditor}>
            {Array.from({ length: numCentralizedOutputs }, (_, i) => (
              <Select.Option value={i + 1} key={i + 1}>
                {i + 1}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name={[...name, 'output']} hidden={!showOutputs}>
          <Select disabled={!isEditor}>
            {outputTypes.map(({ value, label: outputLabel }) => (
              <Select.Option value={value} key={value}>
                {outputLabel}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </div>
    </Form.Item>
  );
};

const OutputsForm = ({
  formData,
  channelNames,
  priority,
  onFormDataChange,
  resetToDefaults,
  model,
  communicationType,
}) => {
  const [form] = Form.useForm();
  const [isEditingOutputs, setIsEditingOutputs] = useState(false);

  const isCore = useMemo(
    () => [RequesterModel.C764, RequesterModel.G764].includes(model),
    [model]
  );

  const outputStandards = useMemo(
    () =>
      priority === 'lowPriority'
        ? LOW_PRIORITY_CENTRALIZED_OUTPUT_TYPES
        : HIGH_PRIORITY_CENTRALIZED_OUTPUT_TYPES,
    [priority]
  );
  const outputTypes = useMemo(() => {
    if (isCore) {
      return CORE_OUTPUT_TYPES;
    }

    return priority === 'lowPriority'
      ? LOW_PRIORITY_OUTPUT_TYPES
      : HIGH_PRIORITY_OUTPUT_TYPES;
  }, [priority, isCore]);

  const numCentralizedOutputs = useMemo(() => {
    if (priority === "highPriority" || model !== 'M764') {
      return 255;
    }
    return 6;
  }, [model, priority])

  const showNtcipConfig = useMemo(
    () =>
      [
        RequesterCommunicationType.NTCIP,
        RequesterCommunicationType.OFF,
        RequesterCommunicationType.DISCRETE_NTCIP,
      ].includes(communicationType),
    [communicationType]
  );
  const showActiveState = useMemo(() => {
    if (priority !== 'lowPriority') {
      return false;
    }
    return [
      RequesterCommunicationType.OFF,
      RequesterCommunicationType.DISCRETE,
      RequesterCommunicationType.DISCRETE_NTCIP,
      RequesterCommunicationType.DISCRETE_CENTRALIZED,
      RequesterCommunicationType.CENTRALIZED,
    ].includes(communicationType);
  }, [priority, communicationType]);

  useEffect(() => {
    form.setFieldsValue(formData);
  }, [form, formData, priority]);

  const handleFormDataChange = useCallback(() => {
    onFormDataChange(form.getFieldsValue());
  }, [form, onFormDataChange]);
  const channels = useMemo(() => formData?.channels ?? [], [formData]);

  const { userHasRole } = useUser();
  const isEditor = userHasRole([UserRole.ORG_ADMIN, UserRole.READ_WRITE]);

  return (
    <Form
      form={form}
      className="outputs-form"
      layout="vertical"
      onFieldsChange={(_, allFields) => handleFormDataChange(allFields)}
    >
      <div className="outputs-form__channel-options">
        <Form.Item
          hidden={!showNtcipConfig}
          name="type"
          label={
            <p>
              <b>
                {priority === 'lowPriority' ? 'Low' : 'High'} Priority Output
                Type
              </b>{' '}
              (applies to all channels)
            </p>
          }
        >
          <Select
            className="outputs-form__format-option"
            disabled={!isEditor}
            defaultActiveFirstOption={false}
          >
            {outputStandards.map((type) => (
              <Select.Option key={type.key} value={type.key}>
                {type.value}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          hidden={priority !== 'lowPriority'}
          name={['lowPriorityOutput', 'lowPriorityOutputMode']}
          label={
            <p>
              <b>Output</b> (applies to all channels)
            </p>
          }
        >
          <Select
            className="outputs-form__format-option"
            disabled={!isEditor}
            defaultActiveFirstOption={false}
          >
            {OUTPUT_MODES.map((item) => (
              <Select.Option key={item.value} value={item.value}>
                {item.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          hidden={!showActiveState}
          name={['lowPriorityOutput', 'lowPriorityActiveState']}
          label={
            <p>
              <b>Active State</b> (applies to all channels)
            </p>
          }
        >
          <Select
            className="outputs-form__format-option"
            disabled={!isEditor}
            defaultActiveFirstOption={false}
          >
            {OUTPUT_ACTIVE_STATES.map((item) => (
              <Select.Option key={item.value} value={item.value}>
                {item.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </div>

      <div className="outputs-form__warning-bar">
        <Switch
          checkedChildren="Cancel"
          unCheckedChildren="Edit"
          onClick={() => setIsEditingOutputs(!isEditingOutputs)}
        />
        <span className="outputs-form__edit-warning">
          {isEditingOutputs &&
            'Changing the output configuration may result in conflicts with the intersection timing plans.'}
        </span>
      </div>

      <div className="outputs-form__channels">
        {channels.map((_, index) => (
          <div key={index} className="outputs-form__channel">
            <Form.Item
              hidden
              name={['channels', index, 'channel']}
              value={`${index}`}
            />
            <b className="outputs-form__channel-title">
              Channel {CHANNEL_TYPES[index]}
              {` `}
              {channelNames[index] !== '' && `(${channelNames[index]})`}
            </b>
            <ChannelOutputForm
              label="Straight"
              name={['channels', index, 'straight']}
              outputTypes={outputTypes}
              isEditor={isEditor && isEditingOutputs}
              communicationType={communicationType}
              numCentralizedOutputs={numCentralizedOutputs}
            />
            <ChannelOutputForm
              label="Left"
              name={['channels', index, 'left']}
              outputTypes={outputTypes}
              isEditor={isEditor && isEditingOutputs}
              communicationType={communicationType}
              numCentralizedOutputs={numCentralizedOutputs}
            />
            <ChannelOutputForm
              label="Right"
              name={['channels', index, 'right']}
              outputTypes={outputTypes}
              isEditor={isEditor && isEditingOutputs}
              communicationType={communicationType}
              numCentralizedOutputs={numCentralizedOutputs}
            />
          </div>
        ))}
      </div>
      <Button
        className="outputs-form__reset"
        type="danger"
        size="sm"
        onClick={() => resetToDefaults()}
        hidden={!isEditor || !isEditingOutputs}
      >
        Reset Output Defaults
      </Button>
    </Form>
  );
};

const ApproachesOutputs = ({
  model,
  communicationType,
  outputs,
  lowPriorityOutput,
  channelNames,
  resetToDefaults,
}) => {
  const { lowPriority, highPriority } = outputs;
  const dispatch = useDispatch();

  return (
    <Tabs className="outputs-tabs" tabBarStyle={{ backgroundColor: '#f1efef' }}>
      <TabPane tab="Low Priority" key="lowPriorityOutputs">
        <OutputsForm
          model={model}
          communicationType={communicationType}
          formData={lowPriority}
          lowPriorityOutput={lowPriorityOutput}
          channelNames={channelNames}
          onFormDataChange={(fd) => {
            dispatch(setIntersectionOutputs({ ...outputs, lowPriority: fd }));
            dispatch(setIntersectionSaved(false));
          }}
          priority="lowPriority"
          resetToDefaults={resetToDefaults}
        />
      </TabPane>
      <TabPane tab="High Priority" key="highPriorityOutputs">
        <OutputsForm
          model={model}
          communicationType={communicationType}
          formData={highPriority}
          channelNames={channelNames}
          onFormDataChange={(fd) => {
            dispatch(setIntersectionOutputs({ ...outputs, highPriority: fd }));
            dispatch(setIntersectionSaved(false));
          }}
          priority="highPriority"
          resetToDefaults={resetToDefaults}
        />
      </TabPane>
    </Tabs>
  );
};

export default ApproachesOutputs;
