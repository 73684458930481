import { useMemo } from 'react';
import MetricsMap from '..';
import { DwellTimeMapLegend } from '../../MapLegend';
import {
  createBusStopIcon,
  getPaths,
} from '../../../pages/routeDetails/shapes';
import { createFlags } from '../../../../../common/utils/shapes';
import { metricColorMap } from '../../../../../common/constants';
import { Metric } from '../../../../../common/enums';

const createStops = (stops = []) =>
  stops.map((stop) =>
    createBusStopIcon({
      marker: stop,
      key: `stop-${stop.lat}-${stop.lon}`,
      onClick: () => {},
    })
  );

const DriveTimeMetricsMap = ({
  stops,
  segments,
  bounds,
  onSelectedMarkerChange,
  ...props
}) => {
  const flags = useMemo(() => createFlags(stops), [stops]);
  const icons = useMemo(() => createStops(stops), [stops]);
  const paths = useMemo(
    () =>
      getPaths(
        segments,
        (marker) =>
          marker?.metric?.isBetter || marker?.metric?.isBetter === null
            ? metricColorMap[Metric.DriveTime]
            : '#AD1515',
        onSelectedMarkerChange
      ),
    [segments, onSelectedMarkerChange]
  );

  const shapes = useMemo(
    () => [...flags, ...paths, ...icons],
    [flags, paths, icons]
  );

  return (
    <MetricsMap
      shapes={shapes}
      onSelectedMarkerChange={onSelectedMarkerChange}
      {...props}
    >
      <DwellTimeMapLegend />
    </MetricsMap>
  );
};

export default DriveTimeMetricsMap;
