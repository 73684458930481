import { useMemo, useState } from 'react';
import { Tabs } from 'antd';
import ConditionalPriority from '../ConditionalPriority';
import OffTripModeSettings from '../OffTripModeSettings';
import EditAgencySettings from '../EditAgencySettings';
import './style.css';
import { UserRole, UserType } from '../../../../common/enums';
import useUser from '../../../userAuth/hooks/useUser';

const { TabPane } = Tabs;

const AgencyFeatureTab = ({
  editFeaturePersistanceTSPResponse,
  editFeaturePersistanceTSP,
  updateConditionOccupancy,
  updateConditionScheduleAdherence,
  updateConditionalPriority,
  updateConditionalPriorityResponse,
  updateOffTripMode,
  updateOffTripModeResponse,
  agencyId,
}) => {
  const [activeTab, setactiveTab] = useState('analytics');
  const { userHasUserType, userHasRole } = useUser();

  const admin = useMemo(
    () =>
      userHasUserType(UserType.MioAdmin) || userHasRole([UserRole.ORG_ADMIN]),
    [userHasUserType, userHasRole]
  );

  return (
    <Tabs
      onChange={setactiveTab}
      defaultActiveKey={activeTab}
      className={'activity-tabs'}
    >
      <TabPane tab="Analytics" key="analytics">
        <EditAgencySettings
          editFeaturePersistanceTSP={editFeaturePersistanceTSP}
          createFPResponse={editFeaturePersistanceTSPResponse}
          admin={admin}
        />
      </TabPane>
      <TabPane tab="Conditional Priority" key="conditionalPriority">
        <ConditionalPriority
          updateConditionOccupancy={updateConditionOccupancy}
          updateConditionScheduleAdherence={updateConditionScheduleAdherence}
          updateConditionalPriority={updateConditionalPriority}
          updateConditionalPriorityResponse={updateConditionalPriorityResponse}
          agencyId={agencyId}
          admin={admin}
        ></ConditionalPriority>
      </TabPane>
      <TabPane tab="Off-Trip Mode" key="offTripMode">
        <OffTripModeSettings
          updateOffTripMode={updateOffTripMode}
          updateOffTripModeResponse={updateOffTripModeResponse}
          agencyId={agencyId}
          admin={admin}
        ></OffTripModeSettings>
      </TabPane>
    </Tabs>
  );
};
export default AgencyFeatureTab;
