import React from 'react';
import { withRouter } from 'react-router-dom';
import MapEmpty from '../../common/icons/MapEmpty.svg';
import './style.css';

const MioErrorPage = () => (
  <div className="mio-error-page">
    <img src={MapEmpty}></img>
    <h1>Something went wrong, please try again later.</h1>
  </div>
);

export default withRouter(MioErrorPage);
