import { useSelector } from 'react-redux';
import {
  useGetAgenciesQuery,
  useCreateAgencyMutation,
  useDeleteAgencyMutation,
  useGetAgencyQuery,
} from '../api';

const useAgencies = ({ regionId }) => {
  const { agencyId: sessionAgencyId } = useSelector(({ user }) => user);
  const { data: agency } = useGetAgencyQuery(
    { id: sessionAgencyId },
    {
      skip: !!regionId,
    }
  );

  const {
    data: agencies,
    isLoading,
    isError,
    error,
  } = useGetAgenciesQuery({ regionId: regionId ?? agency?.regionId });

  const [createAgency, createAgencyResponse] = useCreateAgencyMutation();
  const [deleteAgency, deleteAgencyResponse] = useDeleteAgencyMutation();

  return {
    agencies,
    isLoading,
    isError,
    error,
    createAgencyResponse,
    createAgency,
    deleteAgencyResponse,
    deleteAgency,
  };
};

export default useAgencies;
