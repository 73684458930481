import React from 'react';
import { Space } from 'antd';
import Table, { alphanumericSorter } from '../../../../common/components/Table';
import MakeModel from '../MakeModel';
import './style.css';

const DevicesTable = ({ devices, isLoading, onAssign, onDelete, onEdit }) => {
  const devicesColumns = [
    {
      key: 'serial',
      dataIndex: 'serial',
      title: 'Device Serial',
      width: 140,
      sorter: alphanumericSorter('serial'),
      sortDirections: ['ascend', 'descend'],
    },
    {
      key: 'gttSerial',
      dataIndex: 'gttSerial',
      title: 'Opticom Serial',
      width: 150,
      sorter: alphanumericSorter('gttSerial'),
      sortDirections: ['ascend', 'descend'],
    },
    {
      key: 'description',
      dataIndex: 'description',
      title: 'Description',
      width: 180,
      sorter: alphanumericSorter('description'),
      sortDirections: ['ascend', 'descend'],
    },
    {
      key: 'deviceMakeModel',
      dataIndex: 'make',
      title: 'Make & Model',
      width: 200,
      render: (_, { make, model }) => <MakeModel makeModel={[make, model]} />,
      sorter: alphanumericSorter('make'),
      sortDirections: ['ascend', 'descend'],
    },
    {
      key: 'addressMac',
      dataIndex: 'addressMac',
      title: 'MAC',
      width: 140,
      sorter: alphanumericSorter('addressMac'),
      sortDirections: ['ascend', 'descend'],
    },
    {
      key: 'addressLan',
      dataIndex: 'addressLan',
      title: 'LAN',
      width: 140,
      sorter: alphanumericSorter('addressLan'),
      sortDirections: ['ascend', 'descend'],
    },
    {
      key: 'addressWan',
      dataIndex: 'addressWan',
      title: 'WAN',
      width: 140,
      sorter: alphanumericSorter('addressWan'),
      sortDirections: ['ascend', 'descend'],
    },
    {
      key: 'imei',
      dataIndex: 'imei',
      title: 'IMEI',
      width: 140,
      sorter: alphanumericSorter('imei'),
      sortDirections: ['ascend', 'descend'],
    },
    {
      key: 'devcertId',
      dataIndex: 'devcertId',
      title: 'Dev Cert ID',
      width: 200,
      sorter: alphanumericSorter('devcertId'),
      sortDirections: ['ascend', 'descend'],
    },
    {
      key: 'actions',
      dataIndex: 'actions',
      title: 'Actions',
      fixed: 'right',
      width: 165,
      render: (_, record) => (
        <Space size="middle">
          <a onClick={() => onEdit(record)}>Edit</a>
          <a onClick={() => onAssign(record)} style={{ color: '#faad14' }}>
            Assign
          </a>
          <a style={{ color: 'red' }} onClick={() => onDelete(record)}>
            Delete
          </a>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Table
        className="tabs-table-devices"
        isLoading={isLoading}
        columns={devicesColumns}
        dataSource={devices}
        pagination={true}
        bordered={false}
        scroll={{ x: 1140 }}
      />
    </>
  );
};

export default DevicesTable;
