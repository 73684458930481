import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Period, SourceDevice } from '../../../common/enums';
import { fillEmptyDates } from '../../../common/utils/dateRange';
import { useGetChartMetricsQuery } from '../api';
import { selectTimeperiod } from '../../featurePersistence/store/selectors';

export const EquipmentState = {
  EQUIPPED: 'TSP-equipped',
  UNEQUIPPED: 'TSP-unequipped',
};

const getEquipmentStateFromSourceDevice = (sourceDevice) =>
  sourceDevice.indexOf('RT_GTFS') > -1
    ? EquipmentState.UNEQUIPPED
    : EquipmentState.EQUIPPED;

const useMetricChart = ({ routeName, metric }) => {
  const { dateRange, direction, periods } = useSelector(
    ({ routeFilters }) => routeFilters
  );
  const { startDate, endDate } = dateRange;
  const timeperiod = useSelector(selectTimeperiod);
  const { data: charts, isFetching: isLoading } = useGetChartMetricsQuery({
    routeName,
    dateRange,
    direction,
    periods,
    period: 'day',
    timeperiod,
  });

  const chartBySourceDevice = useMemo(
    () =>
      (charts ?? []).reduce((acc, chart) => {
        const { sourceDevice } = chart;

        if (!acc[sourceDevice]) {
          acc[sourceDevice] = [];
        }

        acc[sourceDevice].push(chart);

        return acc;
      }, {}),
    [charts]
  );

  const chart = useMemo(
    () =>
      !Object.keys(chartBySourceDevice).length
        ? []
        : Object.entries(chartBySourceDevice)
            .map(([sourceDevice, c]) => {
              const series = getEquipmentStateFromSourceDevice(sourceDevice);
              return fillEmptyDates({
                array: c
                  .map((item) => {
                    if (item[metric]?.secs == null) {
                      return null;
                    }

                    return {
                      ...item,
                      period: item.period,
                      series,
                      value: parseFloat(item[metric]?.secs / 60) || 0,
                    };
                  })
                  // Remove missing values
                  .filter((item) => item != null),

                period: Period.Day,
                startDate,
                endDate,
                series,
              });
            })
            .reduce((acc, item) => {
              acc.push(...item);
              return acc;
            }, []),
    [chartBySourceDevice, metric, startDate, endDate]
  );

  return { charts, chart, isLoading };
};

export default useMetricChart;
