import React, { useEffect, useState } from 'react';
import { Form as AntForm, Input as AntInput } from 'antd';
import FormModal from '../../../../common/components/FormModal';
import { intersectionSchema } from '../../schemas';
import openNotification from '../../../../common/components/notification';
import useCreateIntersection from '../../hooks/useCreateIntersection';
import useRelativeNavigate from '../../../../common/hooks/useRelativeNavigate';
import ConnectionErrorModal from '../NoConnectionErrorModal/index';
import { NO_CONNECTION_ERROR_MESSAGE as ERROR_MESSAGE } from '../../constants';

const AddIntersectionModal = ({ visible, onCancel }) => {
  const { create, newIntersection, error, isLoading, isSuccess, isError } =
    useCreateIntersection();

  const [addIntersectionData, setAddIntersectionData] = useState(null);

  const handleFormSubmit = (formData) => {
    setAddIntersectionData(formData);
    create(formData);
  };

  const navigate = useRelativeNavigate();

  useEffect(() => {
    if (isSuccess) {
      openNotification({
        message: 'Successfully Created Intersection',
        type: 'success',
      });
    }
  }, [isSuccess, isLoading, newIntersection]);

  useEffect(() => {
    if (isError) {
      ConnectionErrorModal(create, addIntersectionData, ERROR_MESSAGE);
    }
  }, [isError]);

  useEffect(() => {
    if (newIntersection) {
      navigate(newIntersection.serialNumber);
    }
  }, [navigate, newIntersection]);

  return (
    <div className="Create">
      <FormModal
        title="Please enter name and coordinates for new intersection"
        onSubmit={handleFormSubmit}
        visible={visible}
        onCancel={onCancel}
        destroyOnClose={true}
        validationSchema={intersectionSchema}
        validateOnChange={false}
        loading={isLoading}
        initialValues={{
          intersectionName: '',
          longitude: '',
          latitude: '',
        }}
      >
        {({
          handleSubmit: onSubmit,
          handleChange,
          values,
          isSubmitting,
          errors,
          setFieldValue,
        }) => (
          <div>
            <AntForm
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 12 }}
              onSubmit={onSubmit}
              onChange={handleChange}
            >
              <AntForm.Item
                label="Name"
                help={errors.intersectionName}
                required
              >
                <AntInput
                  type="text"
                  name="intersectionName"
                  onChange={handleChange}
                  disabled={isSubmitting}
                />
              </AntForm.Item>
              <AntForm.Item label="Latitude" help={errors.latitude} required>
                <AntInput
                  type="text"
                  name="latitude"
                  onChange={handleChange}
                  disabled={isSubmitting}
                  placeholder="Enter value in Decimal Degrees"
                />
              </AntForm.Item>
              <AntForm.Item label="Longitude" help={errors.longitude} required>
                <AntInput
                  type="text"
                  name="longitude"
                  onChange={handleChange}
                  disabled={isSubmitting}
                  placeholder="Enter value in Decimal Degrees"
                />
              </AntForm.Item>
            </AntForm>
          </div>
        )}
      </FormModal>
    </div>
  );
};

export default AddIntersectionModal;
