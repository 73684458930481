import {
  Form as AntForm,
  Col,
  Row,
  Radio,
  Select as AntSelect,
  Switch,
  Button,
} from 'antd';
import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import InfoTooltip from '../../../../common/components/InfoTooltip';
import SelectRange from '../../../../common/components/SelectRange';
import useMutationNotification from '../../../../common/hooks/useMutationNotification';
import './style.css';
import { TooltipText } from '../../../../common/constants';

const { Option: AntOption } = AntSelect;

const ConditionalPriority = ({
  updateConditionOccupancy,
  updateConditionScheduleAdherence,
  updateConditionalPriority,
  updateConditionalPriorityResponse,
  agencyId,
  admin,
}) => {
  const { conditionalPriority } = useSelector(
    ({ featurePersistence }) => featurePersistence
  );
  const [form] = AntForm.useForm();

  const [lateness, setLateness] = useState(conditionalPriority.lateness);
  const [latenessCheck, setLatenessCheck] = useState(
    conditionalPriority.schedule_adherence
  );
  const [calculationMode, setCalculationMode] = useState(
    conditionalPriority.requires
  );
  const [occupancyCheck, setOccupancyCheck] = useState(
    conditionalPriority.occupancy_threshold
  );
  const [occupancyValue, setOccupancyValue] = useState(
    conditionalPriority.occupancy
  );

  const radioOptions = [
    {
      label: 'And',
      value: 'ALL',
    },
    {
      label: 'Or',
      value: 'ANY',
    },
  ];

  const occupancyData = [
    { name: 'Empty', value: 'EMPTY' },
    { name: 'Many seats available', value: 'MANY_SEATS_AVAILABLE' },
    { name: 'Few seats available', value: 'FEW_SEATS_AVAILABLE' },
    { name: 'Standing room only', value: 'STANDING_ROOM_ONLY' },
    { name: 'Crushed standing room only', value: 'CRUSHED_STANDING_ROOM_ONLY' },
    { name: 'Full', value: 'FULL' },
    { name: 'Not accepting passengers', value: 'NOT_ACCEPTING_PASSENGERS' },
  ];

  useMutationNotification(updateConditionalPriorityResponse, {
    formatSuccessNotification: () => ({
      message: 'Conditional priority updated',
    }),
    formatErrorNotification: ({ message }) => ({
      message: 'Error while updating conditional priority ',
      description: message,
    }),
  });

  useEffect(
    () =>
      form.setFieldsValue({
        lateness: conditionalPriority.lateness,
        latenessEnable: conditionalPriority.schedule_adherence,
        minimumOccupancy: conditionalPriority.occupancy,
        occupancyEnable: conditionalPriority.occupancy_threshold,
        CalculationMode: conditionalPriority.requires,
      }),
    [form, conditionalPriority]
  );

  const handleFormSubmit = (values) => {
    updateConditionScheduleAdherence({
      lateness_threshold: values.lateness,
    });
    updateConditionOccupancy({
      occupancy_threshold: values.minimumOccupancy,
    });

    const conditions = {
      occupancy_threshold: values.occupancyEnable,
      schedule_adherence: values.latenessEnable,
    };

    updateConditionalPriority({
      conditions,
      agency_name: agencyId,
      requires: values.CalculationMode,
    });
  };

  return (
    <AntForm
      name="basic"
      autoComplete="off"
      form={form}
      colon={false}
      onFinish={handleFormSubmit}
    >
      <Row>
        <Col span="12">
          <div className="padding-form">
            <Row>
              <Col span="24">
                <h2>Conditional Priority*</h2>
              </Col>
            </Row>
            <Row>
              <Col span="12">Lateness</Col>
              <Col span="12" style={{ textAlign: 'right' }}>
                <div>
                  <div className="custom-flex-container">
                    <AntForm.Item name="lateness">
                      <SelectRange
                        style={{ width: '70px' }}
                        name="lateness"
                        value={lateness}
                        min={0}
                        max={10}
                        onChange={(value) => setLateness(value)}
                        disabled={!admin}
                      />
                    </AntForm.Item>
                    <AntForm.Item label="Minutes" name="latenessEnable">
                      <Switch
                        defaultChecked={latenessCheck}
                        onChange={(checked) => setLatenessCheck(checked)}
                        disabled={!admin}
                      />
                    </AntForm.Item>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col span="12">Calculation Mode</Col>
              <Col span="12" style={{ textAlign: 'right' }}>
                <div>
                  <div className="custom-flex-container">
                    <AntForm.Item label="" name="CalculationMode">
                      <Radio.Group
                        options={radioOptions}
                        onChange={({ target: { value } }) =>
                          setCalculationMode(value)
                        }
                        value={calculationMode}
                        optionType="button"
                        disabled={!latenessCheck || !occupancyCheck || !admin}
                      />
                    </AntForm.Item>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col span="12">
                Minimum Occupancy
                <InfoTooltip
                  text={TooltipText.ConditionalPrioriotyMinimumOccupancy}
                  className="custom-tooltip-margin"
                />
              </Col>
              <Col span="12" style={{ textAlign: 'right' }}>
                <div>
                  <div className="custom-flex-container">
                    <AntForm.Item
                      className="custom-full-width"
                      name="minimumOccupancy"
                    >
                      <AntSelect
                        name="minimumOccupancy"
                        onChange={(value) => setOccupancyValue(value)}
                        value={occupancyValue}
                        className="custom-full-width"
                        disabled={!admin}
                      >
                        {occupancyData.map((data) => (
                          <AntOption key={data.name} value={data.value}>
                            {data.name}
                          </AntOption>
                        ))}
                      </AntSelect>
                    </AntForm.Item>
                    <AntForm.Item
                      style={{ marginLeft: '10px' }}
                      name="occupancyEnable"
                    >
                      <Switch
                        defaultChecked={occupancyCheck}
                        onChange={(checked) => setOccupancyCheck(checked)}
                        disabled={!admin}
                      />
                    </AntForm.Item>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col span="12" style={{ fontSize: '10px' }}>
                *Applies to all routes
              </Col>
              {admin && (
                <Col span="12" style={{ textAlign: 'right' }}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={
                      latenessCheck ===
                        conditionalPriority.schedule_adherence &&
                      occupancyCheck ===
                        conditionalPriority.occupancy_threshold &&
                      lateness === conditionalPriority.lateness &&
                      occupancyValue === conditionalPriority.occupancy &&
                      calculationMode === conditionalPriority.requires
                    }
                  >
                    Save
                  </Button>
                </Col>
              )}
            </Row>
          </div>
        </Col>
      </Row>
    </AntForm>
  );
};

export default ConditionalPriority;
