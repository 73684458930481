import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Typography as AntTypography, Modal as AntModal, Radio } from 'antd';
import CreateRegionModal from '../../components/CreateRegionModal';
import ConfirmModal from '../../components/ConfirmModal';
import RegionsLayout from '../../../../common/layouts/RegionsLayout';
import RegionsControls from '../../../../common/components/RegionsControls';
import { ERROR_REGION_HAS_CHILD_ASSETS } from '../../../../common/constants';
import Table, { alphanumericSorter } from '../../../../common/components/Table';
import { setAdminRegion, setAdminAgency } from '../../../userAuth/store/slice';
import openNotification from '../../../../common/components/notification';
import useRegions from '../../hooks/useRegions';
import BatchImport from '../../components/BatchImport';
import useAgencies from '../../hooks/useAgencies';
import { IS_MIO_APP } from '../../../../common/constants/environment';

const { Title: AntTitle } = AntTypography;

const ConfirmDeleteRegionModal = ({
  visible,
  onResponseChange,
  selected,
  deleteRegionResponse,
  ...props
}) => {
  const regionId = selected[0];
  const { reset } = deleteRegionResponse;

  const { agencies, isLoading, isError } = useAgencies({ regionId });
  const name = useSelector(
    ({ configuration }) => configuration.regions[regionId]?.name
  );

  useEffect(() => {
    if (selected.length > 0 && isError && visible) {
      openNotification({
        message: 'Error Checking for Child Assets',
        description: `Unable to check for child assets for region ${name}`,
      });
      onResponseChange();
    }
  }, [isError, name, onResponseChange, selected.length, visible]);

  const warningMessage = useMemo(() => {
    if (!agencies || agencies?.length === 0) return null;

    return (
      <span style={{ marginTop: '10px', color: 'red' }}>
        {ERROR_REGION_HAS_CHILD_ASSETS.replace('{}', name)}
      </span>
    );
  }, [agencies, name]);

  const handleResponseChange = useCallback(() => {
    reset();
    onResponseChange();
  }, [onResponseChange, reset]);

  return (
    <>
      {isLoading && visible && selected.length > 0 ? (
        <AntModal confirmLoading={isLoading} visible={isLoading} title="Delete">
          <p>Checking for child assets...</p>
        </AntModal>
      ) : // If the user has not selected a region to delete, then ConfirmModal still needs to render
      // in order to display the correct error message
      (!isLoading && !isError) || (!isLoading && selected.length === 0) ? (
        <ConfirmModal
          visible={visible && !isLoading}
          onResponseChange={() => handleResponseChange()}
          selected={selected}
          response={deleteRegionResponse}
          enableDelete={!agencies || agencies?.length === 0}
          warningMessage={warningMessage}
          emptyErrorMessage="You must select a region to delete."
          {...props}
        />
      ) : null}
    </>
  );
};

const RegionsPage = () => {
  const [creating, setCreating] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [selected, setSelected] = useState([]);
  const [searchTerm, setSearchTerm] = useState();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setAdminRegion({ name: '', regionId: '' }));

    if (IS_MIO_APP) return;
    dispatch(setAdminAgency({ agencyId: '' }));
  }, [dispatch]);

  const {
    regions,
    isLoading,
    isError,
    error,
    createRegionResponse,
    createRegion,
    deleteRegionResponse,
    deleteRegion,
  } = useRegions();

  useEffect(() => {
    if (isError)
      openNotification({
        message: 'Error Getting Regions',
        description: error.message,
      });
  }, [error?.message, isError]);

  const filteredRegions = !regions
    ? []
    : regions.filter(
        ({ name }) => !searchTerm || name.toLowerCase().includes(searchTerm)
      );

  const handleResponseChange = useCallback(() => {
    setCreating(false);
  }, [setCreating]);

  // Change the visibility of ConfirmDeleteRegionModal
  const handleConfirmClick = () => {
    setDeleting(true);
  };

  const handleRadioClick = (key) => {
    const selectedKey = selected.includes(key) ? [] : [key];
    setSelected(selectedKey);
  };

  const columns = [
    {
      className: 'ant-table-selection-col ant-table-before',
      render: (record) => (
        <Radio
          checked={selected[0] === record.id}
          onClick={() => handleRadioClick(record.id)}
        />
      ),
    },
    {
      dataIndex: 'name',
      title: 'Region Name',
      key: 'name',
      render: (_text, record) => {
        const handleClick = () => {
          dispatch(
            setAdminRegion({
              name: record.name.toLowerCase(),
              regionId: record.id,
            })
          );
        };
        return (
          <Link
            to={`/regions/${record.name.toLowerCase()}`}
            onClick={handleClick}
            className="btn-analytics"
            key={record.name}
          >
            {record.name}
          </Link>
        );
      },
      defaultSortOrder: 'ascend',
      sorter: alphanumericSorter('name'),
    },
    {
      dataIndex: 'description',
      title: 'Description',
      key: 'description',
    },
  ];

  if (deleteRegionResponse?.isSuccess) {
    deleteRegionResponse.data = ['Region Deleted Successfully'];
  }
  if (deleteRegionResponse?.isError) {
    deleteRegionResponse.data = ['Error Deleting Region'];
  }

  return (
    <>
      <CreateRegionModal
        visible={creating}
        onResponseChange={handleResponseChange}
        onCancel={() => setCreating(false)}
        createRegion={createRegion}
        response={createRegionResponse}
      />

      {deleting && (
        <ConfirmDeleteRegionModal
          visible={deleting}
          onResponseChange={() => setDeleting(false)}
          onSelectedChange={() => setSelected([])}
          selected={selected}
          delete={deleteRegion}
          deleteRegionResponse={deleteRegionResponse}
        />
      )}

      <RegionsLayout step={0}>
        <RegionsControls
          label={'Region'}
          onSearch={(st) => setSearchTerm(st.toLowerCase())}
          onCreate={() => setCreating(true)}
          onDelete={handleConfirmClick}
        />

        <div>
          <AntTitle level={5} style={{ margin: '0.5rem 0 1.25rem 0' }}>
            Region
          </AntTitle>
          <Table
            rowKey="id"
            style={{ width: '100%' }}
            pagination={true}
            isLoading={isLoading}
            columns={columns}
            dataSource={filteredRegions}
          />
          <div>
            <BatchImport />
          </div>
        </div>
      </RegionsLayout>
    </>
  );
};

export default withRouter(RegionsPage);
