import { useEffect } from 'react';
import TravelTimeTable from '../../components/TravelTimeTable';
import PerformanceSummary from '../../components/PerformanceSummary';
import openNotification from '../../../../common/components/notification';
import useOverview from '../../hooks/useOverview';
import { ERROR_NOT_SETUP, ERROR_NO_DATA } from '../../../../common/constants';
import './style.css';
import useAgency from '../../../../common/hooks/useAgency';
import { Metric } from '../../../../common/enums';

const OverviewContent = () => {
  const { performance, table, isLoading } = useOverview();
  const {
    status: { availability },
    isLoading: isStatusLoading,
  } = useAgency();

  // Check if any date range is available
  useEffect(() => {
    if (isStatusLoading || availability[Metric.TravelTime].isAvailable) {
      return;
    }

    openNotification({
      message: 'Error 404',
      description: ERROR_NOT_SETUP,
    });
  }, [availability, isStatusLoading]);

  // Check if any data is available in date range
  useEffect(() => {
    if (
      table &&
      table.length === 0 &&
      !isLoading &&
      availability[Metric.TravelTime].isAvailable
    ) {
      openNotification({
        message: 'Error 404',
        description: ERROR_NO_DATA,
      });
    }
  }, [table, isLoading, availability]);

  return (
    <div className="overview-content">
      <PerformanceSummary
        isLoading={isLoading}
        performance={performance}
        routes={table}
      />
      <div className="overview-content__table">
        <TravelTimeTable dataSource={table} isLoading={isLoading} />
      </div>
    </div>
  );
};

export default OverviewContent;
