import { createApi } from '@reduxjs/toolkit/query/react';
import { USER_ACTIVITY_LOGS_URL } from '../../../common/constants/apis';
import { baseQuery } from '../../../redux/utils/client';

const api = createApi({
  reducerPath: 'api/userActivityLogs',
  baseQuery,
  endpoints: (builder) => ({
    /* User logs  */
    getUserLogs: builder.query({
      query: ([startDate, endDate, _]) => ({
        url: `${USER_ACTIVITY_LOGS_URL}/logs`,
        title: 'Get User Logs',
        method: 'GET',
        params: {
          start_date: startDate,
          end_date: endDate,
        },
      }),
    }),
    startSession: builder.mutation({
      query: () => ({
        url: `${USER_ACTIVITY_LOGS_URL}/start-session`,
        title: 'Start User Session',
        method: 'POST',
      }),
    }),
  }),
});

export const { useGetUserLogsQuery, useStartSessionMutation } = api;
export const { resetApiState } = api.util;
export default api;
