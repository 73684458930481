/**
 * Compare two strings with `localeCompare` favoring strings closer to the term
 * @param {string} string1 String 1
 * @param {string} string2 String 2
 * @param {string} term Matching term
 * @return {number} Comparison value
 */
export const compareWithTerm = (string1, string2, term) => {
  const s1 = `${string1}`;
  const s2 = `${string2}`;

  if (term) {
    const s1TermComparison = term.localeCompare(s1);
    const s2TermComparison = term.localeCompare(s2);

    if (s1 === term) return -1;
    if (s2 === term) return 1;

    if (term && s1TermComparison < s2TermComparison) return -1;
    if (term && s1TermComparison > s2TermComparison) return 1;
  }

  return s1.localeCompare(s2);
};

/**
 * Remove a sequence of characters from the beginning and end of a string
 * @param {string} s String to trim
 * @param {string} cs Characters to trim
 * @returns Trimmed string
 */
export const trim = (s, cs) =>
  s.replace(new RegExp(`^[${cs}]+|[${cs}]+$`, 'g'), '');

/**
 * Pluralize a string based on a number
 * @param {string} s Base string
 * @param {number} n Number of items
 * @param {string?} pluralForm Override for specific plural form of string
 * @return {string} Pluralized string
 */
export const pluralize = (s, n, pluralForm) => {
  if (n === 1) return s;

  return pluralForm || `${s}s`;
};

/**
 * Recursively convert an object with keys in snake case to lower camel case
 * @param {any} obj Arbitrary object
 * @returns Object with keys in lower camel case
 */
export const camelize = (obj) => {
  if (obj === undefined || obj === null) return obj;
  // Removed quoted strings
  if (typeof obj === 'string') return trim(obj, '"');

  const newObj = {};

  Object.keys(obj).forEach((key) => {
    const value = obj[key];
    const keyCamel = key.replace(/(_\w?)/g, (match) =>
      match[1] ? match[1].toUpperCase() : ''
    );
    const isRecursive = value !== null && typeof value === 'object';
    newObj[keyCamel] = isRecursive
      ? camelize(value)
      : value === 'True'
      ? null
      : value;
  });

  return newObj;
};

/**
 * Recursively convert an object with keys in lower camel case to snake case
 * @param {any} obj Arbitrary object
 * @returns Object with keys in snake case
 */

export const snakeize = (obj) => {
  if (obj === undefined || obj === null) return obj;

  const newObj = {};

  Object.keys(obj).forEach((key) => {
    const value = obj[key];
    const keySnake = key.replace(
      /([A-Z])/g,
      (match) => `_${match[0].toLowerCase()}`
    );
    const isRecursive = value !== null && typeof value === 'object';
    newObj[keySnake] = isRecursive ? snakeize(value) : value;
  });

  return newObj;
};
