import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import configurationApi, {
  useGetWhelenImportStatusQuery,
  useStartWhelenImportMutation,
} from '../api';
import useMutationNotification from '../../../common/hooks/useMutationNotification';

const useWhelenImport = ({ agencyId }) => {
  const [jobId, setJobId] = useState();
  const dispatch = useDispatch();

  const {
    data,
    isError: isRefreshError,
    isLoading: isWhelenImportLoading,
  } = useGetWhelenImportStatusQuery(
    { jobId },
    {
      skip: !jobId,
      pollingInterval: 5000,
    }
  );
  const [start, startResponse] = useStartWhelenImportMutation({ agencyId });

  const { isError: isStartError, data: startData } = startResponse;
  const isRunning =
    startResponse?.isLoading ||
    isWhelenImportLoading ||
    (data && !data?.isDone);

  useMutationNotification(startResponse, {
    formatErrorNotification: (error) => ({
      message: 'Failed to Start Whelen Import',
      description: error?.message?.errorMessage ?? error?.message,
    }),
    formatSuccessNotification: () => ({
      message: 'Successfully Started Whelen Import',
    }),
  });

  useEffect(() => {
    if (startData?.jobId) {
      setJobId(startData.jobId);
    }
  }, [startData]);

  // Invalidate vehicles queries on finish
  useEffect(() => {
    if (data?.isDone) {
      setJobId();
      dispatch({
        type: `${configurationApi.reducerPath}/invalidateTags`,
        payload: ['VEHICLES', 'VEHICLE'],
      });
    }
  }, [data?.isDone, dispatch]);

  // Can't track job status if failure to start or refresh
  useEffect(() => {
    if (isRefreshError || isStartError) {
      setJobId();
    }
  }, [isRefreshError, isStartError, isRunning]);

  const startImport = useCallback(() => {
    start({ agencyId });
  }, [start, agencyId]);

  return {
    start: startImport,
    isRunning,
  };
};

export default useWhelenImport;
