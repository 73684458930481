import {
  getPolyline,
  getMarker,
} from '../../../../common/components/Map/GMaps/drawShapes';
import BusStopSVG from '../../../../common/icons/BusStop.svg';
import BusStopLowConfidence from '../../../../common/icons/BusStopLowConfidence.svg';
import IntersectionSVG from '../../../../common/icons/Intersection.svg';
import IntersectionLowConfidenceSVG from '../../../../common/icons/IntersectionLowConfidence.svg';
import { createShortTimeLabel } from '../../../../common/utils';
import { isInBounds } from '../../../../common/utils/map';
import { createTooltip } from '../../../../common/utils/shapes';

export const MAX_NUM_TOOLTIPS = 5;

export const visibleMetricComparator = (bounds) => (s1, s2) => {
  if (!isInBounds(bounds, s1)) return 1;
  if (!isInBounds(bounds, s2)) return -1;

  const s1Value = s1?.metric?.secs;
  const s2Value = s2?.metric?.secs;

  if (s1Value === undefined || s1Value === null || Number.isNaN(s1Value))
    return 1;
  if (s2Value === undefined || s2Value === null || Number.isNaN(s2Value))
    return -1;

  return +s2Value - +s1Value;
};

export const getPaths = (segments, getPathColor, setSelectedMarker) =>
  (segments || [])?.map((segment, i) => {
    const strokeColor = getPathColor(segment);

    const handleClick = (clickedSegment) => {
      if (!setSelectedMarker) {
        return;
      }

      const tooltipPoint =
        clickedSegment?.points[Math.floor(clickedSegment?.points?.length / 2)];

      setSelectedMarker({
        ...segment,
        lat: parseFloat(tooltipPoint.lat),
        lon: parseFloat(tooltipPoint.lon),
      });
    };

    return getPolyline(
      {
        ...segment,
        name: `Segment ${segment.stopstartname} - ${segment.stopendname}`,
      },
      {
        strokeOpacity: 1,
        strokeWeight: 4,
        strokeColor,
      },
      `segment-${segment.stopstartname}-${segment.stopendname}-${i}`,
      handleClick
    );
  });

export const createMetricTooltip = ({ minutes, maxSeconds, ...rest }) =>
  createTooltip({
    text: createShortTimeLabel(minutes, maxSeconds),
    ...rest,
  });

export const createBusStopIcon = ({ isLowConfidence, marker, key, onClick }) =>
  getMarker(
    marker,
    onClick,
    {
      url: isLowConfidence ? BusStopLowConfidence : BusStopSVG,
      // Half the height/width of the BusStopSVG
      anchor: { x: 10, y: 10 },
    },
    key
  );

export const createIntersectionIcon = ({
  isLowConfidence,
  marker,
  key,
  onClick,
}) =>
  getMarker(
    marker,
    onClick,
    {
      url: isLowConfidence ? IntersectionLowConfidenceSVG : IntersectionSVG,
      // Half the height/width of the IntersectionSVG
      anchor: { x: 11, y: 10 },
    },
    key
  );
