import { createApi } from '@reduxjs/toolkit/query/react';
import { LATENESS_URL } from '../../../common/constants';
import { Direction, Timeframe } from '../../../common/enums';
import { reduceSegments } from '../../../common/utils';
import { baseQuery } from '../../../redux/utils/client';
import { inferStopOrder } from '../utils';
import { addMinutesToMetrics } from './utils';

const PERIOD = 'month';

const api = createApi({
  reducerPath: 'api/travelTime',
  baseQuery,
  endpoints: (builder) => ({
    /* Agency */
    getOverview: builder.query({
      query: ({
        dateRange: { startDate, endDate },
        direction,
        periods,
        timeperiod,
      }) => ({
        url: `${LATENESS_URL}/metrics/travel_time/agg_travel_time`,
        title: 'Overview Page',
        method: 'POST',
        data: {
          selected_direction:
            direction === Direction.All
              ? [Direction.Inbound, Direction.Outbound]
              : [direction],
          selected_timeperiod:
            periods[0] === Timeframe.All
              ? [
                  Timeframe.PeakAM,
                  Timeframe.PeakPM,
                  Timeframe.OffPeak,
                  Timeframe.Weekends,
                ]
              : periods,
          timeperiod,
        },
        params: {
          start_date: startDate,
          end_date: endDate,
          period: PERIOD,
        },
      }),
    }),

    /* Route */

    getAvgMetrics: builder.query({
      query: ({
        routeName,
        dateRange: { startDate, endDate },
        direction,
        periods,
        timeperiod,
      }) => ({
        url: `${LATENESS_URL}/metrics/travel_time/agg_travel_time_per_period`,
        title: 'Average Metrics',
        method: 'POST',
        data: {
          selected_direction:
            direction === Direction.All
              ? [Direction.Inbound, Direction.Outbound]
              : [direction],
          selected_timeperiod:
            periods[0] === Timeframe.All
              ? [
                  Timeframe.PeakAM,
                  Timeframe.PeakPM,
                  Timeframe.OffPeak,
                  Timeframe.Weekends,
                ]
              : periods,
          timeperiod,
        },
        params: {
          start_date: startDate,
          end_date: endDate,
          period: PERIOD,
          route: routeName,
        },
      }),
      // TODO: Remove conversion to minutes
      transformResponse: addMinutesToMetrics,
    }),

    getRouteSegments: builder.query({
      query: ({ routeName, direction, dateRange: { endDate } }) => ({
        url: `${LATENESS_URL}/routes_map_data`,
        title: 'Travel Time Map',
        method: 'POST',
        data: {
          selected_direction:
            direction === Direction.All
              ? [Direction.Inbound, Direction.Outbound]
              : [direction],
        },
        params: {
          // Endpoint does not handle date range. TODAY returns most recent GTFS published version.
          start_date: endDate,
          end_date: endDate,
          route: routeName,
        },
      }),
      transformResponse: (data) => {
        const points = reduceSegments(data);

        if (!points?.length || !points[0]?.segments) return points;

        // TODO: Move logic into API
        const segments = inferStopOrder(points[0].segments);

        return [{ ...points[0], segments }];
      },
    }),

    getStops: builder.query({
      query: ({ routeName, direction, dateRange: { endDate } }) => ({
        url: `${LATENESS_URL}/gtfs/route/stops`,
        title: 'GTFS Stops',
        method: 'POST',
        data: {
          selected_direction:
            direction === Direction.All
              ? [Direction.Inbound, Direction.Outbound]
              : [direction],
        },
        params: {
          start_date: endDate,
          end_date: endDate,
          route: routeName,
        },
      }),
    }),

    getDwellTimeByStop: builder.query({
      query: ({
        routeName,
        dateRange: { startDate, endDate },
        direction,
        periods,
        timeperiod,
      }) => ({
        url: `${LATENESS_URL}/metrics/tsp/dwell-time/route/stops`,
        title: 'Stop Dwell Time',
        method: 'POST',
        data: {
          selected_direction:
            direction === Direction.All
              ? [Direction.Inbound, Direction.Outbound]
              : [direction],
          selected_timeperiod:
            periods[0] === Timeframe.All
              ? [
                  Timeframe.PeakAM,
                  Timeframe.PeakPM,
                  Timeframe.OffPeak,
                  Timeframe.Weekends,
                ]
              : periods,
          timeperiod,
        },
        params: {
          start_date: startDate,
          end_date: endDate,
          route: routeName,
          period: PERIOD,
        },
      }),
      transformResponse: (response) =>
        (response ?? []).reduce((acc, dtbs) => {
          acc[dtbs.stopName] = dtbs;
          return acc;
        }, {}),
    }),

    getDriveTimeBySegment: builder.query({
      query: ({
        routeName,
        dateRange: { startDate, endDate },
        direction,
        periods,
        timeperiod,
      }) => ({
        url: `${LATENESS_URL}/metrics/tsp/drive-time/route/segments`,
        title: 'Stop-to-stop Drive Time',
        method: 'POST',
        data: {
          selected_direction:
            direction === Direction.All
              ? [Direction.Inbound, Direction.Outbound]
              : [direction],
          selected_timeperiod:
            periods[0] === Timeframe.All
              ? [
                  Timeframe.PeakAM,
                  Timeframe.PeakPM,
                  Timeframe.OffPeak,
                  Timeframe.Weekends,
                ]
              : periods,
          timeperiod,
        },
        params: {
          start_date: startDate,
          end_date: endDate,
          route: routeName,
          period: PERIOD,
        },
      }),
      transformResponse: (response) =>
        (response ?? []).reduce((acc, dtbs) => {
          acc[`${dtbs.stopStartId}-${dtbs.stopEndId}`] = dtbs;
          return acc;
        }, {}),
    }),

    getChartMetrics: builder.query({
      query: ({
        routeName,
        dateRange: { startDate, endDate },
        direction,
        periods,
        period,
        timeperiod,
      }) => ({
        url: `${LATENESS_URL}/metrics/travel_time/agg_travel_time_per_route_day`,
        title: 'Chart Metrics',
        method: 'POST',
        data: {
          selected_direction:
            direction === Direction.All
              ? [Direction.Inbound, Direction.Outbound]
              : [direction],
          selected_timeperiod:
            periods[0] === Timeframe.All
              ? [
                  Timeframe.PeakAM,
                  Timeframe.PeakPM,
                  Timeframe.OffPeak,
                  Timeframe.Weekends,
                ]
              : periods,
          timeperiod,
        },
        params: {
          start_date: startDate,
          end_date: endDate,
          route: routeName,
          period,
        },
      }),
    }),

    getSignalDelayByIntersection: builder.query({
      query: ({
        routeName,
        dateRange: { startDate, endDate },
        direction,
        periods,
        timeperiod,
      }) => ({
        url: `${LATENESS_URL}/metrics/travel_time/agg_signal_delay_per_route_intersection`,
        title: 'Signal Delay',
        method: 'POST',
        data: {
          selected_direction:
            direction === Direction.All
              ? [Direction.Inbound, Direction.Outbound]
              : [direction],
          selected_timeperiod:
            periods[0] === Timeframe.All
              ? [
                  Timeframe.PeakAM,
                  Timeframe.PeakPM,
                  Timeframe.OffPeak,
                  Timeframe.Weekends,
                ]
              : periods,
          timeperiod,
        },
        params: {
          start_date: startDate,
          end_date: endDate,
          route: routeName,
          period: PERIOD,
        },
      }),
      transformResponse: (response) =>
        (response ?? []).reduce((acc, sdbi) => {
          acc[sdbi.locationid] = sdbi;
          return acc;
        }, {}),
    }),

    getIntersections: builder.query({
      query: ({ routeName, direction, dateRange: { endDate } }) => ({
        url: `${LATENESS_URL}/metrics/travel_time/route_intersections`,
        title: 'Intersections',
        method: 'POST',
        data: {
          selected_direction:
            direction === Direction.All
              ? [Direction.Inbound, Direction.Outbound]
              : [direction],
        },
        params: {
          // Endpoint does not handle date range. TODAY returns most recent GTFS published version.
          start_date: endDate,
          end_date: endDate,
          route: routeName,
        },
      }),
      transformResponse: (response) =>
        // Fields: { direction, route, route_id, intersections }
        response.length ? response[0].intersections : response.intersections,
    }),
  }),
});

export const {
  useGetOverviewQuery,
  useGetAvgMetricsQuery,
  useGetRouteSegmentsQuery,
  useGetStopsQuery,
  useGetDwellTimeByStopQuery,
  useGetDriveTimeBySegmentQuery,
  useGetChartMetricsQuery,
  useGetSignalDelayByIntersectionQuery,
  useGetIntersectionsQuery,
} = api;

export const { resetApiState } = api.util;

export default api;
