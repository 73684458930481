import { useMemo, useEffect } from 'react';
import {
  useChangePreemptionMutation,
  useGetChangePreemptionStatusMutation,
} from '../api';

// Updates preemption status for a device
const useChangePreemption = ({ vehicleId }) => {
  const [changePreemption, changePreemptionResponse] =
    useChangePreemptionMutation();
  const [getChangePreemptionStatus, getChangePreemptionStatusResponse] =
    useGetChangePreemptionStatusMutation();

  const queueUrl = useMemo(
    () => changePreemptionResponse?.data?.queueUrl,
    [changePreemptionResponse]
  );

  const response = useMemo(
    () =>
      changePreemptionResponse?.isError
        ? changePreemptionResponse
        : getChangePreemptionStatusResponse,
    [changePreemptionResponse, getChangePreemptionStatusResponse]
  );

  useEffect(() => {
    if (!queueUrl || !vehicleId) return;
    getChangePreemptionStatus({ queueUrl, vehicleId });
  }, [queueUrl, getChangePreemptionStatus, vehicleId]);

  return {
    changePreemption,
    changePreemptionResponse: response,
  };
};

export default useChangePreemption;
