import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  useGetDevicesQuery,
  useCreateDeviceMutation,
  useEditDeviceMutation,
  useDeleteDeviceMutation,
  useDissociateDeviceMutation,
  useAssociateDeviceMutation,
} from '../api';
import useChangePreemption from './useChangePreemption';

// Returns list of all Devices
const useDevices = ({ agencyId: selectedAgencyId } = {}) => {
  const [vehId, setVehId] = useState(null);

  const { agencyId: sessionAgencyId } = useSelector(({ user }) => user);

  const agencyId = useMemo(
    () => selectedAgencyId || sessionAgencyId,
    [selectedAgencyId, sessionAgencyId]
  );

  const {
    data: devices,
    isLoading,
    isError,
    error,
  } = useGetDevicesQuery({ agencyId });

  const [createDevice, createDeviceResponse] = useCreateDeviceMutation();
  const [editDevice, editDeviceResponse] = useEditDeviceMutation();
  const [deleteDevice, deleteDeviceResponse] = useDeleteDeviceMutation();
  const [dissociateDevice, dissociateDeviceResposne] =
    useDissociateDeviceMutation();
  const [associateDevice, associateDeviceResponse] =
    useAssociateDeviceMutation();
  const { changePreemption } = useChangePreemption({ vehicleId: vehId });

  const [updateDevice, updateDeviceResponse] = useEditDeviceMutation();

  const onAssociateDevice = useCallback(
    ({ deviceId, vehicleId }) => {
      associateDevice({ id: deviceId, vehicleId, agencyId });
    },
    [associateDevice, agencyId]
  );

  const onDissociateDevice = useCallback(
    (device) => {
      const { vehicleId } = device;
      dissociateDevice({
        id: device.id,
        vehicleId,
        agencyId,
      });
      if (device?.preemptionLicense === 'active') {
        setVehId(vehicleId);
        changePreemption({
          devices: { [device.id]: 'inactive' },
          agencyId,
          vehicleId,
        });
      }
    },
    [dissociateDevice, agencyId, changePreemption]
  );

  const devicesByVehicle = useMemo(() => {
    if (!devices?.length) return {};

    return devices.reduce((vd, d) => {
      if (!(d.vehicleId in vd)) {
        vd[d.vehicleId] = [d];
      } else {
        vd[d.vehicleId] = [...vd[d.vehicleId], d];
      }

      return vd;
    }, {});
  }, [devices]);

  const unassignedDevices = useMemo(
    () =>
      devices?.length ? devices.filter(({ vehicleId }) => !vehicleId) : [],
    [devices]
  );

  return {
    devices,
    unassignedDevices,
    devicesByVehicle,
    isLoading,
    isError,
    error,
    createDeviceResponse,
    createDevice,
    editDeviceResponse,
    editDevice,
    associateDeviceResponse,
    associateDevice: onAssociateDevice,
    dissociateDeviceResposne,
    dissociateDevice: onDissociateDevice,
    deleteDeviceResponse,
    deleteDevice,
    updateDevice,
    updateDeviceResponse,
  };
};

export default useDevices;
