import { Metric, Period, Timeframe, Direction } from '../enums';
import { AWS_REGION } from './config';

// Label maps
export const metricsNames = {
  signaldelay: 'Signal Delay',
  dwelltime: 'Dwell Time',
  drivetime: 'Drive Time',
  traveltime: 'Travel Time',
  scheduleDeviation: 'Schedule Deviation',
  onTimePercentage: 'On-Time Percentage',
};

export const Country = {
  UNITED_STATES: 'United States',
  CANADA: 'Canada',
};

export const getLabelForCountry = (country) =>
  country === Country.CANADA ? 'Province' : 'State';

export const AgencyPriority = {
  HIGH: 'High',
  LOW: 'Low',
};

export const AgencyCreationModelWidth = 850;

export const ModalSize = {
  Small: 850,
};

// Shapes
export const SEGMENT_MAP_METRICS = [Metric.DriveTime];

export const PERIODS = [
  {
    label: `Peak AM (06:00 - 09:00)`,
    value: Timeframe.PeakAM,
  },
  {
    label: `Peak PM (16:00 - 19:00)`,
    value: Timeframe.PeakPM,
  },
  {
    label: 'Off-Peak (not peak or weekends)',
    value: Timeframe.OffPeak,
  },
  {
    label: 'Weekends (Saturday & Sunday)',
    value: Timeframe.Weekends,
  },
  {
    label: 'All',
    value: Timeframe.All,
  },
];

export const DIRECTIONS = Object.values(Direction);

export const TIMEPERIODS = Object.values(Timeframe);

export const BREADCRUMB_TITLES = {
  analytics: {
    title: 'Analytics',
  },
  'schedule-deviation': {
    title: 'Schedule Deviation',
    to: '/analytics/schedule-deviation',
  },
  'transit-delay': {
    title: 'Transit Delay',
    to: '/analytics/transit-delay',
  },
  'health-monitoring': {
    title: 'Health Monitoring',
  },
  intersections: {
    title: 'Intersections',
    to: '/health-monitoring/intersections',
  },
  vehicles: {
    title: 'Vehicles',
    to: '/health-monitoring/vehicles',
  },
  'logs-reporting': {
    title: 'Logs & Reporting',
  },
  'activity-log': {
    title: 'Activity Logs',
    to: '/logs-reporting/activity-log',
  },
  config: {
    title: 'Configuration',
  },
  'agency-settings': {
    title: 'Agency',
    to: '/config/agency-settings',
  },
};

// Dates
export const PERIOD_FREQUENCY = {
  [Period.Day]: Period.Day,
  [Period.Week]: Period.Day,
  [Period.Month]: Period.Day,
  [Period.Year]: Period.Month,
};

export const stateCodesByCountry = {
  'United States': [
    { code: 'AL', name: 'Alabama' },
    { code: 'AK', name: 'Alaska' },
    { code: 'AZ', name: 'Arizona' },
    { code: 'AR', name: 'Arkansas' },
    { code: 'CA', name: 'California' },
    { code: 'CO', name: 'Colorado' },
    { code: 'CT', name: 'Connecticut' },
    { code: 'DE', name: 'Delaware' },
    { code: 'FL', name: 'Florida' },
    { code: 'GA', name: 'Georgia' },
    { code: 'HI', name: 'Hawaii' },
    { code: 'ID', name: 'Idaho' },
    { code: 'IL', name: 'Illinois' },
    { code: 'IN', name: 'Indiana' },
    { code: 'IA', name: 'Iowa' },
    { code: 'KS', name: 'Kansas' },
    { code: 'KY', name: 'Kentucky' },
    { code: 'LA', name: 'Louisiana' },
    { code: 'ME', name: 'Maine' },
    { code: 'MD', name: 'Maryland' },
    { code: 'MA', name: 'Massachusetts' },
    { code: 'MI', name: 'Michigan' },
    { code: 'MN', name: 'Minnesota' },
    { code: 'MS', name: 'Mississippi' },
    { code: 'MO', name: 'Missouri' },
    { code: 'MT', name: 'Montana' },
    { code: 'NE', name: 'Nebraska' },
    { code: 'NV', name: 'Nevada' },
    { code: 'NH', name: 'New Hampshire' },
    { code: 'NJ', name: 'New Jersey' },
    { code: 'NM', name: 'New Mexico' },
    { code: 'NY', name: 'New York' },
    { code: 'NC', name: 'North Carolina' },
    { code: 'ND', name: 'North Dakota' },
    { code: 'OH', name: 'Ohio' },
    { code: 'OK', name: 'Oklahoma' },
    { code: 'OR', name: 'Oregon' },
    { code: 'PA', name: 'Pennsylvania' },
    { code: 'RI', name: 'Rhode Island' },
    { code: 'SC', name: 'South Carolina' },
    { code: 'SD', name: 'South Dakota' },
    { code: 'TN', name: 'Tennessee' },
    { code: 'TX', name: 'Texas' },
    { code: 'UT', name: 'Utah' },
    { code: 'VT', name: 'Vermont' },
    { code: 'VA', name: 'Virginia' },
    { code: 'WA', name: 'Washington' },
    { code: 'WV', name: 'West Virginia' },
    { code: 'WI', name: 'Wisconsin' },
    { code: 'WY', name: 'Wyoming' },
  ],
  Canada: [
    { code: 'AB', name: 'Alberta' },
    { code: 'BC', name: 'British Columbia' },
    { code: 'MB', name: 'Manitoba' },
    { code: 'NB', name: 'New Brunswick' },
    { code: 'NL', name: 'Newfoundland and Labrador' },
    { code: 'NS', name: 'Nova Scotia' },
    { code: 'NT', name: 'Northwest Territories' },
    { code: 'NU', name: 'Nunavut' },
    { code: 'ON', name: 'Ontario' },
    { code: 'PE', name: 'Prince Edward Island' },
    { code: 'QC', name: 'Quebec' },
    { code: 'SK', name: 'Saskatchewan' },
    { code: 'YT', name: 'Yukon' },
  ],
};

export const timeZonesByCountry = {
  'United States': [
    { value: 'Central', name: 'Central' },
    { value: 'Mountain', name: 'Mountain' },
    { value: 'Eastern', name: 'Eastern' },
    { value: 'Pacific', name: 'Pacific' },
    { value: 'Arizona', name: 'Arizona' },
  ],
  Canada: [
    { value: 'Central', name: 'Central' },
    { value: 'Mountain', name: 'Mountain' },
    { value: 'Eastern', name: 'Eastern' },
    { value: 'Pacific', name: 'Pacific' },
    { value: 'Newfoundland', name: 'Newfoundland' },
    { value: 'Atlantic', name: 'Atlantic' },
    { value: 'Saskatchewan', name: 'Saskatchewan' },
  ],
};

const awsRegionToCountry = {
  'us-east-1': Country.UNITED_STATES,
  'us-east-2': Country.UNITED_STATES,
  'us-west-1': Country.UNITED_STATES,
  'us-west-2': Country.UNITED_STATES,
  'af-south-1': 'South Africa',
  'ap-east-1': 'Hong Kong',
  'ap-south-1': 'India',
  'ap-northeast-3': 'Japan',
  'ap-northeast-2': 'South Korea',
  'ap-southeast-1': 'Singapore',
  'ap-southeast-2': 'Australia',
  'ap-northeast-1': 'Japan',
  'ca-central-1': Country.CANADA,
  'cn-north-1': 'China',
  'cn-northwest-1': 'China',
  'eu-central-1': 'Germany',
  'eu-west-1': 'Ireland',
  'eu-west-2': 'United Kingdom',
  'eu-south-1': 'Italy',
  'eu-west-3': 'France',
  'eu-north-1': 'Sweden',
  'me-south-1': 'Bahrain',
  'sa-east-1': 'Brazil',
};

export const TIME_ZONES = timeZonesByCountry[awsRegionToCountry[AWS_REGION]];
export const STATE_CODES = stateCodesByCountry[awsRegionToCountry[AWS_REGION]];
