import React from 'react';
import Table, { alphanumericSorter } from '../../../../common/components/Table';

const columns = [
  {
    key: 'name',
    dataIndex: 'name',
    title: 'Vehicle',
    sorter: alphanumericSorter('name'),
    sortDirections: ['ascend', 'descend'],
  },
  {
    key: 'daysSinceLastHeard',
    dataIndex: 'daysSinceLastHeard',
    title: 'Last Communication',
    sorter: alphanumericSorter('daysSinceLastHeard'),
    render: (value) => (value > 0 ? `${value} days ago` : 'Today'),
    sortDirections: ['ascend', 'descend'],
  },
];

const VehiclesTable = ({ isLoading, data }) => (
  <Table
    className="vehicles-health-monitoring-table"
    isLoading={isLoading}
    columns={columns}
    expandable={{
      expandedRowRender: (record) => (
        <p className="vehicles-health-monitoring-table-expanded">
          Device Model: {record.deviceModel} &nbsp; &nbsp; | &nbsp; &nbsp;
          Device Serial Number: {record.deviceName} &nbsp; &nbsp; | &nbsp;
          &nbsp; Class: {record.vehicleClass} &nbsp; &nbsp;
        </p>
      ),
    }}
    rowKey={(value) => value.name}
    dataSource={data}
    pagination={true}
    bordered={false}
  />
);

export default VehiclesTable;
