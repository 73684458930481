import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useStatusQuery } from '../../redux/api';
import { Metric } from '../enums';

const useAgency = () => {
  const { agency } = useSelector((state) => state.user);
  const { data: scheduleDeviation, isFetching: isScheduleDeviationLoading } =
    useStatusQuery({
      params: { agency },
    });
  const { data: travelTime, isFetching: isTravelTimeLoading } =
    useStatusQuery();

  const availability = useMemo(() => {
    if (
      isTravelTimeLoading ||
      isScheduleDeviationLoading ||
      !travelTime?.availability ||
      !scheduleDeviation?.availability
    ) {
      return {
        [Metric.ScheduleDeviation]: {
          min: null,
          max: null,
        },
        [Metric.TravelTime]: {
          min: null,
          max: null,
        },
      };
    }

    const scheduleDeviationAvailability = {
      min: moment(scheduleDeviation.availability?.schedule_deviation?.min),
      max: moment(scheduleDeviation.availability?.schedule_deviation?.max),
      isAvailable:
        scheduleDeviation.availability?.schedule_deviation?.min ||
        scheduleDeviation.availability?.schedule_deviation?.max,
    };

    const travelTimeAvailability = {
      min: moment(travelTime.availability?.travel_time?.min),
      max: moment(travelTime.availability?.travel_time?.max),
      isAvailable:
        travelTime.availability?.travel_time?.min ||
        travelTime.availability?.travel_time?.max,
    };

    return {
      [Metric.ScheduleDeviation]: scheduleDeviationAvailability,
      [Metric.TravelTime]: travelTimeAvailability,
    };
  }, [
    scheduleDeviation,
    travelTime,
    isScheduleDeviationLoading,
    isTravelTimeLoading,
  ]);

  return {
    status: { availability },
    isLoading: isScheduleDeviationLoading || isTravelTimeLoading,
  };
};

export default useAgency;
