import { useMemo } from 'react';
import {
  useGetIntersectionsQuery,
  useRefreshIntersectionsMutation,
} from '../api';

const useIntersections = () => {
  const { data, isLoading } = useGetIntersectionsQuery();

  const [
    refresh,
    {
      isLoading: isRefreshing,
      isSuccess: isRefreshed,
      isError: errorRefreshing,
    },
  ] = useRefreshIntersectionsMutation();

  const intersections = useMemo(() => {
    if (isLoading || !data) return [];

    return data.map(
      ({
        latitude,
        longitude,
        intersectionName,
        intersectionId,
        serialNumber,
        make,
        model,
        ...rest
      }) => ({
        latitude,
        longitude,
        coordinates: [latitude, longitude],
        intersectionName: intersectionName?.trim(),
        makeModel: [make, model],
        serialNumber,
        intersectionId: intersectionId || serialNumber,
        key: `${intersectionName?.trim()}${
          intersectionName?.trim() ? ' ' : ''
        }${intersectionId || serialNumber}`,
        ...rest,
      })
    );
  }, [isLoading, data]);

  return {
    intersections,
    isLoading,
    refresh,
    isRefreshing,
    isRefreshed,
    errorRefreshing,
  };
};

export default useIntersections;
