import * as yup from 'yup';
import { Make, MakeModels, Model, TimeZone } from '../../common/enums';
import { ApproachChannels } from './enums';

const MAC_ADDRESS_REGEX = '^(([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2}))?$';
const IP_ADDRESS_REGEX = /(^(\d{1,3}\.){3}(\d{1,3})$)/;
const LATITUDE_LONGITUDE_REGEX = /^-?[0-9]{1,3}(\.[0-9]{0,6})?$/;

const ipAddressValidator = (value) =>
  value === undefined || value === null || value.trim() === ''
    ? true
    : value.split('.').find((i) => parseInt(i, 10) > 255) === undefined;

// eslint-disable-next-line func-names
yup.addMethod(yup.string, 'validModelForMakeAndIntegration', function () {
  // eslint-disable-next-line no-invalid-this
  return this.test(
    'test-model-for-make-and-integration',
    // eslint-disable-next-line no-undef
    null,
    // eslint-disable-next-line func-names
    function (value, params) {
      // eslint-disable-next-line no-invalid-this
      const { path, createError } = this;
      const { parent: originalValues } = params;

      if (
        !originalValues?.make ||
        originalValues?.model === Model.Integration
      ) {
        return (
          originalValues === undefined ||
          originalValues === null ||
          createError({ path, message: '' })
        );
      }

      return (
        MakeModels[originalValues.make] ||
        createError({
          path,
          message: `Model must be one of ${(
            MakeModels[originalValues.make] || []
          ).join(', ')}`,
        })
      );
    }
  );
});

const regionSchema = yup.object({
  name: yup
    .string()
    .matches(
      '^[A-Za-z0-9]+$',
      'Must be alphanumeric without spaces or underscores.'
    )
    .max(50, 'Must be 50 characters or less')
    .required('Name is a required field'),
  description: yup.string().max(1000, 'Must be 1000 characters or less'),
});

const editRegionSchema = regionSchema;

const agencySchema = yup.object({
  name: yup
    .string()
    .matches(
      '^[A-Za-z0-9]+$',
      'Must be alphanumeric without spaces or underscores.'
    )
    .max(50, 'Must be 50 characters or less')
    .required('Name is a required field'),
  description: yup.string().max(1000, 'Must be 1000 characters or less'),
  city: yup.string().max(50, 'Must be 50 characters or less').required(),
  state: yup.string().max(50, 'Must be 50 characters or less').required(),
  timezone: yup.string().oneOf(Object.values(TimeZone)),
  agencyCode: yup
    .number()
    .integer()
    .min(1, 'Must be between 1 to 254')
    .max(254, 'Must be between 1 to 254')
    .required(),
  priority: yup.string().oneOf(['Low', 'High']).required(),
  cmsId: yup.string().max(225, 'Must be 225 characters or less'),
  integrations: yup
    .string()
    .matches(
      '^[A-Za-z0-9]+$',
      'Must be alphanumeric without spaces or underscores.'
    )
    .max(50, 'Must be 50 characters or less'),
});

const vehicleSchema = yup.object({
  name: yup
    .string()
    .max(100, 'Must be 100 characters or less')
    .required('Name is a required field'),
  description: yup.string().max(500, 'Must be 500 characters or less'),
  type: yup.string().max(100, 'Must be 100 characters or less'),
  class: yup
    .number()
    .integer()
    .min(1, 'Must be in between 1 to 10')
    .max(10, 'Must be in between 1 to 10'),
  vid: yup
    .number()
    .integer()
    .min(1, 'Must be between 1 to 9999')
    .max(9999, 'Must be between 1 to 9999'),
  priority: yup
    .string()
    .oneOf(['Low', 'High', 'Probe'])
    .required('Priority is a required field'),
});

const editVehicleSchema = vehicleSchema;

const deviceSchema = yup.object({
  description: yup.string().max(500, 'Must be 500 characters or less'),
  serial: yup.string().max(50, 'Must be 50 characters or less').required(),
  addressMac: yup
    .string()
    .nullable()
    .when('make', {
      is: (make) => [Make.Cradlepoint, Make.SierraWireless].includes(make),
      then: yup
        .string()
        .nullable()
        .matches(MAC_ADDRESS_REGEX, 'Must be valid MAC address')
        .required('MAC Address is a required field'),
      otherwise: yup.string().nullable(),
    }),
  addressWan: yup
    .string()
    .nullable()
    .when('make', {
      is: (make) => [Make.GTT].includes(make),
      then: yup
        .string()
        .matches(IP_ADDRESS_REGEX, {
          message: 'Invalid IP address',
          excludeEmptyString: true,
        })
        .test('ip', 'Invalid IP address', ipAddressValidator)
        .required('WAN Address is a required field'),
      otherwise: yup
        .string()
        .nullable()
        .matches(IP_ADDRESS_REGEX, {
          message: 'Invalid IP address',
          excludeEmptyString: true,
        })
        .test('ip', 'Invalid IP address', ipAddressValidator),
    }),
  addressLan: yup
    .string()
    .nullable()
    .when('make', {
      is: (make) => [Make.GTT].includes(make),
      then: yup
        .string()
        .matches(IP_ADDRESS_REGEX, {
          message: 'Invalid IP address',
          excludeEmptyString: true,
        })
        .test('ip', 'Invalid IP address', ipAddressValidator)
        .required('LAN Address is a required field'),
      otherwise: yup.string().nullable(),
    }),
  imei: yup
    .string()
    .nullable()
    .when('make', {
      is: (m) => [Make.SierraWireless, Make.Cradlepoint].includes(m),
      then: yup
        .string()
        .nullable()
        .matches('^[0-9]{15}$', 'Must be 15 digits')
        .required('IMEI is a required field'),
      otherwise: yup
        .string()
        .nullable()
        .matches('[0-9]{15}$', 'Must be 15 digits')
        .notRequired(),
    }),
  make: yup
    .string()
    .oneOf(Object.values(Make))
    .required(
      `Make must be one of ${[
        Make.GTT,
        Make.SierraWireless,
        Make.Cradlepoint,
      ].join(', ')}`
    ),
  model: yup.string().nullable().validModelForMakeAndIntegration(),
  gttSerial: yup
    .string()
    .nullable()
    .when('make', {
      is: (m) => [Make.SierraWireless, Make.Cradlepoint].includes(m),
      then: yup
        .string()
        .nullable()
        .max(50, 'Must be 50 characters or less')
        .required('GTT Serial is a required field'),
      otherwise: yup
        .string()
        .nullable()
        .max(50, 'Must be 50 characters or less')
        .notRequired(),
    }),
});

const editDeviceSchema = deviceSchema;

const intersectionSchema = yup.object({
  intersectionName: yup
    .string()
    .matches(
      '^[ -~]+$',
      'Only ASCII characters are allowed in intersection name'
    )
    .max(40, 'Must be 40 characters or less')
    .required('Name is a required field'),
  longitude: yup
    .number()
    .min(-180)
    .max(180)
    .test('is-decimal', 'Only 6 digits after decimal are accepted', (value) =>
      `${value}`.match(LATITUDE_LONGITUDE_REGEX)
    )
    .required('Longitude is a required field'),
  latitude: yup
    .number()
    .min(-90)
    .max(90)
    .test('is-decimal', 'Only 6 digits after decimal are accepted', (value) =>
      `${value}`.match(LATITUDE_LONGITUDE_REGEX)
    )
    .required('Latitude is a required field'),
  controllerIpAddress: yup
    .string()
    .matches(IP_ADDRESS_REGEX, 'Invalid Ip Address'),
  controllerPort: yup
    .number()
    .min(1, 'Must be between 1 to 65535')
    .max(65535, 'Must be between 1 to 65535'),
  communityName: yup.string().max(128, 'Must be 128 characters or less'),
  [ApproachChannels.A]: yup
    .string()
    .matches('^[ -~]+$', 'Only ASCII characters are allowed in channel name')
    .max(40, 'Must be 40 characters or less'),
  [ApproachChannels.B]: yup
    .string()
    .matches('^[ -~]+$', 'Only ASCII characters are allowed in channel name')
    .max(40, 'Must be 40 characters or less'),
  [ApproachChannels.C]: yup
    .string()
    .matches('^[ -~]+$', 'Only ASCII characters are allowed in channel name')
    .max(40, 'Must be 40 characters or less'),
  [ApproachChannels.D]: yup
    .string()
    .matches('^[ -~]+$', 'Only ASCII characters are allowed in channel name')
    .max(40, 'Must be 40 characters or less'),
});

export {
  regionSchema,
  editRegionSchema,
  agencySchema,
  vehicleSchema,
  editVehicleSchema,
  deviceSchema,
  editDeviceSchema,
  intersectionSchema,
};
