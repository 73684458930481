import { Modal } from 'antd';
import { useEffect, useState } from 'react';
import useAgency from '../../../configuration/hooks/useAgency';

// Used to display a Modal explaining the disclaimer for demo agency
const DemoDisclaimer = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const { agency } = useAgency();

  const handleConfirm = () => {
    setIsModalVisible(false);
  };

  // Need to display demo disclaimer in pilot environment irrespective of agency, in other environments it should display only for demo agency
  useEffect(() => {
    const disclaimerCount = sessionStorage.getItem('disclaimerCount', '0');
    if (
      disclaimerCount !== '1' &&
      process.env.REACT_APP_SC_DOMAIN_NAME === 'cdfmanager-pilot'
    ) {
      setIsModalVisible(true);
      sessionStorage.setItem('disclaimerCount', '1');
    }
  }, [agency?.name]);
  return (
    <div>
      <Modal
        title="THIS IS A DEMO ENVIRONMENT. ALL DATA PREVIEWED IS MANUFACTURED AND PLACED ON A REAL LOCATION MAP TO SHOW THE PRODUCT FEATURES AND REPORTS."
        visible={isModalVisible}
        onOk={handleConfirm}
        okText="Confirm"
        cancelButtonProps={{ style: { display: 'none' } }}
        closable={false}
        keyboard={false}
      >
        <p>
          This demonstration data is not reflective of the City of San
          Francisco&apos;s actual routes and data.
        </p>
      </Modal>
    </div>
  );
};

export default DemoDisclaimer;
