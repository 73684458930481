import ReactDOM from 'react-dom';
import Marker from '../Marker';

// Legend Marker Icons
export const lineChartPath =
  'M0.75 6.9375 L 18.75 -5.4375 L 36 6.9375 L 52.125 1.6875';
export const dottedLinePath = 'M0 5 H6 M12 5 H24 M32 5 H38';
export const squarePath = 'M20 10 h 20 l 0 -10 l -20 0';

export const createStopTick = (index, stroke) => ({
  type: 'dataMarker',
  position: [+index, 0],
  offsetY: 12.5,
  line: {
    length: 25,
    style: {
      stroke,
      lineWidth: 2,
    },
  },
  text: false,
  point: false,
});

export const createStopsLine = (stroke) => ({
  type: 'line',
  start: ['min', 0],
  end: ['max', 0],
  style: {
    stroke,
    lineWidth: 2,
  },
});

export const createSegment = (index, stroke) => ({
  type: 'line',
  start: [+index, 0],
  end: [+index + 1, 0],
  style: {
    stroke,
    lineWidth: 2,
  },
});

export const createMarker = ({ index, value, title, onClick, ...props }) => ({
  type: 'html',
  position: [index, 0],
  html: () => {
    const el = document.createElement('div');
    el.title = title;
    el.addEventListener('click', onClick);
    ReactDOM.render(<Marker label={value} {...props} />, el);
    return el;
  },
});

export const transformLegendItems = (legendItems) =>
  legendItems.map(({ field, label, symbol, stroke, fill = '' }) => ({
    id: field,
    value: field,
    name: field,
    label,
    marker: {
      symbol: () => symbol,
      style: {
        stroke,
        fill,
        lineWidth: 2,
      },
      spacing: 48,
    },
  }));

/**
 * Create visual indication of missing sections on a graph
 * @param {any[]} rawData Array of datapoints with potentially empty values
 * @param {string} xField Field for data's x-axis
 * @param {string} yField Field used to determine data prevalence
 * @return Annotations
 */
export const createUnusedZones = (rawData, xField, yField) => {
  const fieldsWithData = new Set(
    rawData
      .map((item) => {
        if (
          item[yField] !== null &&
          item[yField] !== undefined &&
          !Number.isNaN(item[yField])
        )
          return item[xField];

        return undefined;
      })
      .filter((x) => !!x)
  );

  // Filter to single instance of each 'xField' value, preferring those with data
  const seenZones = new Set();
  const data = rawData
    .filter((item) => {
      if (seenZones.has(item[xField])) return false;
      if (
        fieldsWithData.has(item[xField]) &&
        (item[yField] === null ||
          item[yField] === undefined ||
          Number.isNaN(item[yField]))
      ) {
        return false;
      }

      seenZones.add(item[xField]);
      return true;
    })
    .sort((z1, z2) => z1[xField].localeCompare(z2[xField]));

  const emptyAnnotations = [];
  data.forEach((item, index) => {
    if (index === 0 || index === data.length - 1) return;
    if (!Number.isNaN(+item[yField])) return;

    let startIndex = index;
    let endIndex = index + 1;

    if (!Number.isNaN(+data[index - 1][yField])) {
      // Leftmost period in empty section
      startIndex -= 0.75;

      // Both left and right valid
      if (!Number.isNaN(+data[index + 1][yField])) {
        endIndex = startIndex + 1.5;
      }
    } else if (!Number.isNaN(+data[index + 1][yField])) {
      // Rightmost period in empty section
      endIndex = startIndex + 0.75;
    }

    emptyAnnotations.push({
      type: 'region',
      start: [startIndex, 'min'],
      end: [endIndex, 'max'],
    });
  });

  // Add annotation for start
  if (Number.isNaN(+data[0][yField])) {
    emptyAnnotations.push({
      type: 'region',
      start: [-0.25, 'min'],
      end: [
        // Adjust for first empty
        data.length >= 2 && !Number.isNaN(+data[1][yField]) ? 0.75 : 1,
        'max',
      ],
    });
  }

  // Add annotation for end
  if (Number.isNaN(+data[data.length - 1][yField])) {
    emptyAnnotations.push({
      type: 'region',
      start: [
        // Adjust for last empty
        data.length >= 2 && !Number.isNaN(+data[data.length - 2][yField])
          ? data.length - 1.75
          : data.length - 1,
        'min',
      ],
      end: [data.length - 0.5, 'max'],
    });
  }

  return emptyAnnotations;
};
