import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useGetDwellTimeByStopQuery, useGetStopsQuery } from '../api';
import { selectTimeperiod } from '../../featurePersistence/store/selectors';
import { LOW_CONFIDENCE_THRESHOLD } from '../constants';

const useStops = ({ routeName, totalTrips }) => {
  const { dateRange, direction, periods } = useSelector(
    ({ routeFilters }) => routeFilters
  );
  const timeperiod = useSelector(selectTimeperiod);

  const { data: dwellTimeByStop, isFetching: isDwellTimeByStopLoading } =
    useGetDwellTimeByStopQuery({
      routeName,
      dateRange,
      direction,
      periods,
      timeperiod,
    });

  const { data: stops, isFetching: isStopsLoading } = useGetStopsQuery({
    routeName,
    dateRange,
    direction,
    periods,
    timeperiod,
  });

  const stopsWithDwellTime = useMemo(
    () =>
      stops?.map((stop, index) => {
        const metric = dwellTimeByStop?.[stop?.name] ?? {};
        const { dwellTime } = metric ?? {};

        return {
          ...stop,
          numTrips: metric?.numTrips?.value,
          stopid: stop.id,
          stopname: stop.name,
          name: stop.name,
          stoporder: stop.order,
          lat: stop.latitude,
          lon: stop.longitude,
          stopNumber: index + 1,
          metric: {
            ...dwellTime,
            secs: dwellTime?.value,
            mins: dwellTime?.value / 60,
          },
        };
      }),
    [stops, dwellTimeByStop]
  );

  const lowConfidenceStops = (stops || []).filter(
    (stop) => stop.numTrips < LOW_CONFIDENCE_THRESHOLD * totalTrips
  );

  const isLoading = useMemo(
    () => isStopsLoading || isDwellTimeByStopLoading,
    [isStopsLoading, isDwellTimeByStopLoading]
  );

  return {
    stops: stopsWithDwellTime,
    lowConfidenceStops,
    isMetricsEmpty: JSON.stringify(dwellTimeByStop) === '{}',
    isLoading,
  };
};

export default useStops;
