/* eslint-disable camelcase */
import { useGetUserLogsQuery } from '../api';

const useUserActivityLogs = (dateRange) => {
  const {
    data: logs,
    isLoading,
    isError,
    error,
  } = useGetUserLogsQuery(dateRange);

  return {
    logs,
    isLoading,
    isError,
    error,
  };
};

export default useUserActivityLogs;
