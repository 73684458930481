import { useMemo } from 'react';
import { useGetLastTimeAggregatedTimestampQuery } from '../api';

const useLastTimeDataAggregated = () => {
  const { data, isLoading, error } = useGetLastTimeAggregatedTimestampQuery();

  const lastTimeAggregatedTimestamp = useMemo(() => {
    if (!data || data?.length === 0) return null;

    const epochTimeStamp = data[0];
    const formattedTimestamp = new Date(epochTimeStamp).toLocaleString();

    return formattedTimestamp.replace(',', ' at');
  }, [data]);

  return { lastTimeAggregatedTimestamp, isLoading, error };
};

export default useLastTimeDataAggregated;
