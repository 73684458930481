import React, { useEffect } from 'react';
import AgencyGeneralTab from '../../components/AgencyGeneralTab';
import Card from '../../../../common/components/Card';
import ConfigurationLayout from '../../../../common/layouts/ConfigurationLayout';
import openNotification from '../../../../common/components/notification';
import useAgency from '../../hooks/useAgency';
import {
  useTspAnalyticsFeature,
  useConditionScheduleAdherenceFeature,
  useConditionOccupancyFeature,
  useConditionalPriorityFeature,
  useOffTripModeFeature,
} from '../../../../common/hooks/useAgencyDefaults';
import './style.css';
import useUser from '../../../userAuth/hooks/useUser';
import { UserType } from '../../../../common/enums';

const AgencyPage = () => {
  const { agencyId, userHasUserType } = useUser();
  const { agency } = useAgency();
  const regionId = agency?.regionId;

  const {
    agency: agencyData,
    isError: isAgencyError,
    error: agencyError,
    editAgencyResponse,
    edit: editAgency,
  } = useAgency();

  const {
    isLoading: isAgencyInfoloading,
    editFeaturePersistanceTSPResponse,
    editFeaturePersistanceTSP,
  } = useTspAnalyticsFeature();

  const {
    isLoadingConditionScheduleAdherence,
    updateConditionScheduleAdherence,
  } = useConditionScheduleAdherenceFeature();

  const { isLoadingConditionOccupancy, updateConditionOccupancy } =
    useConditionOccupancyFeature();

  const { isLoadingOffTripMode, updateOffTripMode, updateOffTripModeResponse } =
    useOffTripModeFeature();

  const {
    isLoadingConditionalPriority,
    updateConditionalPriority,
    updateConditionalPriorityResponse,
  } = useConditionalPriorityFeature();

  useEffect(() => {
    if (isAgencyError)
      openNotification({
        message: 'Error Getting Agency Data',
        description: agencyError?.message,
      });
  }, [agencyError?.message, isAgencyError]);

  return (
    <ConfigurationLayout>
      <Card className="custom-card">
        <AgencyGeneralTab
          agencyId={agencyId}
          agencyData={agencyData}
          editAgency={editAgency}
          editAgencyResponse={editAgencyResponse}
          admin={userHasUserType(UserType.MioAdmin)}
          regionId={regionId}
          editFeaturePersistanceTSPResponse={editFeaturePersistanceTSPResponse}
          editFeaturePersistanceTSP={editFeaturePersistanceTSP}
          updateOffTripMode={updateOffTripMode}
          updateOffTripModeResponse={updateOffTripModeResponse}
          updateConditionOccupancy={updateConditionOccupancy}
          updateConditionScheduleAdherence={updateConditionScheduleAdherence}
          updateConditionalPriority={updateConditionalPriority}
          updateConditionalPriorityResponse={updateConditionalPriorityResponse}
        />
      </Card>
    </ConfigurationLayout>
  );
};

export default AgencyPage;
