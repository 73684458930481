import React from 'react';
import { Modal } from 'antd';
import { APPROACHES_KEYSTROKES_MODAL_TEXT } from '../../constants';

const ApproachesKeystrokesModal = ({ isVisible, onClose }) => (
  <>
    <Modal
      title="Additional Keystrokes"
      visible={isVisible}
      onOk={onClose}
      onCancel={onClose}
      style={{ top: '25%' }}
      cancelButtonProps={{ style: { display: 'none' } }}
    >
      <ul style={{ listStyle: 'none' }}>
        {APPROACHES_KEYSTROKES_MODAL_TEXT.map((text, i) => (
          <li key={i}>{text}</li>
        ))}
      </ul>
    </Modal>
  </>
);

export default ApproachesKeystrokesModal;
