import React, { useEffect } from 'react';
import useAgencies from '../../hooks/useAgencies';
import EditAgency from '../EditAgency';
import openNotification from '../../../../common/components/notification';

const AgencyInfo = ({
  agencyId,
  agencyData,
  editAgency,
  editAgencyResponse,
  admin,
  regionId,
}) => {
  const {
    agencies,
    isError: agenciesIsError,
    error: agenciesError,
    isLoading,
  } = useAgencies({ regionId });

  useEffect(() => {
    if (!isLoading && agenciesIsError && admin)
      openNotification({
        message: 'Error Getting Agencies',
        description: agenciesError?.message,
      });
  }, [admin, agenciesError?.message, agenciesIsError, isLoading]);

  return (
    <EditAgency
      admin={admin}
      agencyId={agencyId}
      agency={agencyData}
      agencies={agencies || []}
      editAgency={editAgency}
      response={editAgencyResponse}
      editPropertiesVisible={admin}
    />
  );
};

export default AgencyInfo;
