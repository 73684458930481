import { Select as AntSelect } from 'antd';
import { Make, MakeModels, Model } from '../../common/enums';

export const capitalizeFirstLetter = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

export const filterIntersections = (searchKeys) => (data) => {
  if (searchKeys.length === 0) return data;
  return data.filter(({ intersectionName, intersectionId }) => {
    const nameIdSearchKeys = searchKeys.map((sk) => [
      sk.split(' ').slice(0, -1).join(' '),
      sk.split(' ').pop(),
    ]);
    return (
      (intersectionName &&
        nameIdSearchKeys.find((sk) => sk.includes(intersectionName))) ||
      nameIdSearchKeys.find((sk) => sk.includes(intersectionId))
    );
  });
};

export const filterVehicles = (searchKeys) => (data) => {
  if (searchKeys.length === 0) return data;
  return data.filter(({ name }) => searchKeys.includes(name));
};

export const filterUnassignedDevices = (searchKeys) => (data) => {
  if (searchKeys.length === 0) return data;
  return data.filter(({ serial }) => searchKeys.includes(serial));
};

export const mapDataToInitialValues = (formData) => {
  if (!formData) return {};

  const initialValues = {};
  formData.forEach((data, index) => {
    Object.entries(data).forEach((entry) => {
      const [key, value] = entry;
      initialValues[`${index}-${key}`] = value;
    });
  });
  return initialValues;
};

export const generateMakeOptions = () =>
  Object.values(Make).reduce((acc, value) => {
    if (
      MakeModels[value]?.length === 1 &&
      MakeModels[value][0] === Model.Integration
    )
      return acc;
    return [...acc, <AntSelect.Option key={value}>{value}</AntSelect.Option>];
  }, []);

// The below function is used specifically with Google Maps API
// NOTE: The function is slightly incorrect, and gives a direction that is
// offset by 90 degrees from the true direction that the user drew the line.
// Effectively, this means that the function returns the direction "west" of
// the true direction. This is a known issue, and the function is left as-is for now
export const computeDirection = (startPoint, endPoint) => {
  const heading = window.google.maps.geometry.spherical.computeHeading(
    startPoint,
    endPoint
  );

  // Headings are computer relative to global North (represented as 0 degrees)
  // Headings are in range -180 to 180 degrees
  // Computing offsets later on requires degrees ranging from 0 to 360, so we
  // need to convert
  let direction = heading;
  if (heading < -90 && heading >= -180) direction += 450;
  else direction += 90;
  return direction;
};

export const computeDistanceBetween = (coordOne, coordTwo) => {
  const { maps } = window.google;
  return (
    Math.round(
      maps.geometry.spherical.computeDistanceBetween(coordOne, coordTwo) * 100
    ) / 100
  );
};

export const convertLatLngToOffset = (latLng, center) => {
  const { maps } = window.google;
  const { lat, lng } = center;

  // X = longitude
  const x =
    Math.sign(latLng.lng() - lng) *
    Math.trunc(
      computeDistanceBetween(
        new maps.LatLng(lat, lng),
        new maps.LatLng(lat, latLng.lng())
      )
    );

  // Y = latitude
  const y =
    Math.sign(latLng.lat() - lat) *
    Math.trunc(
      computeDistanceBetween(
        new maps.LatLng(lat, lng),
        new maps.LatLng(latLng.lat(), lng)
      )
    );

  return { x, y };
};

export const convertOffsetToLatLng = (offset, center) => {
  const { maps } = window.google;
  const { x, y } = offset;
  const { lat, lng } = center;

  const calculatedLat = maps.geometry.spherical
    .computeOffset(new maps.LatLng(lat, lng), Math.abs(y), y > 0 ? 0 : 180)
    .lat();
  const calculatedLng = maps.geometry.spherical
    .computeOffset(new maps.LatLng(lat, lng), Math.abs(x), x > 0 ? 90 : 270)
    .lng();

  const boundedLat =
    Math.sign(calculatedLat) *
    Math.min(calculatedLat * Math.sign(calculatedLat), 90);
  const boundedLng =
    Math.sign(calculatedLng) *
    Math.min(calculatedLng * Math.sign(calculatedLng), 180);

  return new maps.LatLng(boundedLat, boundedLng);
};
