import { notification } from 'antd';
import 'antd/lib/notification/style/css';

const toString = (s) =>
  typeof s === 'object' && s !== null ? JSON.stringify(s) : s;

const openNotification = ({
  description,
  message,
  type = 'error',
  duration = 10,
}) => {
  notification[type]({
    message: toString(message),
    description: toString(description),
    key: `open-${Date.now()}`,
    duration,
    maxCount: 1,
    placement: 'bottomRight',
  });
};

export default openNotification;
