import { COLOR_NORMAL, COLOR_WARNING, COLOR_ERROR } from '../constants';

const statusColorPicker = (status) => {
  switch (status) {
    case 'NORMAL':
      return COLOR_NORMAL;
    case 'WARNING':
      return COLOR_WARNING;
    case 'ERROR':
      return COLOR_ERROR;
    default:
      return 'grey';
  }
};
export default statusColorPicker;
