import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Card, Row, Col, DatePicker, Space, Input } from 'antd';
import Table from '../../../../common/components/Table';
import TableSearch from '../../../../common/components/TableSearch';
import useUserActivityLogs from '../../hooks/useUserActivityLogs';
import LogsLayout from '../../../../common/layouts/LogsLayout';
import { selectUserActivityLogsFilter } from '../../store/selectors';
import { setFilters } from '../../store/slice';
import './style.css';

const { RangePicker } = DatePicker;
const { Search } = Input;

const dateFormatter = (timestamp) => {
  const date = new Date(timestamp * 1000);
  const dateTimeOptions = {
    timeZoneName: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  };
  const formattedDate = date.toLocaleString(undefined, dateTimeOptions);
  return `${formattedDate}`;
};

const ActivityLogs = () => {
  const { startDate, endDate } = useSelector(selectUserActivityLogsFilter);
  const [dateRange, setDateRange] = useState([startDate, endDate, Date.now()]);
  const [dataSource, setDataSource] = useState([]);
  const [value, setValue] = useState('');
  const dispatch = useDispatch();

  const { logs, isLoading, isError, error } = useUserActivityLogs(dateRange);

  useEffect(() => {
    if (!isLoading && logs) setDataSource(logs);
  }, [logs, isLoading]);

  const onRangeChange = (dates, dateStrings) => {
    if (dates) {
      setDateRange([dateStrings[0], dateStrings[1], Date.now()]);
      dispatch(
        setFilters({ startDate: dateStrings[0], endDate: dateStrings[1] })
      );
    }
  };

  const onUsernameSearchChange = (event) => {
    const currValue = event.target.value;
    setValue(currValue);
    const filteredData = logs.filter((entry) =>
      entry.email.includes(currValue)
    );
    setDataSource(filteredData);
  };

  const columns = [
    {
      title: 'User Name',
      dataIndex: 'email',
      key: 'email',
      sorter: (a, b) => a.email.localeCompare(b.email),
    },
    {
      title: 'Logged in',
      dataIndex: 'session_starts',
      key: 'session_starts',
      sorter: (a, b) => a.session_starts - b.session_starts,
      render: (timestamp) => dateFormatter(timestamp),
    },
    {
      title: 'Last activity',
      dataIndex: 'last_activity',
      key: 'last_activity',
      sorter: (a, b) => a.last_activity - b.last_activity,
      render: (timestamp) => dateFormatter(timestamp),
    },
  ];

  return (
    <LogsLayout>
      <Card>
        <Row gutter={24}>
          <Col span={6}>
            <h2>Activity Log</h2>
          </Col>
          <Col span={10}>
            <div>
              <Space direction="horizontal">
                <b>Date Range: </b>
                <RangePicker
                  defaultValue={[
                    moment(startDate, 'YYYY-MM-DD'),
                    moment(endDate, 'YYYY-MM-DD'),
                  ]}
                  onChange={onRangeChange}
                />
              </Space>
            </div>
          </Col>
          <Col span={8}>
            <div>
              <Search
                placeholder="Search by username"
                allowClear
                style={{ width: 300 }}
                onChange={onUsernameSearchChange}
              />
            </div>
          </Col>
        </Row>
        <div className="card-padding-top">
          <Table
            rowKey={(record) => `${record.email}_${record.session_starts}`}
            dataSource={dataSource}
            columns={columns}
            isLoading={isLoading}
          />
        </div>
      </Card>
    </LogsLayout>
  );
};

export default ActivityLogs;
