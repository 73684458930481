import React, { memo } from 'react';
import { Row, Col } from 'antd';
import './style.css';
import { convertRelativeToPercentages } from '../../../../common/utils';
import InfoTooltip from '../../../../common/components/InfoTooltip';
import { TooltipText } from '../../../../common/constants/definitions';

const StatusCard = ({ normal, warning, error }) => {
  const [p1, p2, p3] = convertRelativeToPercentages(
    [normal, warning, error],
    2
  );

  return (
    <Row className="status-row">
      <Col className="total-col" span={4}>
        <p className="p1">
          Intersections <InfoTooltip text={TooltipText.IhmTotal} />
        </p>
        <p className="p2">{normal + warning + error}</p>
        <p className="p3">in total</p>
      </Col>
      <Col className="normal-col" span={4}>
        <p className="p1">
          Normal <InfoTooltip text={TooltipText.IhmNormal} />
        </p>
        <p className="p2">{normal}</p>
        <p className="p3">{p1}</p>
      </Col>
      <Col className="warning-col" span={4}>
        <p className="p1">
          Warning
          <InfoTooltip text={TooltipText.IhmWarning} />
        </p>
        <p className="p2">{warning}</p>
        <p className="p3">{p2}</p>
      </Col>
      <Col className="error-col" span={4}>
        <p className="p1">
          Error <InfoTooltip text={TooltipText.IhmError} />
        </p>
        <p className="p2">{error}</p>
        <p className="p3">{p3}</p>
      </Col>
    </Row>
  );
};

export default memo(StatusCard);
