/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { resetApi } from '../../../redux/utils/api';
import { accessLocalStorage } from '../../../common/utils';
import { IS_MIO_APP } from '../../../common/constants/environment';
import { UserType, UserRole } from '../../../common/enums';

const { storeLocalItem, getLocalItem } = accessLocalStorage();

const AGENCY_ID_KEY = 'user/agencyId';
const REGION_ID_KEY = 'user/regionId';
const REGION_KEY = 'user/region';

/**
 * @typedef {Object} UserState
 * @property {UserType} userType
 * @property {boolean} mioAdmin
 * @property {boolean} mioViewer
 * @property {typeof UserRole[]} roles
 * @property {boolean} loading
 * @property {string} username
 * @property {string} agencyId
 * @property {string} regionId
 * @property {string} region
 * @property {string?} error
 * @property {string?} accessToken
 */

const initialState = /** @type {UserState}  */ ({
  loading: true,
  username: null,
  agencyId: localStorage.getItem(AGENCY_ID_KEY),
  regionId: localStorage.getItem(REGION_ID_KEY),
  region: '',
});

const user = createSlice({
  name: 'user',
  initialState,
  reducers: {
    loginUserRequest: (state) => {
      state.loading = true;
    },
    loginUserSuccess: (state, { payload }) => {
      if (IS_MIO_APP) {
        return {
          ...state,
          ...payload,
          loading: false,
        };
      }

      let agencyId = getLocalItem(AGENCY_ID_KEY) ?? '';
      if (!payload.mioAdmin) {
        agencyId = payload.agencyId;
        storeLocalItem(AGENCY_ID_KEY, payload.agencyId);
      }

      return {
        ...state,
        ...payload,
        loading: false,
        agencyId,
      };
    },
    logoutUserRequest: (state) => ({
      ...state,
      loading: true,
    }),
    logoutUserSuccess: (state) => {
      localStorage.clear();
      return {
        ...state,
        loading: false,
        accessToken: null,
        error: '',
        userType: '',
        region: '',
        agencyId: '',
      };
    },
    authUserFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      accessToken: null,
      error: payload,
      userType: '',
      region: '',
      agencyId: '',
    }),
    setAdminRegion: (state, { payload }) => {
      storeLocalItem(REGION_KEY, payload.name);
      storeLocalItem(REGION_ID_KEY, payload.regionId);

      state.region = payload.name;
      state.regionId = payload.regionId;
    },
    setAdminAgency: (state, { payload }) => {
      storeLocalItem(AGENCY_ID_KEY, payload.agencyId);

      state.agencyId = payload.agencyId;
    },
  },
});

export const authUserFailure = resetApi(user.actions.authUserFailure);
export const setAdminRegion = resetApi(user.actions.setAdminRegion);
export const setAdminAgency = resetApi(user.actions.setAdminAgency);
export const logoutUserSuccess = resetApi(user.actions.logoutUserSuccess);

export const { loginUserSuccess, loginUserRequest, logoutUserRequest } =
  user.actions;

export default user.reducer;
