import { Modal } from 'antd';

const ConnectionErrorModal = (
  okFunction,
  okFunctionParameter,
  errorMessage
) => {
  Modal.warning({
    title: 'Connection not available',
    content: errorMessage,
    closable: true,
    okText: 'Retry',
    onOk: () => {
      okFunction(okFunctionParameter);
    },
  });
};

export default ConnectionErrorModal;
