import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  useGetVehiclesQuery,
  useCreateVehicleMutation,
  useEditVehicleMutation,
  useDeleteVehicleMutation,
} from '../api';
import useDevices from './useDevices';
import useChangePreemption from './useChangePreemption';

// Returns list of all Vehicles
const useVehicles = ({ agencyId: selectedAgencyId } = {}) => {
  const [vehicleId, setVehicleId] = useState(null);
  const { agencyId: sessionAgencyId } = useSelector(({ user }) => user);

  const agencyId = useMemo(
    () => selectedAgencyId || sessionAgencyId,
    [selectedAgencyId, sessionAgencyId]
  );

  const {
    data: vehicles,
    isLoading,
    isError,
    error,
  } = useGetVehiclesQuery({ agencyId });

  const [createVehicle, createVehicleResponse] = useCreateVehicleMutation();
  const [deleteVehicle, deleteVehicleResponse] = useDeleteVehicleMutation();
  const [editVehicle, editVehicleResponse] = useEditVehicleMutation();

  const { devicesByVehicle } = useDevices({
    agencyId,
  });

  const vehiclesWithDevices = useMemo(() => {
    if (!vehicles || !devicesByVehicle) return [];

    return vehicles?.map((vehicle) => {
      const devices = devicesByVehicle[vehicle.id] || [];

      const vehicleWithDevice = {
        ...vehicle,
        devices,
        makeModels: [],
        preemption: 'N/A',
      };

      if (!devices?.length) return vehicleWithDevice;

      vehicleWithDevice.makeModels = new Set();

      devices.forEach((device) => {
        const { preemptionLicense, make, model } = device;
        const { preemption } = vehicleWithDevice;

        // Make/model
        vehicleWithDevice.makeModels.add(make);
        vehicleWithDevice.makeModels.add(model);

        // Preemption
        if (!preemptionLicense) return;

        vehicleWithDevice.preemption =
          vehicleWithDevice.preemption === 'active'
            ? preemption
            : preemptionLicense;
      });

      vehicleWithDevice.makeModels = [...vehicleWithDevice.makeModels];
      vehicleWithDevice.devices = [...vehicleWithDevice.devices];

      return vehicleWithDevice;
    }, []);
  }, [vehicles, devicesByVehicle]);

  const { changePreemption, changePreemptionResponse } = useChangePreemption({
    vehicleId,
  });

  const onEditVehicle = useCallback(
    (
      vehicle,
      { preemptionActive, preemptionChanged } = {
        preemptionActive: false,
        preemptionChange: false,
      }
    ) => {
      editVehicle(vehicle);

      const { devices: associatedDevices } = vehicle;
      if (!preemptionChanged || !associatedDevices) return;

      const licencedDevices = associatedDevices.reduce((devices, device) => {
        if (!('preemptionLicense' in device)) return devices;

        return {
          ...devices,
          [device.serial]: preemptionActive ? 'active' : 'inactive',
        };
      }, {});

      setVehicleId(vehicle.id);

      changePreemption({
        devices: licencedDevices,
        agencyId,
        vehicleId: vehicle.id,
      });
    },
    [agencyId, changePreemption, editVehicle]
  );

  const editVehicleAndPreemptionResponse = useMemo(() => {
    if (changePreemptionResponse?.status === 'uninitialized')
      return editVehicleResponse;

    if (editVehicleResponse?.isLoading || changePreemptionResponse?.isLoading)
      return { ...editVehicleResponse, isLoading: true };

    const response = {
      ...editVehicleResponse,
      reset: () => {
        editVehicleResponse.reset();
        changePreemptionResponse.reset();
      },
    };

    if (editVehicleResponse.isError || changePreemptionResponse.isError) {
      return {
        ...response,
        isError: true,
        isSuccess: false,
        error: editVehicleResponse.error || changePreemptionResponse.error,
      };
    }

    return {
      ...response,
      data: editVehicleResponse.data || changePreemptionResponse.data.message,
      isSuccess: true,
      isError: false,
    };
  }, [editVehicleResponse, changePreemptionResponse]);

  return {
    vehicles: vehiclesWithDevices,
    isLoading,
    isError,
    error,
    createVehicleResponse,
    createVehicle,
    deleteVehicleResponse,
    deleteVehicle,
    editVehicleResponse: editVehicleAndPreemptionResponse,
    editVehicle: onEditVehicle,
    changePreemption,
    changePreemptionResponse,
  };
};

export default useVehicles;
