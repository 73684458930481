import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Alert } from 'antd';
import AverageMetrics, {
  createTransitDelayLabels,
} from '../../../../common/components/AverageMetrics';
import AverageMetricsChart from '../../components/AverageMetricsChart';
import MapIcon from '../../../../common/icons/Map';
import { Metric } from '../../../../common/enums';
import TabView, {
  Header as TabViewHeader,
} from '../../../../common/components/TabView';
import useStats from '../../hooks/useStats';
import useSegments from '../../hooks/useSegments';
import { metricColorMap } from '../../../../common/constants';
import { pluralize } from '../../../../common/utils/string';
import { TooltipText } from '../../constants';
import TravelTimeMetricsMap from '../../components/MetricsMap/TravelTimeMetricsMap';
import DwellTimeMetricsMap from '../../components/MetricsMap/DwellTimeMetricsMap';
import SignalDelayMetricsMap from '../../components/MetricsMap/SignalDelayMetricsMap';
import DriveTimeMetricsMap from '../../components/MetricsMap/DriveTimeMetricsMap';
import './style.css';
import useStops from '../../hooks/useStops';
import useIntersections from '../../hooks/useIntersections';

const View = {
  Map: 'Map',
};

const ToolTipContent = ({ text, locations, locationType, totalTrips }) => (
  <div>
    <p style={{ marginBottom: '5px' }}>{text}</p>
    {locations.length !== 0 && (
      <Alert
        style={{ padding: '10px', paddingRight: '2px' }}
        description={
          <>
            <b>
              {locations.length} {pluralize(locationType, locations.length)}
            </b>{' '}
            with {`< 5%`} of {totalTrips} route trips
          </>
        }
        type="warning"
        icon={
          <span role="img">
            <svg
              viewBox="64 64 896 896"
              focusable="false"
              width="1em"
              height="1em"
              fill="currentColor"
              aria-hidden="true"
            >
              <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path>
              <path d="M464 688a48 48 0 1096 0 48 48 0 10-96 0zm24-112h48c4.4 0 8-3.6 8-8V296c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8v272c0 4.4 3.6 8 8 8z"></path>

              <g stroke="red" strokeWidth="3" fill="red">
                <circle id="danger" cx="780" cy="200" r="140" />
              </g>
            </svg>
          </span>
        }
        showIcon
      ></Alert>
    )}
  </div>
);

const RouteDetailsContent = ({ match, updateNumTrips }) => {
  const { route } = match.params;

  const [selectedMetric, setSelectedMetric] = useState(Metric.SignalDelay);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [view, setView] = useState(View.Map);

  const { avgMetrics, isLoading: isAvgMetricsLoading } = useStats({
    routeName: route,
  });

  const totalTrips = avgMetrics?.total_trips;
  const totalMins = avgMetrics?.traveltime?.mins;

  const isTotalMinsNaN = avgMetrics?.traveltime?.value === null;
  const isAvgMetricsEmpty =
    isTotalMinsNaN ||
    (!isAvgMetricsLoading && Object.keys(avgMetrics).length === 0);

  const {
    segments,
    isLoading: isSegmentsLoading,
    isMetricsEmpty: isSegmentMetricsEmpty,
  } = useSegments({
    routeName: route,
  });
  const {
    stops,
    lowConfidenceStops,
    isMetricsEmpty: isStopsMetricsEmpty,
    isLoading: isStopsLoading,
  } = useStops({
    routeName: route,
    totalTrips,
  });
  const {
    intersections,
    lowConfidenceIntersections,
    isMetricsEmpty: isIntersectionMetricsEmpty,
    isLoading: isIntersectionsLoading,
  } = useIntersections({ routeName: route, totalTrips });

  useEffect(() => {
    updateNumTrips(isAvgMetricsLoading ? undefined : totalTrips);
  }, [updateNumTrips, totalTrips, isAvgMetricsLoading]);

  const isLoading = useMemo(
    () =>
      isSegmentsLoading ||
      isAvgMetricsLoading ||
      isStopsLoading ||
      isIntersectionsLoading,
    [
      isSegmentsLoading,
      isAvgMetricsLoading,
      isStopsLoading,
      isIntersectionsLoading,
    ]
  );

  const handleSelectedMetricChange = (metric) => {
    setSelectedMetric(metric);
    setSelectedMarker();
  };

  const { dateRange } = useSelector(({ routeFilters }) => routeFilters);

  return (
    <section className="route-details">
      <TabView
        views={
          <>
            <MapIcon value={View.Map} />
          </>
        }
        value={view}
        onChange={setView}
        header={
          <TabViewHeader>
            <AverageMetrics
              avgMetrics={isAvgMetricsEmpty ? {} : avgMetrics}
              isLoading={isLoading}
              selectedMetric={selectedMetric.toLowerCase()}
              onSelectedMetricChange={handleSelectedMetricChange}
            >
              <AverageMetrics.Tab
                metric={Metric.SignalDelay}
                tooltip={
                  <ToolTipContent
                    text={TooltipText.SignalDelay}
                    locations={lowConfidenceIntersections}
                    locationType={'intersection'}
                    totalTrips={totalTrips}
                  />
                }
                hasLowConfidenceData={lowConfidenceIntersections.length !== 0}
                style={{
                  borderColor: metricColorMap[Metric.SignalDelay],
                }}
                createLabels={createTransitDelayLabels({ totalMins })}
              />
              <span className="route-details__sign">+</span>
              <AverageMetrics.Tab
                metric={Metric.DwellTime}
                tooltip={
                  <ToolTipContent
                    text={TooltipText.DwellTime}
                    locations={lowConfidenceStops}
                    locationType={'stop'}
                    totalTrips={totalTrips}
                  />
                }
                hasLowConfidenceData={lowConfidenceStops.length !== 0}
                style={{
                  borderColor: metricColorMap[Metric.DwellTime],
                }}
                createLabels={createTransitDelayLabels({ totalMins })}
              />
              <span className="route-details__sign">+</span>
              <AverageMetrics.Tab
                metric={Metric.DriveTime}
                tooltip={TooltipText.DriveTime}
                style={{
                  borderColor: metricColorMap[Metric.DriveTime],
                }}
                createLabels={createTransitDelayLabels({ totalMins })}
              />
              <span className="route-details__sign">=</span>
              <AverageMetrics.Tab
                metric={Metric.TravelTime}
                tooltip={TooltipText.TravelTime}
                style={{
                  borderColor: metricColorMap[Metric.TravelTime],
                }}
                createLabels={createTransitDelayLabels({ totalMins })}
              />
            </AverageMetrics>
          </TabViewHeader>
        }
      >
        <>
          {view === View.Map && selectedMetric === Metric.DwellTime && (
            <DwellTimeMetricsMap
              isEmpty={isStopsMetricsEmpty || isAvgMetricsEmpty}
              isLoading={isAvgMetricsLoading || isSegmentsLoading}
              dateRange={dateRange}
              onSelectedMarkerChange={setSelectedMarker}
              selectedMarker={selectedMarker}
              totalTrips={totalTrips}
              segments={segments}
              stops={stops}
            />
          )}

          {view === View.Map && selectedMetric === Metric.DriveTime && (
            <DriveTimeMetricsMap
              isEmpty={isSegmentMetricsEmpty || isAvgMetricsEmpty}
              isLoading={isAvgMetricsLoading || isSegmentsLoading}
              dateRange={dateRange}
              segments={segments}
              stops={stops}
              onSelectedMarkerChange={setSelectedMarker}
              selectedMarker={selectedMarker}
              totalTrips={totalTrips}
            />
          )}

          {view === View.Map && selectedMetric === Metric.SignalDelay && (
            <SignalDelayMetricsMap
              isEmpty={isIntersectionMetricsEmpty || isAvgMetricsEmpty}
              isLoading={isAvgMetricsLoading || isSegmentsLoading}
              stops={stops}
              intersections={intersections}
              segments={segments}
              dateRange={dateRange}
              onSelectedMarkerChange={setSelectedMarker}
              selectedMarker={selectedMarker}
              totalTrips={totalTrips}
            />
          )}

          {view === View.Map && selectedMetric === Metric.TravelTime && (
            <TravelTimeMetricsMap
              travelTime={avgMetrics?.traveltime?.mins}
              segments={segments}
              stops={stops}
              isLoading={isAvgMetricsLoading || isSegmentsLoading}
              dateRange={dateRange}
            />
          )}
        </>
      </TabView>
      {!isAvgMetricsEmpty && <AverageMetricsChart metric={selectedMetric} />}
    </section>
  );
};

export default withRouter(RouteDetailsContent);
