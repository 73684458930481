import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { loginUserSuccess, authUserFailure } from '../../store/slice';
import { useGetCurrentUserQuery } from '../../api';

const REFRESH_INTERVAL = 1000 * 60 * 5; // 5 minutes

const setupPendo = (user) => {
  try {
    // eslint-disable-next-line no-undef
    pendo.initialize({
      visitor: {
        id: user.externalId,
        email: user.email,
        full_name: `${user.firstName} ${user.lastName}`,
        role: user.userType,
      },
      account: {
        id: user.currentOrganization.id,
        name: user.currentOrganization.name,
      },
    });
  } catch (error) {
    console.error('Error setting up Pendo', error);
  }
};

const MioAuthListener = ({ children }) => {
  const dispatch = useDispatch();
  const { data, isLoading, isError } = useGetCurrentUserQuery({
    refetchInterval: REFRESH_INTERVAL,
    retry: 3,
  });

  useEffect(() => {
    if (isLoading) return;
    if (isError) {
      dispatch(authUserFailure('getCurrentUser'));
    }

    const { currentOrganization, externalId: username } = data ?? {};

    const user = {
      ...data,
      username,
      agencyId: currentOrganization?.id,
      agency: currentOrganization?.name,
    };

    setupPendo(user);

    dispatch(loginUserSuccess(user));
  }, [dispatch, data, isError, isLoading]);

  return children;
};

export default MioAuthListener;
