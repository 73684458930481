import { createApi } from '@reduxjs/toolkit/query/react';
import { FEATURE_PERSISTENCE_URL } from '../../../common/constants/apis';
import { baseQuery } from '../../../redux/utils/client';

const api = createApi({
  reducerPath: 'api/featurePersistence',
  tagTypes: ['FEATURE'],
  baseQuery,
  endpoints: (builder) => ({
    /* Feature Persistence */
    getFeature: builder.query({
      query: ({ agencyId, featureName }) => ({
        url: FEATURE_PERSISTENCE_URL,
        title: 'Get Feature',
        method: 'GET',
        params: {
          AgencyGUID: agencyId,
          FeatureName: featureName,
        },
      }),
      /**
       * Whenever a mutation is executed on an agency ID / feature, we want to invalidate only that feature in the cache
       */
      providesTags: (result) => {
        if (!result) return [{ type: 'Features', id: 'FEATURE' }];
        return [
          {
            type: 'Features',
            id: `${result?.AgencyGUID}-${result?.FeatureName}`,
          },
          // Exposing a global 'FEATURE' tag allows us to invalidate all features
          { type: 'Features', id: 'FEATURE' },
        ];
      },
    }),

    updateFeature: builder.mutation({
      query: ({ agencyId, featureName, feature }) => ({
        url: FEATURE_PERSISTENCE_URL,
        title: 'Update Feature',
        method: 'PUT',
        data: {
          AgencyGUID: agencyId,
          FeatureName: featureName,
          Feature: feature,
        },
      }),
      invalidatesTags: (_, __, { agencyId, featureName }) => [
        { type: 'Features', id: `${agencyId}-${featureName}` },
      ],
    }),

    createFeature: builder.mutation({
      query: ({ agencyId, featureName, feature }) => ({
        url: FEATURE_PERSISTENCE_URL,
        title: 'Create Feature',
        method: 'POST',
        data: {
          AgencyGUID: agencyId,
          FeatureName: featureName,
          Feature: feature,
        },
      }),
    }),
  }),
});

export const {
  useGetFeatureQuery,
  useUpdateFeatureMutation,
  useCreateFeatureMutation,
} = api;
export const { resetApiState } = api.util;
export default api;
