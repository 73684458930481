import { createSelector } from '@reduxjs/toolkit';
import { CHANNEL_MAPPINGS } from '../constants';

const selectConfiguration = ({ configuration }) => configuration;

export const selectApproachCoordinates = (approachNum) =>
  createSelector(
    selectConfiguration,
    ({ intersectionApproachMap }) =>
      intersectionApproachMap[approachNum]?.coordinates
  );

export const selectApproachChannel = (approachNum) =>
  createSelector(selectConfiguration, ({ intersectionChannels }) => {
    let channel = '';
    intersectionChannels.forEach((ch) => {
      const channelNum = ch.channel;
      const { assignedApproaches } = ch;

      if (assignedApproaches.includes(approachNum + 1))
        channel = CHANNEL_MAPPINGS[channelNum];
    });
    return channel;
  });

export const selectSelectedSegment = createSelector(
  selectConfiguration,
  ({ approachMapEditor }) => ({
    selectedSegment: approachMapEditor?.selectedSegment,
    approach: approachMapEditor?.approach,
  })
);

export const isSelectedSegment = (approachNum, index) =>
  createSelector(
    selectConfiguration,
    ({ approachMapEditor }) =>
      approachMapEditor.approach?.approachNum === approachNum &&
      approachMapEditor.selectedSegment?.index === index
  );
