/* eslint-disable camelcase */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Timeframe } from '../enums';
import { selectAgencyId } from '../../features/userAuth/store/selectors';
import { PERIODS } from '../constants';
import { getPeakValues } from '../utils';
import useFeaturePersistence, {
  Feature,
} from '../../features/featurePersistence/hooks/useFeaturePersistence';
import {
  setOnTimeRange,
  setTimeperiod,
  setConditionalPriority,
  setLateness,
  setOccupancy,
  setOffTripMode,
} from '../../features/featurePersistence/store/slice';

export const useTspAnalyticsFeature = () => {
  const dispatch = useDispatch();

  const agencyId = useSelector(selectAgencyId);

  const {
    feature: agencyInfo,
    update: updateFeature,
    updateResponse,
    isFetching: isLoading,
    isError,
    error,
  } = useFeaturePersistence({
    agencyId,
    featureName: Feature.TspAnalytics,
  });

  // Save timeperiod/on-time range filters
  useEffect(() => {
    if (!agencyInfo) return;
    dispatch(
      setTimeperiod([
        {
          start_time: agencyInfo?.peak_am_range?.start_time,
          label: 'peak_am',
          end_time: agencyInfo?.peak_am_range.end_time,
        },
        {
          start_time: agencyInfo?.peak_pm_range.start_time,
          label: 'peak_pm',
          end_time: agencyInfo?.peak_pm_range.end_time,
        },
      ])
    );
    dispatch(
      setOnTimeRange([
        parseInt(agencyInfo?.late_schedule_deviation_limit, 10),
        parseInt(agencyInfo?.early_schedule_deviation_limit, 10),
      ])
    );
  }, [agencyInfo, dispatch]);

  return {
    agencyInfo,
    isLoading,
    isError,
    error,
    editFeaturePersistanceTSPResponse: updateResponse,
    editFeaturePersistanceTSP: updateFeature,
  };
};

export const useConditionOccupancyFeature = () => {
  const dispatch = useDispatch();
  const agencyId = useSelector(selectAgencyId);

  const {
    feature: conditionOccupancyData,
    update: updateConditionOccupancy,
    updateResponse: updateConditionOccupancyResponse,
    isFetching: isLoadingConditionOccupancy,
    isError: isErrorConditionOccupancy,
    error: errorConditionOccupancy,
  } = useFeaturePersistence({
    agencyId,
    featureName: Feature.ConditionOccupancy,
  });

  useEffect(() => {
    if (!conditionOccupancyData) return;
    dispatch(setOccupancy(conditionOccupancyData?.occupancy_threshold));
  }, [conditionOccupancyData, dispatch]);

  return {
    conditionOccupancyData,
    updateConditionOccupancy,
    updateConditionOccupancyResponse,
    isLoadingConditionOccupancy,
    isErrorConditionOccupancy,
    errorConditionOccupancy,
  };
};

export const useOffTripModeFeature = () => {
  const dispatch = useDispatch();
  const agencyId = useSelector(selectAgencyId);
  const {
    feature: offTripModeData,
    update: updateOffTripMode,
    updateResponse: updateOffTripModeResponse,
    isFetching: isLoadingOffTripMode,
    isError: isErrorOffTripMode,
    error: errorOffTripMode,
  } = useFeaturePersistence({ agencyId, featureName: Feature.OffTripMode });

  useEffect(() => {
    if (!offTripModeData) return;
    dispatch(setOffTripMode(offTripModeData?.mode));
  }, [offTripModeData, dispatch]);

  return {
    offTripModeData,
    updateOffTripMode,
    updateOffTripModeResponse,
    isLoadingOffTripMode,
    isErrorOffTripMode,
    errorOffTripMode,
  };
};

export const useConditionScheduleAdherenceFeature = () => {
  const dispatch = useDispatch();
  const agencyId = useSelector(selectAgencyId);

  const {
    feature: conditionScheduleAdherenceData,
    update: updateConditionScheduleAdherence,
    updateResponse: updateConditionScheduleAdherenceResponse,
    isFetching: isLoadingConditionScheduleAdherence,
    isError: isErrorConditionScheduleAdherence,
    error: errorConditionScheduleAdherence,
  } = useFeaturePersistence({
    agencyId,
    featureName: Feature.ConditionScheduleAdherence,
  });

  useEffect(() => {
    if (!conditionScheduleAdherenceData) return;
    dispatch(setLateness(conditionScheduleAdherenceData?.lateness_threshold));
  }, [conditionScheduleAdherenceData, dispatch]);

  return {
    conditionScheduleAdherenceData,
    updateConditionScheduleAdherence,
    updateConditionScheduleAdherenceResponse,
    isLoadingConditionScheduleAdherence,
    isErrorConditionScheduleAdherence,
    errorConditionScheduleAdherence,
  };
};

export const useConditionalPriorityFeature = () => {
  const dispatch = useDispatch();
  const agencyId = useSelector(selectAgencyId);

  const {
    feature: conditionalPriorityData,
    update: updateConditionalPriority,
    updateResponse: updateConditionalPriorityResponse,
    isFetching: isLoadingConditionalPriority,
    isError: isErrorConditionalPriority,
    error: errorConditionalPriority,
  } = useFeaturePersistence({
    agencyId,
    featureName: Feature.ConditionalPriority,
  });

  useEffect(() => {
    if (!conditionalPriorityData) return;
    const conditionalPriority = {
      occupancy_threshold:
        conditionalPriorityData?.conditions?.occupancy_threshold,
      schedule_adherence:
        conditionalPriorityData?.conditions?.schedule_adherence,
      requires: conditionalPriorityData?.requires,
    };
    dispatch(setConditionalPriority(conditionalPriority));
  }, [conditionalPriorityData, dispatch]);

  return {
    conditionalPriorityData,
    updateConditionalPriority,
    updateConditionalPriorityResponse,
    isLoadingConditionalPriority,
  };
};

const useAgencyDefaults = () => {
  const agencyId = useSelector(selectAgencyId);
  const { agencyInfo, isLoading } = useTspAnalyticsFeature(agencyId);
  const [agencyPeriods, setAgencyPeriods] = useState(PERIODS);

  useEffect(() => {
    if (
      isLoading ||
      !agencyInfo ||
      !agencyInfo?.peak_am_range ||
      !agencyInfo?.peak_pm_range
    )
      return;

    const { peakAM, peakPM } = getPeakValues(
      agencyInfo?.peak_am_range,
      agencyInfo?.peak_pm_range
    );

    setAgencyPeriods([
      {
        label: `Peak AM (${peakAM})`,
        value: Timeframe.PeakAM,
      },
      {
        label: `Peak PM (${peakPM})`,
        value: Timeframe.PeakPM,
      },
      {
        label: 'Off-Peak (not peak or weekends)',
        value: Timeframe.OffPeak,
      },
      {
        label: 'Weekends (Saturday & Sunday)',
        value: Timeframe.Weekends,
      },
      {
        label: 'All',
        value: Timeframe.All,
      },
    ]);
  }, [isLoading, agencyInfo]);

  return { agencyId, agencyPeriods };
};
export default useAgencyDefaults;
