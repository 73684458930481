import { Modal as AntModal, Typography as AnyTypography } from 'antd';
import { Formik } from 'formik';
import { useEffect, useRef, useState } from 'react';

const { Title: AntTitle } = AnyTypography;

const FormModal = ({
  title,
  onSubmit,
  onCancel,
  children,
  cancelText,
  loading,
  destroyOnClose = true,
  validateOnChange = false,
  visible = true,
  ...props
}) => {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const formRef = useRef();

  const handleSubmit = async (values, { resetForm }) => {
    setConfirmLoading(true);
    await onSubmit(values, { resetForm });
    setConfirmLoading(false);
  };

  const handleOk = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  return (
    <AntModal
      onOk={handleOk}
      onCancel={onCancel}
      confirmLoading={confirmLoading || loading}
      visible={visible}
      destroyOnClose={destroyOnClose}
      okText="Submit"
      cancelText={cancelText}
      {...props}
    >
      {title && (
        <AntTitle level={5} style={{ marginBottom: '1.5rem' }}>
          {title}
        </AntTitle>
      )}
      <Formik
        innerRef={formRef}
        onSubmit={handleSubmit}
        validate={() =>
          formRef?.current?.errors &&
          Object.keys(formRef?.current?.errors).length > 0 &&
          console.log('[ERR]', formRef?.current?.errors)
        }
        validateOnChange={validateOnChange}
        {...props}
      >
        {children}
      </Formik>
    </AntModal>
  );
};

export default FormModal;
