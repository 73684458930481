import React from 'react';
import { Redirect } from 'react-router-dom';
import { Spin } from 'antd';
import {
  ADMIN_LANDING_PAGE,
  AGENCY_LANDING_PAGE,
} from '../../../../common/constants';
import { UserRole } from '../../../../common/enums';
import useUser from '../../hooks/useUser';

const LoginCallback = () => {
  const { username, error, agency, userHasRole, loading } = useUser();

  if (
    !loading &&
    username &&
    userHasRole([UserRole.ORG_ADMIN, UserRole.READ_WRITE])
  ) {
    return <Redirect to={ADMIN_LANDING_PAGE} />;
  }
  if (!loading && username && userHasRole([UserRole.VIEW_ONLY])) {
    return <Redirect to={AGENCY_LANDING_PAGE} />;
  }
  if (!loading && !agency) {
    return <Redirect to="/404" />;
  }
  if (!loading && error) return <Redirect to="/400" />;

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        color: '#0f9647',
        flexDirection: 'column',
        padding: '1em',
      }}
    >
      <Spin size="large" />
      <h2 style={{ color: '#1890ff' }}>Loading...</h2>
    </div>
  );
};

export default LoginCallback;
