const TrendLine = ({ ...props }) => (
  <svg
    {...props}
    width="25"
    height="16"
    viewBox="0 0 25 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.70711 0.292893C1.31658 -0.0976311 0.683417 -0.0976311 0.292893 0.292893C-0.0976311 0.683417 -0.0976311 1.31658 0.292893 1.70711L1.70711 0.292893ZM8.5 8.5L7.79289 9.20711C8.18342 9.59763 8.81658 9.59763 9.20711 9.20711L8.5 8.5ZM13 4L13.7071 3.29289C13.3166 2.90237 12.6834 2.90237 12.2929 3.29289L13 4ZM24 16C24.5523 16 25 15.5523 25 15V6C25 5.44772 24.5523 5 24 5C23.4477 5 23 5.44772 23 6V14H15C14.4477 14 14 14.4477 14 15C14 15.5523 14.4477 16 15 16H24ZM0.292893 1.70711L7.79289 9.20711L9.20711 7.79289L1.70711 0.292893L0.292893 1.70711ZM9.20711 9.20711L13.7071 4.70711L12.2929 3.29289L7.79289 7.79289L9.20711 9.20711ZM12.2929 4.70711L23.2929 15.7071L24.7071 14.2929L13.7071 3.29289L12.2929 4.70711Z"
      fill="currentColor"
    />
  </svg>
);

export default TrendLine;
