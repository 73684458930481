import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Typography as AntTypography, Modal as AntModal, Checkbox } from 'antd';
import { useSelector } from 'react-redux';
import EditRegion from '../../components/EditRegion';
import AgencyTableForRegion from '../../components/AgencyTableForRegion';
import CreateAgencyModal from '../../components/CreateAgencyModal';
import RegionsLayout from '../../../../common/layouts/RegionsLayout';
import ConfirmModal from '../../components/ConfirmModal';
import RegionsControls from '../../../../common/components/RegionsControls';
import openNotification from '../../../../common/components/notification';
import { WARNING_AGENCY_HAS_CHILD_ASSETS } from '../../../../common/constants';
import useRegion from '../../hooks/useRegion';
import useAgencies from '../../hooks/useAgencies';
import useVehicles from '../../hooks/useVehicles';
import useDevices from '../../hooks/useDevices';

const { Title: AntTitle } = AntTypography;

const ConfirmDeleteAgencyModal = ({
  visible,
  onResponseChange,
  selected,
  deleteAgencyResponse,
  ...props
}) => {
  const [enableDelete, setEnableDelete] = useState(false);

  const agencyId = selected[0];
  const { reset } = deleteAgencyResponse;

  const {
    vehicles,
    isLoading: isVehiclesLoading,
    isError: isVehiclesError,
  } = useVehicles({ agencyId });
  const {
    unassignedDevices,
    isLoading: isDevicesLoading,
    isError: isDevicesError,
  } = useDevices({ agencyId });

  const name = useSelector(
    ({ configuration }) => configuration.agencies[agencyId]?.name
  );

  const checkingForChildAssets = useMemo(
    () => selected.length > 0 && (isVehiclesLoading || isDevicesLoading),
    [selected.length, isVehiclesLoading, isDevicesLoading]
  );

  const hasChildAssets = useMemo(
    () => vehicles?.length > 0 || unassignedDevices?.length > 0,
    [vehicles, unassignedDevices]
  );

  const isError = useMemo(
    () => isVehiclesError || isDevicesError,
    [isVehiclesError, isDevicesError]
  );

  useEffect(() => {
    if (!checkingForChildAssets && !hasChildAssets) setEnableDelete(true);
  }, [checkingForChildAssets, hasChildAssets]);

  useEffect(() => {
    if (isError && visible && selected.length > 0) {
      openNotification({
        message: 'Error Checking for Child Assets',
        description: `Unable to check for child assets for agency ${name}`,
      });
      onResponseChange();
    }
  }, [isError, name, onResponseChange, selected.length, visible]);

  const onCheckboxChange = useCallback(() => setEnableDelete((ed) => !ed), []);

  const warningMessage = useMemo(() => {
    if (!hasChildAssets) return null;

    return (
      <Checkbox onChange={onCheckboxChange}>
        <span style={{ marginTop: '10px', color: 'darkOrange' }}>
          {WARNING_AGENCY_HAS_CHILD_ASSETS.replace('{}', name)}
        </span>
      </Checkbox>
    );
  }, [hasChildAssets, name, onCheckboxChange]);

  const handleResponseChange = useCallback(() => {
    reset();
    onResponseChange();
  }, [onResponseChange, reset]);

  useEffect(() => {
    if (!checkingForChildAssets && !hasChildAssets) setEnableDelete(true);
  }, [checkingForChildAssets, hasChildAssets]);

  return (
    <>
      {checkingForChildAssets && visible ? (
        <AntModal
          confirmLoading={checkingForChildAssets}
          visible={checkingForChildAssets}
          title="Delete"
        >
          <p>Checking for child assets...</p>
        </AntModal>
      ) : (
        <ConfirmModal
          visible={visible && !checkingForChildAssets}
          onResponseChange={() => handleResponseChange()}
          selected={selected}
          response={deleteAgencyResponse}
          enableDelete={enableDelete}
          warningMessage={warningMessage}
          {...props}
        />
      )}
    </>
  );
};

const RegionPage = () => {
  const [creating, setCreating] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [selected, setSelected] = useState([]);
  const [searchTerm, setSearchTerm] = useState();

  const regionId = useSelector(({ user }) => user?.regionId);

  const {
    region,
    isLoading: isRegionLoading,
    isError: isRegionError,
    error: regionError,
    editResponse: editRegionResponse,
    edit: editRegion,
  } = useRegion({ id: regionId });

  const {
    agencies,
    isLoading: isAgenciesLoading,
    isError: isAgenciesError,
    error: agenciesError,
    createAgencyResponse,
    createAgency,
    deleteAgencyResponse,
    deleteAgency,
  } = useAgencies({ regionId });

  const isLoading = useMemo(
    () => isRegionLoading || isAgenciesLoading,
    [isRegionLoading, isAgenciesLoading]
  );

  if (deleteAgencyResponse?.isSuccess) {
    deleteAgencyResponse.data = ['Agency Deleted Successfully'];
  }
  if (deleteAgencyResponse?.isError) {
    deleteAgencyResponse.data = ['Error Deleting Agency'];
  }

  useEffect(() => {
    if (isRegionError)
      openNotification({
        message: 'Error Getting Region',
        description: regionError.message,
      });
  }, [regionError?.message, isRegionError]);

  useEffect(() => {
    if (isAgenciesError)
      openNotification({
        message: 'Error Getting Agencies',
        description: agenciesError.message,
      });
  }, [agenciesError?.message, isAgenciesError]);

  const filteredAgencies = !agencies
    ? []
    : agencies.filter(
        ({ name }) => !searchTerm || name?.toLowerCase().includes(searchTerm)
      );

  const handleResponseChange = useCallback(() => {
    setCreating(false);
  }, [setCreating]);

  // Change the visibility of ConfirmDeleteAgencyModal
  const handleConfirmClick = () => {
    setDeleting(true);
  };

  const regionName = region ? region.name : '';

  return (
    <>
      <CreateAgencyModal
        regionName={regionName}
        agencies={agencies || []}
        onResponseChange={handleResponseChange}
        onCancel={() => setCreating(false)}
        visible={creating}
        createAgency={createAgency}
        response={createAgencyResponse}
      />

      {deleting && (
        <ConfirmDeleteAgencyModal
          visible={deleting}
          onResponseChange={() => setDeleting(false)}
          onSelectedChange={() => setSelected([])}
          selected={selected}
          delete={deleteAgency}
          deleteAgencyResponse={deleteAgencyResponse}
        />
      )}

      <RegionsLayout step={1}>
        <div>
          <AntTitle style={{ marginTop: '2rem' }}>Region {regionName}</AntTitle>
          {region && (
            <EditRegion
              region={region}
              editRegion={editRegion}
              response={editRegionResponse}
            />
          )}
        </div>

        <RegionsControls
          label={'Agency'}
          onSearch={(st) => setSearchTerm(st.toLowerCase())}
          onCreate={() => setCreating(true)}
          onDelete={handleConfirmClick}
        />

        <div>
          <AntTitle level={5} style={{ margin: '0.5rem 0 1.25rem 0' }}>
            Agency
          </AntTitle>
          <AgencyTableForRegion
            loading={isLoading}
            regionName={regionName}
            agencies={filteredAgencies}
            selected={selected}
            onSelectedChange={(value) => setSelected(value)}
          />
        </div>
      </RegionsLayout>
    </>
  );
};

export default RegionPage;
