import React from 'react';

const GttLogo = ({
  width = 28.51,
  height = 30,
  view = '0 0 32 32',
  fill = '#0E9647',
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox={view}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.592.673c-.97.081-2.184.307-3.004.57-.97.306-2.41.964-3.318 1.52-1.778 1.09-3.58 2.93-4.738 4.858-.526.864-.538.901-.138.507.983-.964 2.698-1.809 4.219-2.084 1.32-.238 3.186-.107 4.413.3 2.015.676 3.511 1.853 4.375 3.436.4.739.789 2.079.789 2.73v.206H9.583l-.075-.37c-.213-1.032-1.252-1.883-2.58-2.109-2.134-.363-3.742.62-4.468 2.736-.827 2.422-.388 5.658.957 7.091.295.313.87.689 1.302.852 1.04.388 2.91.212 3.9-.357.488-.288.989-.814 1.22-1.296.12-.244.238-.538.257-.65l.044-.201H6.929v-3.505h7.448l.007 3.924v3.931l.194-.407c.576-1.227 1.089-2.967 1.308-4.413.175-1.114.188-3.248.038-4.318-.213-1.49-.664-3.105-1.234-4.407-.87-1.99-2.547-4.15-4.275-5.508-.231-.182-.407-.35-.388-.376.013-.025.244-.112.514-.187.269-.076.613-.176.77-.226 1.226-.375 3.38-.476 4.788-.225 1.721.313 3.323.901 4.613 1.696 2.06 1.277 3.611 2.967 4.8 5.195.207.395.383.701.383.683 0-.126-.67-1.597-.964-2.116-2.297-4.062-6.06-6.729-10.385-7.361-.676-.1-2.366-.163-2.954-.119zM28.398 10.018l.22.038.018.638c.025.851.138.845.138-.006v-.657l.206-.025c.107-.013-.025-.025-.3-.025-.32 0-.42.012-.282.037zM29.394 10.069c0 .062-.025.375-.044.694-.025.313-.025.576.006.576.025 0 .05-.106.05-.238.007-.125.013-.263.026-.294.006-.037.018-.2.018-.37.006-.168.032-.287.063-.262.025.025.112.275.2.55.088.282.188.52.22.533.062.019.431-.983.381-1.033-.019-.013-.094.175-.169.42-.081.243-.163.444-.194.444-.025 0-.125-.257-.22-.564-.1-.338-.2-.563-.25-.563-.043 0-.08.05-.087.107zM30.364 10.03c-.019.038-.013.088.025.114.031.018.081.3.106.62.025.318.063.569.075.55.05-.044-.056-1.352-.112-1.352-.025 0-.07.031-.094.069z"
      fill={fill}
    />
    <path
      d="M16.756 15.965c0 .55-.02 1.039-.038 1.095-.031.088.1.1 1.083.113l1.114.018.019 3.925.012 3.93h2.191V17.16h2.191V14.97h-6.573v.996zM23.954 16.065v1.095l1.083.013 1.077.018.018 3.925.013 3.93h2.191V17.16h2.19V14.97h-6.572v1.096zM.369 23.432c.375.67 1.202 1.809 1.777 2.454 1.108 1.245 2.035 2.009 3.543 2.91 2.667 1.603 6.11 2.222 9.251 1.659 2.229-.4 4.119-1.233 6.066-2.685.763-.564.901-.739.181-.226-1.721 1.227-4.15 2.028-6.572 2.172-1.158.07-2.986-.225-4.4-.7-.695-.232-1.046-.389-1.02-.458.012-.03.35-.294.757-.582.763-.544 2.103-1.815 2.647-2.503l.282-.363h-1.49l-.069-.52c-.043-.282-.087-.657-.112-.826-.019-.175-.057-.4-.075-.514l-.044-.194-.344.401c-1.09 1.27-2.623 1.903-4.613 1.903-1.259 0-2.222-.175-3.3-.607-.556-.22-1.401-.689-1.752-.97-.425-.338-.763-.639-.807-.714-.025-.05-.075-.088-.107-.088-.03 0 .057.207.2.451z"
      fill={fill}
    />
  </svg>
);

export default GttLogo;
