/* eslint-disable camelcase */
import { useCallback } from 'react';
import {
  useCreateFeatureMutation,
  useGetFeatureQuery,
  useUpdateFeatureMutation,
} from '../api';

export const Feature = {
  Whelen: 'whelen',
  TspAnalytics: 'tsp-analytics',
  ConditionScheduleAdherence: 'condition-schedule-adherence',
  ConditionalPriority: 'conditional-priority',
  ConditionOccupancy: 'condition-occupancy',
  OffTripMode: 'off-trip-mode',
};

const useFeaturePersistence = ({ agencyId, featureName }) => {
  const { data: featureEntry, ...rest } = useGetFeatureQuery({
    agencyId,
    featureName,
  });
  const [updateFeature, updateResponse] = useUpdateFeatureMutation();
  const [createFeature, createResponse] = useCreateFeatureMutation();

  const update = useCallback(
    (updatedFeature) => {
      updateFeature({
        agencyId,
        featureName,
        feature: {
          ...featureEntry?.Feature,
          ...updatedFeature,
        },
      });
    },
    [updateFeature, agencyId, featureName, featureEntry?.Feature]
  );

  const create = useCallback(
    (newFeature) => {
      createFeature({
        agencyId,
        featureName,
        feature: newFeature,
      });
    },
    [createFeature, agencyId, featureName]
  );

  return {
    ...rest,
    feature: featureEntry?.Feature,
    update,
    updateResponse,
    create,
    createResponse,
  };
};

export default useFeaturePersistence;
