import React from 'react';
import ServerTable from '../../components/ServerTable';

const VPSContent = ({ agencyName }) => (
  <div className="VPSPage">
    <ServerTable agencyName={agencyName} />
  </div>
);

export default VPSContent;
