import { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import openNotification from '../components/notification';
import { trim } from '../utils/string';
import { setLastNotification } from '../../redux/logger/slice';

const useMutationNotification = (
  { data, error, isSuccess, isError, reset, ...rest },
  {
    onFinish = () => {},
    formatSuccessNotification = (d) => ({ message: d }),
    formatErrorNotification = (e) => ({ message: e?.message }),
  }
) => {
  const { lastNotification } = useSelector(({ logger }) => logger);
  const dispatch = useDispatch();

  const handleFinish = useCallback(() => {
    onFinish();
    if (reset) reset();
  }, [onFinish, reset]);

  useEffect(() => {
    const notificationKey = `success-${data}-${Math.floor(Date.now() / 1000)}`;
    if (isSuccess && notificationKey !== lastNotification) {
      openNotification({
        type: 'success',
        ...formatSuccessNotification(data),
      });
      handleFinish();
      dispatch(setLastNotification(notificationKey));
    }
  }, [
    data,
    isSuccess,
    lastNotification,
    handleFinish,
    formatSuccessNotification,
    dispatch,
  ]);

  useEffect(() => {
    const notificationKey = `error-${error?.message}-${Math.floor(
      Date.now() / 1000
    )}`;
    if (isError && notificationKey !== lastNotification) {
      let description = error?.message;

      // Handle message objects of the format { "error": ... }
      if (description?.error) {
        description = description?.error;
      }

      // Wrap object responses
      if (description) {
        description = trim(JSON.stringify(description), '"');
      }

      openNotification({
        description,
        ...formatErrorNotification(error),
      });
      handleFinish();
      dispatch(setLastNotification(notificationKey));
    }
  }, [
    error,
    isError,
    handleFinish,
    formatErrorNotification,
    lastNotification,
    dispatch,
  ]);

  return { onFinish: handleFinish };
};

export default useMutationNotification;
