import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useGetDriveTimeBySegmentQuery } from '../api';
import { selectTimeperiod } from '../../featurePersistence/store/selectors';
import useRoutes from './useRoutes';

const findAngleAtClosestPoint = ({ lat, lon }, points) => {
  if (!points?.length) return 0;

  let minDistance;
  let minAngle;

  for (let i = 0; i < points.length; i += 1) {
    const [currLat, currLon] = points[i][0];
    const distance = (currLat - lat) ** 2 + (currLon - lon) ** 2;

    if (!minDistance || distance < minDistance) {
      minDistance = distance;
      [, minAngle] = points[i];
    }
  }

  return minAngle;
};

/**
 * NOTE: Unused but left for future use
 * Create a gradient function mapping a latitude/longitude pair to the angle of
 * the route segment
 */
const createGradient = (points) => {
  const gradientPoints = [];
  points.forEach(({ lat, lon }, index) => {
    if (index === 0 || index === points.length - 1) {
      return;
    }

    const nextPoint = points[index + 1];
    const prevPoint = points[index - 1];

    const nextAngle = Math.atan2(+nextPoint.lon - +lon, +nextPoint.lat - +lat);
    const prevAngle = Math.atan2(+lon - +prevPoint.lon, +lat - +prevPoint.lat);

    gradientPoints.push([[lat, lon], (nextAngle + prevAngle) / 2]);
  });

  return (point) => findAngleAtClosestPoint(point, gradientPoints);
};

const useSegments = ({ routeName }) => {
  const { dateRange, direction, periods } = useSelector(
    ({ routeFilters }) => routeFilters
  );
  const timeperiod = useSelector(selectTimeperiod);

  const { data: driveTimeBySegment, isFetching: isDriveTimeBySegmentLoading } =
    useGetDriveTimeBySegmentQuery({
      routeName,
      dateRange,
      direction,
      periods,
      timeperiod,
    });

  const { selectedRoute, isLoading: isRoutesLoading } = useRoutes({
    routeName,
  });

  const segments = useMemo(() => {
    if (isRoutesLoading) {
      return [];
    }

    return selectedRoute?.segments ?? [];
  }, [isRoutesLoading, selectedRoute?.segments]);

  const isLoading = useMemo(
    () => isDriveTimeBySegmentLoading || isRoutesLoading,
    [isDriveTimeBySegmentLoading, isRoutesLoading]
  );

  const segmentsWithDriveTime = useMemo(
    () =>
      segments.map((segment) => {
        const metric =
          driveTimeBySegment?.[`${segment.stopstartid}-${segment.stopendid}`] ??
          {};
        const { driveTime } = metric ?? {};

        const centerPoint =
          segment?.points[Math.floor(segment?.points?.length / 2)];

        return {
          ...segment,
          lat: centerPoint.lat,
          lon: centerPoint.lon,
          name: `${segment.stopstartname} to ${segment.stopendname}`,
          metric: {
            ...driveTime,
            secs: driveTime?.value,
            mins: driveTime?.value / 60,
          },
        };
      }),
    [segments, driveTimeBySegment]
  );

  return {
    segments: segmentsWithDriveTime,
    selectedRoute,
    isMetricsEmpty: JSON.stringify(driveTimeBySegment) === '{}',
    isLoading,
  };
};

export default useSegments;
