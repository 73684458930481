/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { CURRENT_DATE, DATE_90_DAYS_AGO } from '../../../common/constants';

const initialState = {
  filters: {
    startDate: DATE_90_DAYS_AGO,
    endDate: CURRENT_DATE,
  },
};

const userActivityLogs = createSlice({
  name: 'userActivityLogs',
  initialState,
  reducers: {
    setFilters: (store, { payload }) => {
      store.filters = payload;
    },
  },
});

export const { setFilters } = userActivityLogs.actions;
export default userActivityLogs.reducer;
