import React from 'react';

const BusStop = ({ width, height, size = 'large', lowConfidence = false }) => {
  const iconWidth = width || (size === 'small' ? 20 : 40);
  const iconHeight = height || (size === 'small' ? 20 : 40);

  return (
    <svg
      width={iconWidth}
      height={iconHeight}
      viewBox={`0 0 40 40`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.2454 1.59304C12.5267 1.31174 12.9082 1.1537 13.306 1.1537L26.9565 1.1537C27.3543 1.1537 27.7359 1.31174 28.0172 1.59304L37.6695 11.2454C37.9508 11.5267 38.1088 11.9082 38.1088 12.3061L38.1088 25.9565C38.1088 26.3544 37.9508 26.7359 37.6695 27.0172L28.0172 36.6695C27.7359 36.9508 27.3543 37.1089 26.9565 37.1089L13.306 37.1089C12.9082 37.1089 12.5267 36.9508 12.2454 36.6695L2.59301 27.0172C2.3117 26.7359 2.15367 26.3544 2.15367 25.9565L2.15367 12.3061C2.15367 11.9082 2.3117 11.5267 2.59301 11.2454L12.2454 1.59304Z"
        stroke={lowConfidence ? 'red' : 'currentColor'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 10C9 9.44772 9.44772 9 10 9H30C30.5523 9 31 9.44772 31 10V28C31 28.5523 30.5523 29 30 29H29V30C29 30.5523 28.5523 31 28 31H24C23.4477 31 23 30.5523 23 30V29H17V30C17 30.5523 16.5523 31 16 31H12C11.4477 31 11 30.5523 11 30V29H10C9.44772 29 9 28.5523 9 28V10ZM29 27V23H11V27H29ZM11 21H29V14H11V21ZM11 12H29V11H11V12ZM17.5 16C17.5 15.4477 17.9477 15 18.5 15H21.5C22.0523 15 22.5 15.4477 22.5 16C22.5 16.5523 22.0523 17 21.5 17H18.5C17.9477 17 17.5 16.5523 17.5 16ZM12 25C12 24.4477 12.4477 24 13 24H14C14.5523 24 15 24.4477 15 25C15 25.5523 14.5523 26 14 26H13C12.4477 26 12 25.5523 12 25ZM16.5 25C16.5 24.4477 16.9477 24 17.5 24H22.5C23.0523 24 23.5 24.4477 23.5 25C23.5 25.5523 23.0523 26 22.5 26H17.5C16.9477 26 16.5 25.5523 16.5 25ZM25 25C25 24.4477 25.4477 24 26 24H27C27.5523 24 28 24.4477 28 25C28 25.5523 27.5523 26 27 26H26C25.4477 26 25 25.5523 25 25Z"
        fill={lowConfidence ? 'red' : 'currentColor'}
        fillOpacity="0.85"
      />
    </svg>
  );
};

export default BusStop;
