// A wrapper for <Route>
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getStoredToken } from '../../utils';
import { IS_MIO_APP } from '../../../../common/constants/environment';

const PrivateRoute = ({ children, ...rest }) => {
  const { accessToken: userToken } = useSelector(({ user }) => user);
  const accessToken = userToken != null ? userToken : getStoredToken();

  return (
    <Route
      {...rest}
      render={() =>
        accessToken || IS_MIO_APP ? children : <Redirect to="/login" />
      }
    />
  );
};

export default PrivateRoute;
