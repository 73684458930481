const ENVIRONMENT_MAP = {
  'localhost:9000': {
    environment: 'develop',
    isMioApp: true,
  },
  'app.platform-dev.mioeng.ca': {
    environment: 'develop',
    isMioApp: true,
  },
  'app.platform-staging.mioeng.ca': {
    environment: 'staging',
    isMioApp: true,
  },
  'miovision.one': {
    environment: 'production',
    isMioApp: true,
  },
};

const getEnvironmentFromUrl = () => {
  // Ignore 'preview' subdomain in preview environments
  // preview-XXX.app.platform-<env>.mioeng.ca
  let host = window.location.host.split('preview-').pop();
  if (host.indexOf('app.') >= 0) {
    host = `app.${host.split('app.').pop()}`;
  }

  return (
    ENVIRONMENT_MAP[host] || {
      isMioApp: false,
    }
  );
};

const { isMioApp, environment } = getEnvironmentFromUrl();

export const IS_MIO_APP = isMioApp;
export const ENVIRONMENT = environment;
