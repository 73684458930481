import {
  useGetRegionsQuery,
  useCreateRegionMutation,
  useDeleteRegionMutation,
} from '../api';

// Returns list of all Regions
const useRegions = () => {
  const { data: regions, isLoading, isError, error } = useGetRegionsQuery();

  const [createRegion, createRegionResponse] = useCreateRegionMutation();
  const [deleteRegion, deleteRegionResponse] = useDeleteRegionMutation();

  return {
    regions,
    isLoading,
    isError,
    error,
    createRegionResponse,
    createRegion,
    deleteRegionResponse,
    deleteRegion,
  };
};

export default useRegions;
