import { useCallback } from 'react';
import { useSelector } from 'react-redux';

const useUser = () => {
  const {
    userType,
    mioAdmin,
    mioViewer,
    roles,
    loading,
    username,
    agencyId,
    regionId,
    region,
  } = useSelector(({ user }) => user);

  const userHasUserType = useCallback((ut) => userType === ut, [userType]);

  const userHasRole = useCallback(
    (rs) => rs.some((r) => (roles || []).includes(r)),
    [roles]
  );

  return {
    userType,
    mioAdmin,
    mioViewer,
    roles: roles || [],
    loading,
    username,
    agencyId,
    regionId,
    region,
    userHasUserType,
    userHasRole,
  };
};

export default useUser;
