import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import api, {
  useGetIntersectionQuery,
  useUpdateIntersectionMutation,
  useResetIntersectionMutation,
  useRefreshIntersectionMutation,
} from '../api';
import * as defaultIntersectionData from '../data/defaultDataIntersection.json';

const useIntersection = ({ intersectionId }) => {
  const dispatch = useDispatch();
  const { data, isFetching } = useGetIntersectionQuery({
    intersectionId,
  });
  const [updateIntersection, updateIntersectionResponse] =
    useUpdateIntersectionMutation();
  const { isLoading: isUpdating } = updateIntersectionResponse;

  const [resetIntersection, resetIntersectionResponse] =
    useResetIntersectionMutation();
  const { isLoading: isResetting } = resetIntersectionResponse;

  const [refresh] = useRefreshIntersectionMutation();

  const updateIntersectionCache = useCallback(
    (intersection) =>
      dispatch(
        api.util.updateQueryData(
          'getIntersection',
          { intersectionId: intersection.intersectionId },
          () => intersection
        )
      ),
    [dispatch]
  );

  const update = useCallback(
    (intersection) => {
      updateIntersection(intersection);
      updateIntersectionCache(intersection);
    },
    [updateIntersection, updateIntersectionCache]
  );

  const reset = useCallback(
    (intersection) => {
      const newIntersection = { ...intersection, ...defaultIntersectionData };
      resetIntersection(newIntersection);
      updateIntersectionCache(newIntersection);
    },
    [resetIntersection, updateIntersectionCache]
  );

  const isLoading = useMemo(
    () => isFetching || isUpdating || isResetting,
    [isFetching, isUpdating, isResetting]
  );

  const intersection = useMemo(() => {
    if (isLoading) return {};

    return {
      ...data,
      locationType: data?.locationType?.toUpperCase(),
    };
  }, [data, isLoading]);

  return {
    intersection,
    update,
    reset,
    refresh,
    updateIntersectionResponse,
    resetIntersectionResponse,
    isLoading,
  };
};

export default useIntersection;
