import React, { useCallback } from 'react';
import { Form as AntForm, Input as AntInput, Select as AntSelect } from 'antd';
import FormModal from '../../../../common/components/FormModal';
import { Make, MakeModels, Model } from '../../../../common/enums';
import { generateMakeOptions } from '../../utils';
import { editDeviceSchema } from '../../schemas';
import skeletonComm from '../../../../templates/skeleton_comm.json';
import useDevices from '../../hooks/useDevices';
import useMutationNotification from '../../../../common/hooks/useMutationNotification';
import './style.css';

const EditDeviceModal = ({ device, onClose, ...props }) => {
  const { editDeviceResponse, editDevice } = useDevices();
  const { isLoading } = editDeviceResponse;

  useMutationNotification(editDeviceResponse, {
    formatSuccessNotification: () => ({
      message: 'Device Updated Successfully',
    }),
    formatErrorNotification: () => ({
      message: 'Error Editing Device',
    }),
    onFinish: onClose,
  });

  const onSubmit = useCallback(
    async (values) => {
      editDevice({
        ...device,
        ...values,
      });
    },
    [device, editDevice]
  );

  return (
    <FormModal
      className="edit-device-modal"
      style={{ width: '700px' }}
      okText={'Save'}
      onSubmit={onSubmit}
      onCancel={onClose}
      validationSchema={editDeviceSchema}
      validateOnChange={false}
      destroyOnClose={true}
      loading={isLoading}
      initialValues={{ ...skeletonComm, ...device }}
      {...props}
    >
      {({ handleSubmit, handleChange, values, setFieldValue, errors }) => (
        <div>
          <h1 style={{ textAlign: 'center', margin: 0, marginBottom: '1%' }}>
            Device {values.id} Settings
          </h1>
          <AntForm
            className="edit-device-modal-form"
            layout="vertical"
            onSubmit={handleSubmit}
            onChange={handleChange}
          >
            <AntForm.Item
              className="edit-device-modal-form-item"
              label="Device ID"
              style={{ width: '40%' }}
            >
              <AntInput disabled type="text" name="id" value={values.id} />
            </AntForm.Item>
            <AntForm.Item
              help={errors.description}
              className="edit-device-modal-form-item"
              label="Description"
              style={{ width: '40%' }}
            >
              <AntInput
                type="text"
                name="description"
                value={values.description}
              />
            </AntForm.Item>
            {values?.model !== Model.Integration && (
              <>
                <AntForm.Item
                  help={errors.make}
                  className="edit-device-modal-form-item"
                  label="Make"
                  style={{ width: '52%' }}
                  required
                >
                  <AntSelect
                    allowClear
                    name="make"
                    onChange={(value) => {
                      setFieldValue('make', value);
                      setFieldValue('model');
                    }}
                    value={values.make}
                  >
                    {generateMakeOptions()}
                  </AntSelect>
                </AntForm.Item>
                <AntForm.Item
                  className="edit-device-modal-form-item"
                  label="Model"
                  help={errors.model}
                  style={{ width: '25%' }}
                  required
                >
                  <AntSelect
                    allowClear
                    name="model"
                    onChange={(value) => setFieldValue('model', value)}
                    value={values.model}
                  >
                    {(MakeModels[values.make] || []).map((model) => (
                      <AntSelect.Option value={model} key={model}>
                        {model}
                      </AntSelect.Option>
                    ))}
                  </AntSelect>
                </AntForm.Item>
              </>
            )}
            <AntForm.Item
              help={errors.gttSerial}
              className="edit-device-modal-form-item"
              label="Opticom Serial"
              style={{ width: '47%' }}
              required={[Make.SierraWireless, Make.Cradlepoint].includes(
                values.make
              )}
            >
              <AntInput type="text" name="gttSerial" value={values.gttSerial} />
            </AntForm.Item>
            <AntForm.Item
              help={errors.serial}
              className="edit-device-modal-form-item"
              label="Serial"
              style={{ width: '47%' }}
              required
            >
              <AntInput type="text" name="serial" value={values.serial} />
            </AntForm.Item>
            {values?.model !== Model.Integration && (
              <>
                {[Make.GTT].includes(values.make) && (
                  <AntForm.Item
                    help={errors.addressLan}
                    className="edit-device-modal-form-item"
                    label="LAN Address"
                    style={{ width: '47%' }}
                    required
                  >
                    <AntInput
                      type="text"
                      name="addressLan"
                      value={values.addressLan}
                    />
                  </AntForm.Item>
                )}
                <AntForm.Item
                  help={errors.addressWan}
                  className="edit-device-modal-form-item"
                  label="WAN Address"
                  style={{ width: '47%' }}
                  required={[Make.GTT].includes(values.make)}
                >
                  <AntInput
                    type="text"
                    name="addressWan"
                    value={values.addressWan}
                  />
                </AntForm.Item>
              </>
            )}
            {(values.make === Make.SierraWireless ||
              values.make === Make.Cradlepoint) && (
              <>
                <AntForm.Item
                  help={errors.addressMac}
                  className="edit-device-modal-form-item"
                  label="MAC Address"
                  style={{ width: '47%' }}
                  required
                >
                  <AntInput
                    type="text"
                    name="addressMac"
                    value={values.addressMac}
                  />
                </AntForm.Item>
                <AntForm.Item
                  help={errors.imei}
                  className="edit-device-modal-form-item"
                  label="IMEI"
                  style={{ width: '47%' }}
                  required
                >
                  <AntInput type="text" name="imei" value={values.imei} />
                </AntForm.Item>
              </>
            )}
          </AntForm>
        </div>
      )}
    </FormModal>
  );
};

export default EditDeviceModal;
