import { useState } from 'react';
import { Tabs } from 'antd';
import AgencyFeatureTab from '../AgencyFeatureTab';
import AgencyInfo from '../AgencyInfo';
import './style.css';

const { TabPane } = Tabs;

const AgencyGeneralTab = ({
  agencyId,
  agencyData,
  editAgency,
  editAgencyResponse,
  admin,
  regionId,
  editFeaturePersistanceTSPResponse,
  editFeaturePersistanceTSP,
  updateOffTripMode,
  updateOffTripModeResponse,
  updateConditionOccupancy,
  updateConditionScheduleAdherence,
  updateConditionalPriority,
  updateConditionalPriorityResponse,
}) => {
  const [activeTab, setActiveTab] = useState('general');

  return (
    <Tabs
      onChange={setActiveTab}
      defaultActiveKey={activeTab}
      className="agency-tabs"
    >
      <TabPane tab="General" key="general" data-tabKey="general">
        <div className="content-padding">
          {agencyId && regionId && (
            <AgencyInfo
              agencyId={agencyId}
              agencyData={agencyData}
              editAgency={editAgency}
              editAgencyResponse={editAgencyResponse}
              admin={admin}
              regionId={regionId}
            />
          )}
        </div>
      </TabPane>

      {agencyData?.priority === 'Low' && (
        <TabPane tab="Feature" key="feature" data-tabKey="feature">
          <AgencyFeatureTab
            editFeaturePersistanceTSPResponse={
              editFeaturePersistanceTSPResponse
            }
            editFeaturePersistanceTSP={editFeaturePersistanceTSP}
            updateConditionOccupancy={updateConditionOccupancy}
            updateConditionScheduleAdherence={updateConditionScheduleAdherence}
            updateConditionalPriority={updateConditionalPriority}
            updateConditionalPriorityResponse={
              updateConditionalPriorityResponse
            }
            updateOffTripMode={updateOffTripMode}
            updateOffTripModeResponse={updateOffTripModeResponse}
            agencyId={agencyId}
          />
        </TabPane>
      )}

      <TabPane tab="Integration" key="integration"></TabPane>
    </Tabs>
  );
};
export default AgencyGeneralTab;
