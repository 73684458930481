import { useCallback } from 'react';
import useMutationNotification from '../../../common/hooks/useMutationNotification';
import useFeaturePersistence, {
  Feature,
} from '../../featurePersistence/hooks/useFeaturePersistence';
import { useCreateFeatureMutation } from '../../featurePersistence/api';

const API_KEY_FIELD_NAME = 'X-Api-Key';
const DEFAULT_FEATURE = {
  // Required fields
  api_endpoint: 'https://placeholder.com',
  accessKey: '',
  vehicle_positions_url: '',
};

export const useCreateWhelenFeature = () => {
  const [createFeature, createResponse] = useCreateFeatureMutation();

  const create = useCallback(
    ({ agencyId, apiKey }) => {
      createFeature({
        agencyId,
        feature: { ...DEFAULT_FEATURE, [API_KEY_FIELD_NAME]: apiKey },
        featureName: Feature.Whelen,
      });
    },
    [createFeature]
  );

  useMutationNotification(createResponse, {
    formatErrorNotification: () => ({
      message: 'Failed to Add Whelen API Key',
    }),
    formatSuccessNotification: () => ({
      message: 'Successfully Added Whelen API Key',
    }),
  });

  return [create, createResponse];
};

const useWhelenIntegration = ({ agencyId }) => {
  const { feature, update, updateResponse, create, createResponse } =
    useFeaturePersistence({
      agencyId,
      featureName: Feature.Whelen,
    });

  const apiKey = (feature || {})[API_KEY_FIELD_NAME];

  useMutationNotification(updateResponse, {
    formatErrorNotification: () => ({
      message: 'Failed to Set Whelen API Key',
    }),
    formatSuccessNotification: () => ({
      message: 'Successfully Set Whelen API Key',
    }),
  });

  useMutationNotification(createResponse, {
    formatErrorNotification: () => ({
      message: 'Failed to Add Whelen API Key',
    }),
    formatSuccessNotification: () => ({
      message: 'Successfully Added Whelen API Key',
    }),
  });

  const upsertApiKey = useCallback(
    (newApiKey) => {
      if (!feature) {
        create({
          ...DEFAULT_FEATURE,
          [API_KEY_FIELD_NAME]: newApiKey,
        });
        return;
      }

      update({
        [API_KEY_FIELD_NAME]: newApiKey,
      });
    },
    [create, feature, update]
  );

  return {
    isActive: !!feature,
    feature,
    apiKey,
    upsertApiKey,
  };
};

export default useWhelenIntegration;
