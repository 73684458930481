import React, { useState } from 'react';
import { Form as AntForm, Col, Row, Switch } from 'antd';
import { useSelector } from 'react-redux';
import useMutationNotification from '../../../../common/hooks/useMutationNotification';

const OffTripModeSettings = ({
  updateOffTripMode,
  updateOffTripModeResponse,
  admin,
}) => {
  const { offTripMode } = useSelector(
    ({ featurePersistence }) => featurePersistence
  );

  const [offTripModeCheck, setOffTripModeCheck] = useState(
    offTripMode.mode === 'ENABLED'
  );

  useMutationNotification(updateOffTripModeResponse, {
    formatSuccessNotification: () => ({
      message: 'Off-Trip mode updated',
    }),
    formatErrorNotification: ({ message }) => ({
      message: 'Error while updating Off-Trip mode',
      description: message,
    }),
  });

  const handleSwitchChange = (checked) => {
    // Update offTripModeCheck state when switch changes
    setOffTripModeCheck(checked);
    updateOffTripMode({ mode: checked ? 'ENABLED' : 'DISABLED' });
  };

  return (
    <AntForm name="basic" autoComplete="off" colon={false}>
      <Row>
        <Col span={12}>
          <div className="padding-form">
            <Row>
              <Col span={24}>
                <h2>Off-Trip Mode</h2>
              </Col>
            </Row>
            <Row>
              <Col span={20}>
                Enabling Off-Trip mode will allow a vehicle to request TSP while
                it is not assigned a trip in GTFS
              </Col>
              <Col span={4} style={{ textAlign: 'right' }}>
                <div>
                  <div className="custom-flex-container">
                    <AntForm.Item
                      label={offTripModeCheck ? 'Enabled' : 'Disabled'}
                      name="offTripMode"
                    >
                      <Switch
                        defaultChecked={offTripModeCheck}
                        onChange={handleSwitchChange}
                        disabled={!admin}
                      />
                    </AntForm.Item>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </AntForm>
  );
};

export default OffTripModeSettings;
