import React from 'react';
import { APPROACH_COLORS } from '../../constants';
import { ApproachChannels } from '../../enums';
import './style.css';

const ChannelColorIcon = ({ color, channel, className }) => (
  <>
    <div
      style={{ backgroundColor: color }}
      className={`channel-color-icon ${className}`}
    />
    <p className={`channel-color-label ${className}`}>Ch {channel}</p>
  </>
);

const ChannelLegend = () => (
  <div className="channel-legend">
    <ChannelColorIcon
      color={APPROACH_COLORS[ApproachChannels.A]}
      channel="A"
      className="channel-legend-one"
    />
    <ChannelColorIcon
      color={APPROACH_COLORS[ApproachChannels.B]}
      channel="B"
      className="channel-legend-two"
    />
    <ChannelColorIcon
      color={APPROACH_COLORS[ApproachChannels.C]}
      channel="C"
      className="channel-legend-three"
    />
    <ChannelColorIcon
      color={APPROACH_COLORS[ApproachChannels.D]}
      channel="D"
      className="channel-legend-four"
    />
    <p className="channel-legend-label">Legend</p>
  </div>
);

export default ChannelLegend;
