/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect } from 'react';
import { Form as AntForm, Input as AntInput, Select as AntSelect } from 'antd';
import { useSelector } from 'react-redux';
import FormModal from '../../../../common/components/FormModal';
import useMutationNotification from '../../../../common/hooks/useMutationNotification';
import { Make, MakeModels } from '../../../../common/enums';
import skeletonComm from '../../../../templates/skeleton_comm.json';
import { deviceSchema } from '../../schemas';
import useDevices from '../../hooks/useDevices';
import { generateMakeOptions } from '../../utils';
import './style.css';

const CreateDevice = ({ onClose, ...props }) => {
  const agencyId = useSelector(({ user }) => user?.agencyId);
  const { createDeviceResponse, createDevice } = useDevices();
  const { isLoading } = createDeviceResponse;

  useMutationNotification(createDeviceResponse, {
    formatSuccessNotification: () => ({
      message: 'Device Created Successfully',
    }),
    formatErrorNotification: () => ({
      message: 'Error Creating Device',
    }),
    onFinish: onClose,
  });

  const onSubmit = useCallback(
    async (values) => {
      createDevice({
        ...skeletonComm,
        ...values,
        agencyId,
      });
    },
    [createDevice, agencyId]
  );

  return (
    <div className="Create">
      <FormModal
        className="create-device-modal"
        onSubmit={onSubmit}
        onCancel={onClose}
        destroyOnClose={true}
        loading={isLoading}
        validationSchema={deviceSchema}
        validateOnChange={false}
        initialValues={{ ...skeletonComm }}
        {...props}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          errors,
          isSubmitting,
          setFieldValue,
        }) => (
          <div>
            <h1 style={{ textAlign: 'center', margin: 0, marginBottom: '3%' }}>
              Create a New Device
            </h1>
            <AntForm
              className="create-device-modal-form"
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 12 }}
              onSubmit={handleSubmit}
              onChange={handleChange}
              initialValues={{ ...skeletonComm }}
            >
              <AntForm.Item label="Description" help={errors.description}>
                <AntInput
                  type="text"
                  name="description"
                  help={errors.description}
                  onChange={handleChange}
                  disabled={isSubmitting}
                />
              </AntForm.Item>
              <AntForm.Item
                label="Opticom Serial"
                help={errors.gttSerial}
                required={[Make.SierraWireless, Make.Cradlepoint].includes(
                  values.make
                )}
              >
                <AntInput
                  type="text"
                  name="gttSerial"
                  onChange={handleChange}
                  disabled={isSubmitting}
                />
              </AntForm.Item>
              <AntForm.Item label="Serial" help={errors.serial} required>
                <AntInput
                  type="text"
                  name="serial"
                  onChange={handleChange}
                  disabled={isSubmitting}
                />
              </AntForm.Item>
              {[Make.GTT].includes(values.make) && (
                <AntForm.Item
                  label="LAN Address"
                  help={errors.addressLan}
                  required
                >
                  <AntInput
                    type="text"
                    name="addressLan"
                    onChange={handleChange}
                    disabled={isSubmitting}
                  />
                </AntForm.Item>
              )}
              <AntForm.Item
                label="WAN Address"
                help={errors.addressWan}
                required={[Make.GTT].includes(values.make)}
              >
                <AntInput
                  type="text"
                  name="addressWan"
                  onChange={handleChange}
                  disabled={isSubmitting}
                />
              </AntForm.Item>
              <AntForm.Item label="Make" help={errors.make} required>
                <AntSelect
                  name="make"
                  disabled={isSubmitting}
                  onChange={(value) => {
                    setFieldValue('make', value);
                    setFieldValue('model', null);
                  }}
                  value={values.make}
                >
                  {generateMakeOptions()}
                </AntSelect>
              </AntForm.Item>
              {values.make && (
                <AntForm.Item label="Model" help={errors.model} required>
                  <AntSelect
                    name="model"
                    disabled={isSubmitting}
                    onChange={(value) => setFieldValue('model', value)}
                    value={values.model}
                  >
                    {(MakeModels[values.make] || []).map((model) => (
                      <AntSelect.Option value={model} key={model}>
                        {model}
                      </AntSelect.Option>
                    ))}
                  </AntSelect>
                </AntForm.Item>
              )}

              {(values.make === Make.SierraWireless ||
                values.make === Make.Cradlepoint) && (
                <div>
                  <AntForm.Item
                    help={errors.addressMac}
                    label="MAC Address"
                    required
                  >
                    <AntInput
                      type="text"
                      name="addressMac"
                      onChange={handleChange}
                      disabled={isSubmitting}
                    />
                  </AntForm.Item>
                  <AntForm.Item label="IMEI" help={errors.imei} required>
                    <AntInput
                      type="text"
                      name="imei"
                      onChange={handleChange}
                      disabled={isSubmitting}
                    />
                  </AntForm.Item>
                </div>
              )}
            </AntForm>
          </div>
        )}
      </FormModal>
    </div>
  );
};

export default CreateDevice;
