import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Form, Input, Select, Row, Col } from 'antd';
import moment from 'moment';

import {
  setIntersectionGeneralData,
  setIntersectionChannelNames,
  setIntersectionSaved,
  setIntersectionNtcipConfiguration,
} from '../../store/slice';
import { intersectionSchema } from '../../schemas';
import { ApproachChannels, RequesterCommunicationType } from '../../enums';
import './style.css';
import { UserRole } from '../../../../common/enums';
import useUser from '../../../userAuth/hooks/useUser';
import { PHYSICAL_MODELS } from '../../constants';

/**
 * Get the UTC offset in the form of +HH:MM or -HH:MM from a given ISO timezone
 * @param isoTimezone string. e.g. America/Los_Angeles
 * @returns {number} The UTC offset in minutes
 */
const getUtcOffsetFromIsoTimezone = (isoTimezone) =>
  moment.utc().tz(isoTimezone).utcOffset();

/**
 * Get the offset in the form of +HH:MM or -HH:MM from a offset in minutes
 * @returns {string} The UTC offset in the format +HH:MM or -HH:MM
 */
const formatOffset = (offset) => {
  // Format the offset as +HH:MM or -HH:MM
  const hours = Math.floor(Math.abs(offset) / 60)
    .toString()
    .padStart(2, '0');
  const minutes = (Math.abs(offset) % 60).toString().padStart(2, '0');
  const sign = offset >= 0 ? '+' : '-';

  return `${sign}${hours}:${minutes}`;
};

const SUPPORTED_VPS_TIME_ZONES = [
  'Etc/GMT+12',
  'Pacific/Honolulu',
  'America/Anchorage',
  'America/Los_Angeles',
  'America/Tijuana',
  'America/Mazatlan',
  'America/Phoenix',
  'America/Denver',
  'America/Mexico_City',
  'America/Guatemala',
  'America/Chicago',
  'America/Regina',
  'America/Bogota',
  'America/New_York',
  'America/Indianapolis',
  'America/Cuiaba',
  'America/Halifax',
  'America/La_Paz',
  'America/Santiago',
  'America/Asuncion',
  'America/Caracas',
  'America/St_Johns',
  'America/Buenos_Aires',
  'America/Sao_Paulo',
  'America/Cayenne',
  'America/Montevideo',
  'Etc/GMT+2',
  'America/Godthab',
  'Atlantic/Azores',
  'Atlantic/Cape_Verde',
  'UTC',
  'Africa/Casablanca',
  'Atlantic/Reykjavik',
  'Europe/London',
  'Europe/Berlin',
  'Europe/Budapest',
  'Europe/Paris',
  'Europe/Warsaw',
  'Africa/Lagos',
  'Africa/Windhoek',
  'Europe/Bucharest',
  'Europe/Chisinau',
  'Africa/Cairo',
  'Europe/Kiev',
  'Asia/Beirut',
  'Asia/Jerusalem',
  'Africa/Johannesburg',
  'Asia/Baghdad',
  'Asia/Riyadh',
  'Africa/Nairobi',
  'Asia/Amman',
  'Europe/Moscow',
  'Asia/Tehran',
  'Asia/Yerevan',
  'Asia/Baku',
  'Asia/Tbilisi',
  'Asia/Dubai',
  'Indian/Mauritius',
  'Asia/Kabul',
  'Asia/Karachi',
  'Asia/Tashkent',
  'Asia/Almaty',
  'Asia/Yekaterinburg',
  'Asia/Calcutta',
  'Asia/Colombo',
  'Asia/Katmandu',
  'Asia/Rangoon',
  'Asia/Bangkok',
  'Asia/Krasnoyarsk',
  'Asia/Novosibirsk',
  'Australia/Perth',
  'Asia/Shanghai',
  'Asia/Irkutsk',
  'Asia/Singapore',
  'Asia/Taipei',
  'Asia/Ulaanbaatar',
  'Asia/Tokyo',
  'Asia/Seoul',
  'Asia/Yakutsk',
  'Australia/Adelaide',
  'Australia/Darwin',
  'Australia/Brisbane',
  'Australia/Sydney',
  'Australia/Hobart',
  'Pacific/Port_Moresby',
  'Asia/Vladivostok',
  'Pacific/Guadalcanal',
  'Pacific/Fiji',
  'Pacific/Auckland',
  'Asia/Kamchatka',
  'Pacific/Apia',
  'Pacific/Tongatapu',
];

const SUPPORTED_VPS_TIME_ZONES_OPTIONS = SUPPORTED_VPS_TIME_ZONES.map(
  (value) => {
    const utcOffset = getUtcOffsetFromIsoTimezone(value);
    return { value, utcOffset };
  }
)
  .sort((a, b) => a.utcOffset - b.utcOffset)
  .map(({ value, utcOffset }) => ({
    value,
    label: `(${formatOffset(utcOffset)} UTC) ${value}`,
  }));

const ApproachesGeneral = ({
  generalData,
  ntcipConfiguration,
  channelNames,
}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const { userHasRole } = useUser();

  const handleGeneralDataChange = useCallback(() => {
    const {
      [ApproachChannels.A]: channelA,
      [ApproachChannels.B]: channelB,
      [ApproachChannels.C]: channelC,
      [ApproachChannels.D]: channelD,
      controllerIpAddress,
      controllerPort,
      communityName,
      ...rest
    } = form.getFieldsValue();

    dispatch(setIntersectionSaved(false));
    dispatch(
      setIntersectionChannelNames([channelA, channelB, channelC, channelD])
    );
    dispatch(
      setIntersectionGeneralData({
        ...generalData,
        ...rest,
      })
    );

    dispatch(
      setIntersectionNtcipConfiguration({
        communityName,
        controllerIpAddress,
        controllerPort,
      })
    );
  }, [form, dispatch, generalData]);

  const yupSync = {
    async validator({ field }, value) {
      await intersectionSchema.validateSyncAt(field, { [field]: value });
    },
  };

  const { model } = generalData;
  const isPhysical = useMemo(() => PHYSICAL_MODELS.includes(model), [model]);

  const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const [ntcipFlag, setNtcipFlag] = useState(true);
  useEffect(() => {
    if (
      [
        RequesterCommunicationType.NTCIP,
        RequesterCommunicationType.DISCRETE_NTCIP,
      ].includes(form.getFieldValue('communicationType'))
    )
      setNtcipFlag(false);
    else setNtcipFlag(true);
  }, [form, ntcipConfiguration]);

  const isEditor = userHasRole([UserRole.ORG_ADMIN, UserRole.READ_WRITE]);

  return (
    <Form
      form={form}
      className="general-form"
      layout="vertical"
      onFieldsChange={handleGeneralDataChange}
      initialValues={{
        ...generalData,
        [ApproachChannels.A]: channelNames[0],
        [ApproachChannels.B]: channelNames[1],
        [ApproachChannels.C]: channelNames[2],
        [ApproachChannels.D]: channelNames[3],
        ...ntcipConfiguration,
        location: 'Intersection',
      }}
    >
      <Row>
        <Col className="general-form-col" span={12}>
          <Form.Item
            className="form-item-style-1"
            name="location"
            label="Location Type"
          >
            <Select disabled />
          </Form.Item>
          <Form.Item
            className="form-item-style-1"
            name="communicationType"
            label="Controller Communication Type"
          >
            <Select disabled={!isEditor}>
              {isPhysical && (
                <Select.Option
                  value={RequesterCommunicationType.DISCRETE}
                  key={RequesterCommunicationType.DISCRETE}
                >
                  Discrete
                </Select.Option>
              )}
              <Select.Option
                value={RequesterCommunicationType.NTCIP}
                key={RequesterCommunicationType.NTCIP}
              >
                NTCIP
              </Select.Option>
              {!isPhysical && <Select.Option
                value={RequesterCommunicationType.CENTRALIZED}
                key={RequesterCommunicationType.CENTRALIZED}
              >
                Centralized
              </Select.Option>}
              {isPhysical && (
                <Select.Option
                  value={RequesterCommunicationType.DISCRETE_NTCIP}
                  key={RequesterCommunicationType.DISCRETE_NTCIP}
                >
                  Discrete/NTCIP
                </Select.Option>
              )}
              <Select.Option
                value={RequesterCommunicationType.OFF}
                key={RequesterCommunicationType.OFF}
              >
                None (Off)
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            className="form-item-style-3"
            name="controllerIpAddress"
            label="IP"
            rules={[yupSync]}
            hidden={ntcipFlag}
          >
            <Input disabled={ntcipFlag || !isEditor} />
          </Form.Item>
          <Form.Item
            className="form-item-style-3"
            name="controllerPort"
            label="UDP Port"
            rules={[yupSync]}
            hidden={ntcipFlag}
          >
            <Input disabled={ntcipFlag || !isEditor} />
          </Form.Item>
          <Form.Item
            className="form-item-style-1"
            name="communityName"
            label="Community Name"
            hidden={ntcipFlag}
            rules={[yupSync]}
          >
            <Input disabled={ntcipFlag} />
          </Form.Item>
          <h2 className="general-form-header">Channel names</h2>
          <Form.Item
            className="form-item-style-2"
            name={ApproachChannels.A}
            label="Channel A Name"
            rules={[yupSync]}
          >
            <Input disabled={!isEditor} />
          </Form.Item>
          <Form.Item
            className="form-item-style-2"
            name={ApproachChannels.B}
            label="Channel B Name"
            rules={[yupSync]}
          >
            <Input disabled={!isEditor} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            className="form-item-style-2"
            name={ApproachChannels.C}
            label="Channel C Name"
            rules={[yupSync]}
          >
            <Input disabled={!isEditor} />
          </Form.Item>
          <Form.Item
            className="form-item-style-2"
            name={ApproachChannels.D}
            label="Channel D Name"
            rules={[yupSync]}
          >
            <Input disabled={!isEditor} />
          </Form.Item>
          <h2 className="general-form-header">Physical Details</h2>
          <Form.Item
            className="form-item-style-5"
            name="intersectionName"
            label="Intersection Name"
            rules={[yupSync]}
          >
            <Input disabled={!isEditor} />
          </Form.Item>
          <Form.Item
            className="form-item-style-4"
            name="latitude"
            label="Latitude"
            rules={[yupSync]}
          >
            <Input disabled={!isEditor} />
          </Form.Item>
          <Form.Item
            className="form-item-style-4"
            name="longitude"
            label="Longitude"
            rules={[yupSync]}
          >
            <Input disabled={!isEditor} />
          </Form.Item>
          <Form.Item
            name="timezone"
            label="Time Zone"
            style={{ maxWidth: '16rem' }}
          >
            <Select
              showSearch
              filterOption={filterOption}
              options={SUPPORTED_VPS_TIME_ZONES_OPTIONS}
              disabled={!isEditor}
            ></Select>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default ApproachesGeneral;
