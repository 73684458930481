import { useCallback } from 'react';
import { useCreateIntersectionMutation } from '../api';

const useCreateIntersection = () => {
  const [
    createIntersection,
    { data: newIntersection, error, isSuccess, isLoading, isError },
  ] = useCreateIntersectionMutation();

  const create = useCallback(
    (i) => {
      createIntersection({ intersection: i });
    },
    [createIntersection]
  );

  return {
    create,
    newIntersection,
    error: error?.message?.error,
    isSuccess,
    isLoading,
    isError,
  };
};

export default useCreateIntersection;
