export const TooltipText = {
  HealthMonitoring:
    "Vehicle health monitoring displays when a vehicle was most recently heard by our system. If a vehicle has interacted with Opticom intersections and/or devices in the last 30 days, it will be counted as 'in communication'. Vehicles not 'in communication' will still appear in the table below.",
  IntersectionHealthMonitoring:
    'Intersections are categorized as Normal, Warning, or Error based on thier heartbeat status. If there are any preempt requests in the last 30 days for an intersection, it will appear in the table below.',
};

export const timezoneMap = {
  Central: 'America/Chicago',
  Mountain: 'America/Denver',
  Eastern: 'America/New_York',
  Pacific: 'America/Los_Angeles',
  Arizona: 'America/Phoenix',
};
