import { createSlice } from '@reduxjs/toolkit';
import {
  PEAKS_DEFIINITION,
  ON_TIME_RANGE,
  CONDITIONAL_PRIORITY_DEFAULTS,
  OFF_TRIP_MODE_DEFAULTS,
} from '../../../common/enums';

const initialState = {
  timeperiod: PEAKS_DEFIINITION,
  onTimeRange: ON_TIME_RANGE,
  conditionalPriority: CONDITIONAL_PRIORITY_DEFAULTS,
  offTripMode: OFF_TRIP_MODE_DEFAULTS,
};

const featurePersistence = createSlice({
  name: 'featurePersistence',
  initialState,
  reducers: {
    setTimeperiod: (state, { payload }) => {
      state.timeperiod = payload;
    },
    setOnTimeRange: (state, { payload }) => {
      state.onTimeRange = payload;
    },
    setLateness: (state, { payload }) => {
      state.conditionalPriority.lateness = payload;
    },
    setOccupancy: (state, { payload }) => {
      state.conditionalPriority.occupancy = payload;
    },
    setConditionalPriority: (state, { payload }) => {
      state.conditionalPriority.occupancy_threshold =
        payload.occupancy_threshold;
      state.conditionalPriority.schedule_adherence = payload.schedule_adherence;
      state.conditionalPriority.requires = payload.requires;
    },
    setOffTripMode: (state, { payload }) => {
      state.offTripMode.mode = payload;
    },
    resetConditionalPriority: (state) => {
      state.conditionalPriority = CONDITIONAL_PRIORITY_DEFAULTS;
      state.offTripMode = OFF_TRIP_MODE_DEFAULTS;
    },
  },
});

export const {
  setTimeperiod,
  setOnTimeRange,
  setConditionalPriority,
  resetConditionalPriority,
  setLateness,
  setOccupancy,
  setOffTripMode,
} = featurePersistence.actions;
export default featurePersistence.reducer;
