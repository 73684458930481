import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Form as AntForm, Input as AntInput } from 'antd';
import FormModal from '../../../../common/components/FormModal';
import skeletonVehicle from '../../../../templates/skeleton_vehicle.json';
import { vehicleSchema } from '../../schemas';
import useVehicles from '../../hooks/useVehicles';
import { LocalStorageKeys } from '../../enums';
import { accessLocalStorage } from '../../../../common/utils';
import useMutationNotification from '../../../../common/hooks/useMutationNotification';
import './style.css';

const { _, getLocalItem } = accessLocalStorage();

const CreateVehicle = ({ onClose, ...props }) => {
  const { agencyId } = useSelector(({ user }) => user);
  const { agency } = useSelector(({ configuration }) => configuration);
  const { priority } =
    Object.entries(agency).length === 0
      ? JSON.parse(getLocalItem(LocalStorageKeys.Agency))
      : agency;
  const { createVehicleResponse, createVehicle } = useVehicles();

  const { isLoading } = createVehicleResponse;

  useMutationNotification(createVehicleResponse, {
    formatErrorNotification: () => ({
      message: 'Error Creating Vehicle',
    }),
    formatSuccessNotification: () => ({
      message: 'Vehicle Created Successfully',
    }),
    onFinish: onClose,
  });

  const onSubmit = useCallback(
    async (values) => {
      createVehicle({
        ...skeletonVehicle,
        ...values,
        class: +values.class,
        vid: +values.vid,
        agencyId,
      });
    },
    [createVehicle, agencyId]
  );

  return (
    <div className="Create">
      <FormModal
        className="create-vehicle-modal"
        onSubmit={onSubmit}
        onCancel={onClose}
        destroyOnClose={true}
        loading={isLoading}
        validationSchema={vehicleSchema}
        validateOnChange={false}
        initialValues={{
          ...skeletonVehicle,
          ...{ priority },
        }}
        {...props}
      >
        {({ handleSubmit, handleChange, isSubmitting, errors }) => (
          <div>
            <h1 style={{ textAlign: 'center', margin: 0, marginBottom: '3%' }}>
              Create a New Vehicle
            </h1>
            <AntForm
              className="create-vehicle-modal-form"
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 12 }}
              onSubmit={handleSubmit}
              onChange={handleChange}
              initialValues={{ ...vehicleSchema }}
            >
              <AntForm.Item label="Name" help={errors.name} required>
                <AntInput
                  type="text"
                  name="name"
                  onChange={handleChange}
                  disabled={isSubmitting}
                  defaultValue={skeletonVehicle.name}
                />
              </AntForm.Item>
              <AntForm.Item label="Type" help={errors.type}>
                <AntInput
                  type="text"
                  name="type"
                  defaultValue={skeletonVehicle.type}
                  onChange={handleChange}
                  disabled={isSubmitting}
                />
              </AntForm.Item>
              <AntForm.Item label="Priority" help={errors.priority}>
                <AntInput
                  type="text"
                  name="priority"
                  value={priority}
                  disabled
                />
              </AntForm.Item>
              <AntForm.Item label="Description" help={errors.description}>
                <AntInput
                  type="text"
                  name="description"
                  onChange={handleChange}
                  disabled={isSubmitting}
                />
              </AntForm.Item>
              <AntForm.Item label="VID" help={errors.vid}>
                <AntInput
                  type="text"
                  name="vid"
                  onChange={handleChange}
                  disabled={isSubmitting}
                  defaultValue={skeletonVehicle.vid}
                />
              </AntForm.Item>
              <AntForm.Item label="Class" help={errors.class}>
                <AntInput
                  type="text"
                  name="class"
                  onChange={handleChange}
                  disabled={isSubmitting}
                  defaultValue={skeletonVehicle.class}
                />
              </AntForm.Item>
            </AntForm>
          </div>
        )}
      </FormModal>
    </div>
  );
};

export default CreateVehicle;
