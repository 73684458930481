import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Circle } from '@react-google-maps/api';
import { APPROACH_COLORS, CIRCLE_OPTIONS } from '../../constants';
import ApproachSegment from '../ApproachSegment';
import {
  updateApproachMapEditor,
  clearSelectedSegment,
  updateIntersectionApproachMap,
  setIntersectionSaved,
} from '../../store/slice';
import {
  selectApproachCoordinates,
  selectApproachChannel,
  selectSelectedSegment,
} from '../../store/selectors';
import {
  computeDistanceBetween,
  convertLatLngToOffset,
  convertOffsetToLatLng,
} from '../../utils';

const Approach = ({ approachNum, approachName, mapCenter }) => {
  const [approachCoords, setApproachCoords] = useState([]);
  const [approachSegments, setApproachSegments] = useState([]);
  const [endMarkers, setEndMarkers] = useState([]);
  const [isMouseDown, setIsMouseDown] = useState(false);
  // Have the coordinates been changed
  const [isDirty, setIsDirty] = useState(false);

  const dispatch = useDispatch();

  const coordinates = useSelector(selectApproachCoordinates(approachNum));
  const approachChannel = useSelector(selectApproachChannel(approachNum));
  const { selectedSegment, approach } = useSelector(selectSelectedSegment);

  const color = APPROACH_COLORS[approachChannel];
  const isSelectedSegmentInApproach = useMemo(
    () => selectedSegment && approach?.approachNum === approachNum,
    [approach?.approachNum, approachNum, selectedSegment]
  );

  useEffect(() => {
    const onMouseDown = () => setIsMouseDown(true);
    const onMouseUp = () => setIsMouseDown(false);

    document.addEventListener('mousedown', onMouseDown);
    document.addEventListener('mouseup', onMouseUp);

    return () => {
      document.removeEventListener('mousedown', onMouseDown);
      document.removeEventListener('mousedown', onMouseUp);
    };
  }, []);

  const onClick = useCallback(
    (index, segmentLength, segmentWidth, approachLength) => {
      if (isSelectedSegmentInApproach && selectedSegment?.index === index) {
        dispatch(clearSelectedSegment());
        return;
      }

      dispatch(
        updateApproachMapEditor({
          approach: {
            approachSegments,
            approachNum,
            approachName,
            approachLength,
            approachChannel,
          },
          selectedSegment: {
            index,
            segmentLength,
            segmentWidth,
          },
        })
      );
    },
    [
      isSelectedSegmentInApproach,
      selectedSegment?.index,
      dispatch,
      approachNum,
      approachName,
      approachSegments,
      approachChannel,
    ]
  );

  const updateApproachSegments = useCallback(
    (coords) => {
      if (!coords) return;

      const { maps } = window.google;
      const approachLength = Math.round(
        Math.round(maps.geometry.spherical.computeLength(coords) * 100) / 100
      );

      setApproachSegments([
        ...coords.reduce((acc, _, i) => {
          if (i === coords.length - 1) return acc;

          const startPoint = coords[i];
          const endPoint = coords[i + 1];
          const segmentLength = Math.round(
            computeDistanceBetween(startPoint, endPoint)
          );

          acc.push({
            key: i,
            index: i,
            startPoint,
            endPoint,
            segmentWidth: coordinates[i]?.width,
            segmentLength,
            approachLength,
            approachNum,
            color,
          });
          return acc;
        }, []),
      ]);
    },
    [coordinates, approachNum, color]
  );

  const onEndMarkerDrag = useCallback(
    (e, index) => {
      const { maps } = window.google;
      const updatedApproachCoords = [...approachCoords];
      updatedApproachCoords[index] = new maps.LatLng(
        e.latLng.lat(),
        e.latLng.lng()
      );

      const selectedSegmentIndex = selectedSegment?.index;
      const segmentLength = Math.round(
        computeDistanceBetween(
          updatedApproachCoords[selectedSegmentIndex],
          updatedApproachCoords[selectedSegmentIndex + 1]
        )
      );

      const approachLength = Math.round(
        Math.round(
          maps.geometry.spherical.computeLength(updatedApproachCoords) * 100
        ) / 100
      );

      dispatch(
        updateApproachMapEditor({
          approach: { approachLength },
          selectedSegment: { segmentLength },
        })
      );
      updateApproachSegments(updatedApproachCoords);
      setApproachCoords(updatedApproachCoords);
      setIsDirty(true);
    },
    [approachCoords, dispatch, selectedSegment, updateApproachSegments]
  );

  useEffect(() => {
    setEndMarkers(
      approachCoords?.map((coordinate, i) => ({
        key: i,
        center: coordinate,
        radius: 12,
        draggable: true,
        visible: !!(
          isSelectedSegmentInApproach &&
          selectedSegment &&
          (selectedSegment?.index === i || selectedSegment?.index === i - 1)
        ),
        options: { ...CIRCLE_OPTIONS, strokeColor: color },
        onDrag: (e) => onEndMarkerDrag(e, i),
      }))
    );
  }, [
    approachCoords,
    color,
    isSelectedSegmentInApproach,
    onEndMarkerDrag,
    selectedSegment,
  ]);

  useEffect(() => {
    setIsDirty(false);

    const convertedApproachCoords = coordinates?.map((d) =>
      convertOffsetToLatLng(d, mapCenter)
    );

    setApproachCoords(convertedApproachCoords);
    updateApproachSegments(convertedApproachCoords);
  }, [coordinates, mapCenter, updateApproachSegments]);

  // Save coordinates on change
  useEffect(() => {
    if (isMouseDown || !isDirty) return;

    dispatch(
      updateIntersectionApproachMap({
        approachNum,
        approach: {
          approachName,
          numberOfCoordinates: approachCoords.length,
          coordinates: approachCoords.map((c, index) => ({
            ...convertLatLngToOffset(c, mapCenter),
            width: coordinates[index]?.width,
          })),
        },
      })
    );
    dispatch(setIntersectionSaved(false));
  }, [
    approachCoords,
    approachName,
    approachNum,
    coordinates,
    dispatch,
    isDirty,
    isMouseDown,
    mapCenter,
  ]);

  return (
    <>
      {approachSegments.map((segment) => (
        <ApproachSegment key={segment.key} {...segment} onClick={onClick} />
      ))}
      {endMarkers.map((marker) => (
        <Circle key={marker.key} {...marker} />
      ))}
    </>
  );
};

export default Approach;
