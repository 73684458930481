import React, { useCallback, useState, useEffect } from 'react';
import {
  Form as AntForm,
  Input as AntInput,
  Select as AntSelect,
  Row,
  Col,
} from 'antd';
import { agencySchema } from '../../schemas';
import FormModal from '../../../../common/components/FormModal';
import openNotification from '../../../../common/components/notification';
import Table from '../../../../common/components/Table';
import Button from '../../../../common/components/Button';
import { Integration } from '../../../../common/enums';
import useWhelenIntegration from '../../hooks/useWhelenIntegration';
import {
  stateCodesByCountry,
  Country,
  getLabelForCountry,
  ModalSize,
} from '../../../../common/constants/values';
import './style.css';

const AgencyTable = ({
  admin,
  agency,
  onStartEditing,
  editPropertiesVisible,
}) => {
  const [windowSize, setWindowSize] = useState(undefined);
  useEffect(() => {
    const handleResize = async () => {
      setWindowSize(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
  }, []);

  const columns = [
    {
      key: 'name',
      dataIndex: 'name',
      title: 'Name',
    },
    {
      key: 'description',
      dataIndex: 'description',
      title: 'Description',
    },
    {
      key: 'priority',
      dataIndex: 'priority',
      title: 'Priority',
    },
    {
      key: 'agencyCode',
      dataIndex: 'agencyCode',
      title: 'Agency Code',
    },
    {
      key: 'city',
      dataIndex: 'city',
      title: 'City',
    },
    {
      key: 'state',
      dataIndex: 'state',
      title: 'State',
    },
    ...(admin
      ? [
          {
            key: 'cmsId',
            dataIndex: 'cmsId',
            title: 'CMS ID',
          },
        ]
      : []),
    ...(editPropertiesVisible
      ? [
          {
            title: '',
            dataIndex: 'operation',
            render: () => (
              <Button
                type="secondary"
                size="lg"
                location="right"
                onClick={() => onStartEditing()}
              >
                Edit Properties
              </Button>
            ),
          },
        ]
      : []),
  ];

  return (
    <div className="AgencyTable">
      <Table
        columns={columns}
        dataSource={agency ? [agency] : []}
        pagination={false}
        bordered={false}
        scroll={windowSize < 1600 ? { x: 1200 } : {}}
      />
    </div>
  );
};

const getCountryByStateProvince = (stateName) => {
  const statesUSA = stateCodesByCountry[Country.UNITED_STATES];
  const foundState = statesUSA.find((state) => state.code === stateName);
  if (foundState) {
    return Country.UNITED_STATES;
  }
  return Country.CANADA;
};

const EditAgency = ({
  agencies = [],
  agency,
  admin,
  agencyId,
  editAgency,
  response,
  editPropertiesVisible,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const { data, isLoading, isSuccess, isError, error } = response;
  const [integrations, setIntegrations] = useState([]);
  const [country, setCountry] = useState('');

  useEffect(() => {
    if (country) return;
    const countryForStateProvince = getCountryByStateProvince(agency?.state);
    setCountry(countryForStateProvince);
  }, [agency, country]);

  const { apiKey: whelenApiKey, upsertApiKey: upsertWhelenApiKey } =
    useWhelenIntegration({
      agencyId,
    });
  const [newWhelenApiKey, setNewWhelenApiKey] = useState();

  useEffect(() => {
    if (whelenApiKey) {
      setIntegrations((i) => [...new Set([...i, Integration.Whelen])]);
      setNewWhelenApiKey(whelenApiKey);
    }
  }, [whelenApiKey, setNewWhelenApiKey]);

  useEffect(() => {
    if (isSuccess) {
      openNotification({
        message: 'Agency Updated Successfully',
        type: 'success',
      });
      setIsEditing(false);
    }
  }, [data, isSuccess]);

  useEffect(() => {
    if (isError)
      openNotification({
        message: 'Error Editing Agency',
        description: error.message,
      });
  }, [error?.message, isError]);

  const onSubmit = useCallback(
    async (values) => {
      editAgency({
        ...values,
        agencyCode: +values.agencyCode,
      });

      if (whelenApiKey !== newWhelenApiKey) {
        upsertWhelenApiKey(newWhelenApiKey);
      }
    },
    [editAgency, newWhelenApiKey, upsertWhelenApiKey, whelenApiKey]
  );

  return (
    <div className="Edit">
      {isEditing && (
        <FormModal
          // title="Edit Agency"
          onSubmit={onSubmit}
          visible={isEditing}
          width={ModalSize.Small}
          onCancel={() => setIsEditing(false)}
          loading={isLoading}
          validationSchema={agencySchema}
          validateOnChange={false}
          initialValues={{
            ...agency,
            state: agency.state.toUpperCase(),
          }}
          agencies={agencies}
        >
          {({
            handleSubmit,
            handleChange,
            values,
            errors,
            isSubmitting,
            setFieldValue,
          }) => (
            <div>
              <h3 className="create-agency">Edit Agency</h3>
              <AntForm
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 12 }}
                onSubmit={handleSubmit}
                onChange={handleChange}
              >
                <Row>
                  <Col span={12}>
                    <AntForm.Item label="Name" help={errors.name} required>
                      <AntInput
                        type="text"
                        name="name"
                        onChange={handleChange}
                        disabled={true}
                        value={values.name}
                      />
                    </AntForm.Item>
                  </Col>
                  <Col span={12}>
                    <AntForm.Item label="Country" required>
                      <AntSelect
                        allowClear
                        name="country"
                        onChange={setCountry}
                        disabled={isSubmitting}
                        value={country}
                      >
                        <AntSelect.Option value="United States">
                          United States
                        </AntSelect.Option>
                        <AntSelect.Option value="Canada">
                          Canada
                        </AntSelect.Option>
                      </AntSelect>
                    </AntForm.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <AntForm.Item label="Description" help={errors.description}>
                      <AntInput
                        type="text"
                        name="description"
                        onChange={handleChange}
                        disabled={isSubmitting}
                        value={values.description}
                      />
                    </AntForm.Item>
                  </Col>
                  <Col span={12}>
                    <AntForm.Item
                      label={getLabelForCountry(country)}
                      help={errors.state}
                      required
                    >
                      <AntSelect
                        allowClear
                        name="state"
                        onChange={(value) => setFieldValue('state', value)}
                        disabled={isSubmitting}
                        value={values.state}
                      >
                        {stateCodesByCountry[country]?.map((row) => (
                          <AntSelect.Option value={row.code} key={row.code}>
                            {row.code}
                          </AntSelect.Option>
                        ))}
                      </AntSelect>
                    </AntForm.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <AntForm.Item
                      label="Priority"
                      help={errors.priority}
                      required
                    >
                      <AntSelect
                        allowClear
                        name="priority"
                        onChange={(value) => setFieldValue('priority', value)}
                        disabled={isSubmitting}
                        value={values.priority}
                      >
                        <AntSelect.Option value="High">High</AntSelect.Option>
                        <AntSelect.Option value="Low">Low</AntSelect.Option>
                      </AntSelect>
                    </AntForm.Item>
                  </Col>
                  <Col span={12}>
                    <AntForm.Item label="City" help={errors.city} required>
                      <AntInput
                        type="text"
                        name="city"
                        onChange={handleChange}
                        disabled={isSubmitting}
                        value={values.city}
                      />
                    </AntForm.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <AntForm.Item
                      label="Agency Code"
                      help={errors.agencyCode}
                      required
                    >
                      <AntInput
                        type="text"
                        name="agencyCode"
                        onChange={handleChange}
                        disabled={isSubmitting}
                        value={values.agencyCode}
                      />
                    </AntForm.Item>
                  </Col>
                  <Col span={12}>
                    <AntForm.Item label="Integrations" help={errors.name}>
                      <AntSelect
                        mode="multiple"
                        allowClear
                        options={Object.entries(Integration).map(
                          ([value, label]) => ({
                            label,
                            value,
                          })
                        )}
                        value={integrations}
                        disabled={isSubmitting}
                        onChange={setIntegrations}
                        onClear={() => setIntegrations([])}
                      />
                    </AntForm.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <AntForm.Item label="CMS ID" help={errors.cmsId}>
                      <AntSelect
                        allowClear
                        onChange={(value) => setFieldValue('cmsId', value)}
                        disabled={isSubmitting}
                        value={values.cmsId}
                        style={{ width: '100%' }}
                      >
                        {agencies &&
                          agencies.length &&
                          agencies.map(({ cmsId, name }, index) => (
                            <AntSelect.Option
                              key={index + 1}
                              value={cmsId}
                              width={15}
                            >
                              {cmsId} ({name})
                            </AntSelect.Option>
                          ))}
                      </AntSelect>
                    </AntForm.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    {/* Additional configuration options */}
                    {integrations.includes(Integration.Whelen) && (
                      <AntForm.Item
                        key="whelen"
                        label="Whelen API Key"
                        required
                      >
                        <AntInput
                          value={newWhelenApiKey}
                          onChange={(e) => setNewWhelenApiKey(e.target.value)}
                        />
                      </AntForm.Item>
                    )}
                  </Col>
                </Row>
              </AntForm>
            </div>
          )}
        </FormModal>
      )}
      <div style={{ marginTop: '1em', overflowX: 'scroll' }}>
        <h5>Agency Properties</h5>

        <AgencyTable
          admin={admin}
          agency={agency}
          onStartEditing={() => setIsEditing(true)}
          editPropertiesVisible={editPropertiesVisible}
        />
      </div>
    </div>
  );
};

export default EditAgency;
