import { useMemo, useState } from 'react';
import MetricsMap from '..';
import { DwellTimeMapLegend } from '../../MapLegend';
import {
  MAX_NUM_TOOLTIPS,
  createBusStopIcon,
  createMetricTooltip,
  getPaths,
  visibleMetricComparator,
} from '../../../pages/routeDetails/shapes';
import { createFlags } from '../../../../../common/utils/shapes';
import { metricColorMap } from '../../../../../common/constants';
import { Metric } from '../../../../../common/enums';
import { LOW_CONFIDENCE_THRESHOLD } from '../../../constants';
import { take } from '../../../../../common/utils/array';

const createStops = (stops = [], totalTrips = 0, onClick = () => {}) =>
  stops.map((stop) =>
    createBusStopIcon({
      isLowConfidence: stop.numTrips < totalTrips * LOW_CONFIDENCE_THRESHOLD,
      marker: stop,
      key: `stop-${stop.lat}-${stop.lon}`,
      onClick,
    })
  );

const createMarkers = (stops, bounds, onClick = () => {}) => {
  const sortedStops = (stops || []).sort(visibleMetricComparator(bounds));

  return take(sortedStops, MAX_NUM_TOOLTIPS).map((stop) =>
    createMetricTooltip({
      position: stop,
      onClick,
      minutes: stop.metric?.mins,
      placement: 'top',
      key: `stop-tooltip-${stop.lat}-${stop.lon}`,
    })
  );
};

const DwellTimeMetricsMap = ({
  stops,
  segments,
  totalTrips,
  onSelectedMarkerChange,
  ...props
}) => {
  const [bounds, setBounds] = useState();

  const flags = useMemo(() => createFlags(stops), [stops]);
  const icons = useMemo(
    () => createStops(stops, totalTrips, onSelectedMarkerChange),
    [onSelectedMarkerChange, totalTrips, stops]
  );
  const markers = useMemo(
    () => createMarkers(stops, bounds, onSelectedMarkerChange),
    [stops, bounds, onSelectedMarkerChange]
  );
  const paths = useMemo(
    () => getPaths(segments, () => metricColorMap[Metric.DwellTime]),
    [segments]
  );

  const shapes = useMemo(
    () => [...flags, ...paths, ...icons, ...markers],
    [flags, paths, icons, markers]
  );

  return (
    <MetricsMap
      onBoundsChange={setBounds}
      shapes={shapes}
      onSelectedMarkerChange={onSelectedMarkerChange}
      {...props}
    >
      <DwellTimeMapLegend />
    </MetricsMap>
  );
};

export default DwellTimeMetricsMap;
