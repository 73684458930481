import { getMarker } from '../components/Map/GMaps/drawShapes';
import FlagStartSVG from '../icons/FlagStart.svg';
import MarkerBottomSVG from '../icons/MarkerBottom.svg';
import MarkerTopSVG from '../icons/MarkerTop.svg';

// eslint-disable-next-line import/prefer-default-export
export const createFlags = (stops) => {
  if (!stops?.length) return [];

  const start = stops[0];

  // Can be used for flag at end
  // const end = stops[stops.length - 1];

  return [
    getMarker(
      start,
      () => {},
      {
        url: FlagStartSVG,
        anchor: { x: 0, y: 28 },
      },
      'flag'
    ),
  ];
};

export const createTooltip = ({
  position,
  text,
  onClick = () => {},
  placement = 'top',
  key = '',
  suffix = '',
}) => {
  if (!position) return null;

  return getMarker(
    position,
    onClick,
    {
      url: placement === 'top' ? MarkerTopSVG : MarkerBottomSVG,
      // Place the icon relative to the stop/intersection
      anchor: placement === 'top' ? { x: 8, y: 75 } : { x: 8, y: 15 },
    },
    `tooltip-${key}`,
    {
      text,
      className: `tooltip-map${suffix} ${
        placement === 'top' ? 'tooltip-map--top' : ''
      } ${!text || text.length === 1 ? 'tooltip-map--empty' : ''}`,
    }
  );
};
