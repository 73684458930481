import React, { useState, useMemo, useEffect } from 'react';
import openNotification from '../../../../common/components/notification';
import HealthMonitoringLayout from '../../../../common/layouts/HealthMonitoringLayout';
import TableCard from '../../../../common/components/TableCard';
import TableSearch from '../../../../common/components/TableSearch';
import VehiclesTable from '../../components/VehiclesTable';
import useVehicles from '../../hooks/useVehicles';
import useLastTimeDataAggregated from '../../hooks/useLastTimeDataAggregated';
import { filterVehicles } from '../../utils';
import { TooltipText } from '../../constants';
import './style.css';

const Metrics = ({ numVehiclesInCommunication, numVehiclesInSystem }) => (
  <div className="vehicles-health-monitoring-metrics">
    <div className="vehicles-health-monitoring-metrics-in-communication">
      <>
        <p className="vehicles-health-monitoring-metrics-num">
          {numVehiclesInCommunication} <span>Vehicles</span>
        </p>
        <p>in communication</p>
      </>
    </div>
    <div className="vehicles-health-monitoring-metrics-in-system">
      <>
        <p className="vehicles-health-monitoring-metrics-num">
          {numVehiclesInSystem} <span>Vehicles</span>
        </p>
        <p>in system</p>
      </>
    </div>
  </div>
);

const Title = ({ timestamp }) => (
  <p>{timestamp ? `Updated ${timestamp}` : 'Timestamp Not Available'}</p>
);

const VehiclesHealthMonitoringPage = () => {
  const [searchKeys, setSearchKeys] = useState([]);

  const {
    numVehiclesInSystem,
    numVehiclesInCommunication,
    vehicleHealthData,
    isLoading: vehicleHealthDataIsLoading,
    error: vehicleHealthDataError,
  } = useVehicles();

  const {
    lastTimeAggregatedTimestamp,
    isLoading: lastTimeAggregatedTimestampIsLoading,
    error: lastTimeDataAggregatedError,
  } = useLastTimeDataAggregated();

  useEffect(() => {
    if (vehicleHealthDataError)
      openNotification({
        message: 'Error Fetching Vehicle Health Data',
        description: vehicleHealthDataError,
      });
  }, [vehicleHealthDataError]);

  useEffect(() => {
    if (lastTimeDataAggregatedError)
      openNotification({
        message: 'Error Fetching Last Time Aggregated Timestamp',
        description: lastTimeDataAggregatedError,
      });
  }, [lastTimeDataAggregatedError]);

  // Set up filtering for vehicles so user can search in tables
  const data = !vehicleHealthDataIsLoading
    ? filterVehicles(searchKeys)(vehicleHealthData)
    : null;

  const isLoading = useMemo(
    () => vehicleHealthDataIsLoading || lastTimeAggregatedTimestampIsLoading,
    [vehicleHealthDataIsLoading, lastTimeAggregatedTimestampIsLoading]
  );

  useEffect(() => {
    setSearchKeys([]);
  }, []);

  return (
    <HealthMonitoringLayout
      title={'Health Monitoring'}
      tooltip={TooltipText.HealthMonitoring}
      headerChildren={
        <Metrics
          numVehiclesInCommunication={numVehiclesInCommunication}
          numVehiclesInSystem={numVehiclesInSystem}
        />
      }
    >
      <div className="vehicles-health-monitoring-content">
        <TableCard
          title={
            isLoading ? null : <Title timestamp={lastTimeAggregatedTimestamp} />
          }
          controls={
            <>
              {isLoading || (
                <>
                  <TableSearch
                    data={vehicleHealthData}
                    handleSearch={setSearchKeys}
                    itemSearchField="name"
                    searchKeys={searchKeys}
                    placeholder="Enter Vehicle"
                  />
                </>
              )}
            </>
          }
          table={<VehiclesTable isLoading={isLoading} data={data} />}
        />
      </div>
    </HealthMonitoringLayout>
  );
};

export default VehiclesHealthMonitoringPage;
