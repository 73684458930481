import { Select as AntSelect } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

const SelectRange = ({
  min,
  max,
  step = 1,
  desc = false,
  showSearch = false,
  formatter = (v) => v,
  ...props
}) => {
  const options = Array.from({ length: (max - min) / step + 1 }, (_, i) => (
    <AntSelect.Option
      key={(i + min) * step}
      value={((i + min) * step).toString()}
    >
      {formatter((i + min) * step)}
    </AntSelect.Option>
  ));
  if (showSearch) {
    return (
      <AntSelect
        showSearch
        suffixIcon={<SearchOutlined style={{ color: '#9e9e9e' }} />}
        allowClear
        {...props}
      >
        {desc ? options.reverse() : options}
      </AntSelect>
    );
  }
  return <AntSelect {...props}>{desc ? options.reverse() : options}</AntSelect>;
};

export default SelectRange;
