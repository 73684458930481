import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Tabs } from 'antd';
import openNotification from '../../../../common/components/notification';
import Button from '../../../../common/components/Button';
import ConfigurationLayout from '../../../../common/layouts/ConfigurationLayout';
import Card from '../../../../common/components/Card';
import TableSearch from '../../../../common/components/TableSearch';
import VehiclesTable from '../../components/VehiclesTable';
import DevicesTable from '../../components/DevicesTable';
import CreateVehicle from '../../components/CreateVehicle';
import CreateDevice from '../../components/CreateDevice';
import EditDeviceModal from '../../components/EditDeviceModal';
import ConfirmModal from '../../components/ConfirmModal';
import VehicleAssignmentModal from '../../components/VehicleAssignmentModal';
import useVehicles from '../../hooks/useVehicles';
import useDevices from '../../hooks/useDevices';
import { filterVehicles, filterUnassignedDevices } from '../../utils';
import EditVehicleModal from '../../components/EditVehicleModal';
import useWhelenImport from '../../hooks/useWhelenImport';
import useAgency from '../../hooks/useAgency';
import useWhelenIntegration from '../../hooks/useWhelenIntegration';
import './style.css';

const { TabPane } = Tabs;

const View = {
  CREATING_DEVICE: 'CREATING_DEVICE',
  EDITING_DEVICE: 'EDITING_DEVICE',
  DELETING_DEVICE: 'DELETING_DEVICE',
  CREATING_VEHICLE: 'CREATING_VEHICLE',
  EDITING_VEHICLE: 'EDITING_VEHICLE',
  DELETING_VEHICLE: 'DELETING_VEHICLE',
  CHANGING_PREEMPTION: 'CHANGING_PREEMPTION',
  DISSOCIATING_DEVICE: 'DISSOCIATING_DEVICE',
  ASSIGNING_DEVICE_TO_VEHICLE: 'ASSIGNING_DEVICE_TO_VEHICLE',
};

const ConfirmDeleteVehicleModal = ({ vehicle, onClose, ...props }) => {
  const { id } = vehicle;
  const { deleteVehicleResponse, deleteVehicle } = useVehicles();

  const { reset } = deleteVehicleResponse;

  const handleClose = useCallback(() => {
    reset();
    onClose();
  }, [reset, onClose]);

  if (deleteVehicleResponse.isSuccess) {
    deleteVehicleResponse.data = ['Vehicle Deleted Successfully'];
  }
  if (deleteVehicleResponse.isError) {
    deleteVehicleResponse.data = ['Error Deleting Vehicle'];
  }

  return (
    <ConfirmModal
      onResponseChange={() => handleClose()}
      onSelectedChange={() => handleClose()}
      selected={[id]}
      response={deleteVehicleResponse}
      delete={() => deleteVehicle([vehicle])}
      {...props}
    />
  );
};

const ConfirmDeleteDeviceModal = ({ device, onClose, ...props }) => {
  const { id } = device;
  const { deleteDeviceResponse, deleteDevice } = useDevices();

  const { reset } = deleteDeviceResponse;

  const handleClose = useCallback(() => {
    reset();
    onClose();
  }, [reset, onClose]);

  if (deleteDeviceResponse.isSuccess) {
    deleteDeviceResponse.data = ['Device Deleted Successfully'];
  }
  if (deleteDeviceResponse.isError) {
    deleteDeviceResponse.data = ['Error Deleting Device'];
  }

  return (
    <ConfirmModal
      onResponseChange={() => handleClose()}
      onSelectedChange={() => handleClose()}
      selected={[id]}
      response={deleteDeviceResponse}
      delete={() => deleteDevice([device])}
      {...props}
    />
  );
};

// Vehicle Configuration L1 page
const VehiclesPage = () => {
  // Current tab state
  const [currentTab, setCurrentTab] = useState('Vehicles');
  const [view, setView] = useState();

  // Search Keys
  const [vehiclesSearchKeys, setVehiclesSearchKeys] = useState([]);
  const [devicesSearchKeys, setDevicesSearchKeys] = useState([]);

  // Auxiliary state methods for editing and deleting devices
  const [vehicle, setVehicle] = useState();
  const [device, setDevice] = useState();

  const { agencyId } = useSelector(({ user }) => user);
  const { agency: agencyData } = useAgency({
    id: agencyId,
  });

  const { isActive: isWhelenActive } = useWhelenIntegration({
    agencyId,
  });
  const { start: startWhelenImport, isRunning: isWhelenImportRunning } =
    useWhelenImport({
      agencyId,
    });

  const {
    vehicles,
    isLoading: isVehiclesLoading,
    isError: vehiclesIsError,
    error: vehiclesError,
  } = useVehicles({ agencyId });

  useEffect(() => {
    if (vehiclesIsError)
      openNotification({
        message: 'Error Getting Vehicles Data',
        description: vehiclesError.message,
      });
  }, [vehiclesError?.message, vehiclesIsError]);

  const {
    unassignedDevices,
    dissociateDevice,
    isLoading: isDevicesLoading,
    isError: isDevicesError,
    error: devicesError,
  } = useDevices({ agencyId });

  useEffect(() => {
    if (isDevicesError)
      openNotification({
        message: 'Error Getting Devices',
        description: devicesError.message,
      });
  }, [devicesError?.message, isDevicesError]);

  // Make sure all data is loaded before displaying any tables
  const isLoading = useMemo(
    () => isVehiclesLoading || isDevicesLoading,
    [isVehiclesLoading, isDevicesLoading]
  );

  // Set up filtering for vehicles and devices so user can search in tables
  const filteredVehicles = !isVehiclesLoading
    ? filterVehicles(vehiclesSearchKeys)(vehicles)
    : null;

  const filteredDevices = !isDevicesLoading
    ? filterUnassignedDevices(devicesSearchKeys)(unassignedDevices)
    : null;

  const updateView = (newView) => {
    setView(newView);
  };
  // Based on which tab is currently open, display the correct modal for creating a vehicle or device
  const handleCreate = useCallback(() => {
    if (currentTab === 'Vehicles') setView(View.CREATING_VEHICLE);
    else setView(View.CREATING_DEVICE);
  }, [currentTab]);

  const handleViewChange = useCallback(
    (callback, v) => {
      setDevice();
      setVehicle();

      callback();
      setView(v);
    },
    [setDevice, setVehicle, setView]
  );

  return (
    <ConfigurationLayout>
      <Card>
        <Tabs
          className="tabs"
          onTabClick={(selectedKey) => setCurrentTab(selectedKey)}
          tabBarExtraContent={
            <div className="tabs-extra-content">
              <div className="tabs-button">
                {isWhelenActive && (
                  <Button
                    type="secondary"
                    onClick={() => startWhelenImport()}
                    disabled={isWhelenImportRunning}
                  >
                    Refresh
                  </Button>
                )}
                <Button
                  type="primary"
                  size=""
                  location="center"
                  onClick={() => handleCreate(true)}
                >
                  + Create New{' '}
                  {currentTab === 'Vehicles' ? 'Vehicle' : 'Device'}
                </Button>
              </div>
              <div className="tabs-tablesearch">
                {currentTab === 'Vehicles' ? (
                  <TableSearch
                    data={vehicles}
                    handleSearch={setVehiclesSearchKeys}
                    itemSearchField="name"
                    searchKeys={vehiclesSearchKeys}
                    placeholder="Enter Vehicle"
                  />
                ) : (
                  <TableSearch
                    data={unassignedDevices}
                    handleSearch={setDevicesSearchKeys}
                    itemSearchField="serial"
                    searchKeys={devicesSearchKeys}
                    placeholder="Enter Device"
                  />
                )}
              </div>
            </div>
          }
        >
          <TabPane tab="Vehicles" key="Vehicles">
            <VehiclesTable
              vehicles={filteredVehicles}
              isLoading={isLoading}
              onDelete={(v) =>
                handleViewChange(() => setVehicle(v), View.DELETING_VEHICLE)
              }
              onEdit={(v) =>
                handleViewChange(() => setVehicle(v), View.EDITING_VEHICLE)
              }
              onChangePreemption={(v) =>
                handleViewChange(() => setVehicle(v), View.CHANGING_PREEMPTION)
              }
              onDissociateDevice={(d) => dissociateDevice(d)}
              onDeleteDevice={(d) =>
                handleViewChange(() => setDevice(d), View.DELETING_DEVICE)
              }
              onEditDevice={(d) =>
                handleViewChange(() => setDevice(d), View.EDITING_DEVICE)
              }
            />
          </TabPane>
          <TabPane tab="Unassigned Devices" key="Unassigned Devices">
            <DevicesTable
              devices={filteredDevices}
              isLoading={isLoading}
              onAssign={(d) =>
                handleViewChange(
                  () => setDevice(d),
                  View.ASSIGNING_DEVICE_TO_VEHICLE
                )
              }
              onDelete={(d) =>
                handleViewChange(() => setDevice(d), View.DELETING_DEVICE)
              }
              onEdit={(d) =>
                handleViewChange(() => setDevice(d), View.EDITING_DEVICE)
              }
            />
          </TabPane>
        </Tabs>
      </Card>

      {/* Vehicles */}
      <CreateVehicle
        onClose={() => {
          if (device && currentTab !== 'Vehicles') {
            setView(View.ASSIGNING_DEVICE_TO_VEHICLE);
          } else {
            setView();
          }
        }}
        visible={view === View.CREATING_VEHICLE}
      />
      <EditVehicleModal
        vehicle={vehicle}
        agency={agencyData}
        visible={view === View.EDITING_VEHICLE}
        onClose={() => setView()}
      />
      <ConfirmDeleteVehicleModal
        vehicle={vehicle || {}}
        visible={view === View.DELETING_VEHICLE}
        onClose={() => setView()}
      />

      {/* Devices */}
      <CreateDevice
        onClose={() => setView()}
        visible={view === View.CREATING_DEVICE}
      />
      <EditDeviceModal
        device={device}
        visible={view === View.EDITING_DEVICE}
        onClose={() => setView()}
      />
      <ConfirmDeleteDeviceModal
        device={device || {}}
        visible={view === View.DELETING_DEVICE}
        onClose={() => setView()}
      />

      <VehicleAssignmentModal
        vehicles={vehicles}
        device={device}
        visible={view === View.ASSIGNING_DEVICE_TO_VEHICLE}
        setView={updateView}
        onClose={() => setView()}
      />
    </ConfigurationLayout>
  );
};

export default VehiclesPage;
