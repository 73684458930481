import { useGetRegionQuery, useEditRegionMutation } from '../api';

// Returns the specified Region's data and exposes methods for editing and creating an Region
const useRegion = ({ id }) => {
  const { data: region, isLoading, isError, error } = useGetRegionQuery({ id });

  const [editRegion, editRegionResponse] = useEditRegionMutation();

  return {
    region,
    isLoading,
    isError,
    error,
    editResponse: editRegionResponse,
    edit: editRegion,
  };
};

export default useRegion;
