import React from 'react';
import { Tag } from 'antd';
import makeModelColorPicker from '../../utils/makeModelColorPicker';

const MakeModel = ({ makeModel, ...props }) => (
  <>
    {makeModel?.length &&
      makeModel.map((entry) => {
        const color = makeModelColorPicker(entry);

        return (
          <Tag color={color} key={entry} {...props}>
            {entry}
          </Tag>
        );
      })}
  </>
);

export default MakeModel;
