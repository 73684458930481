import React, { useCallback, useEffect, useMemo } from 'react';
import { Card } from 'antd';
import Map from '../../../../common/components/Map';
import { LOW_CONFIDENCE_THRESHOLD } from '../../constants';

const MetricsMap = ({
  dateRange,
  totalTrips,
  selectedMarker,
  shapes,
  children,
  onSelectedMarkerChange = () => {},
  onBoundsChange = () => {},
  ...props
}) => {
  const { startDate, endDate } = dateRange;
  const numDays = useMemo(
    () =>
      (new Date(dateRange.endDate) - new Date(dateRange.startDate)) /
      (1000 * 60 * 60 * 24),
    [dateRange]
  );

  const marker = useCallback(
    (item) => {
      const isMissingData = item?.metric?.secs === undefined;
      const title = `${item.name}${isMissingData ? '' : ` (${item.numTrips})`}`;
      const hasPrevData =
        item?.metric?.change !== undefined && item?.metric?.change !== null;

      if (isMissingData) {
        return (
          <Card
            title={title}
            size="small"
            bordered={false}
            style={{ width: 250 }}
          >
            No data is available
          </Card>
        );
      }

      return (
        <Card className="map-card" size="medium" bordered={false}>
          <div style={{ width: '45%', float: 'left' }}>
            <h1>{item.name}</h1>
            {item.numTrips !== null &&
              item.numTrips !== undefined &&
              (item.numTrips < totalTrips * LOW_CONFIDENCE_THRESHOLD ? (
                <p style={{ color: '#ff4d4f' }}>* Only {item.numTrips} trips</p>
              ) : (
                <p>{item.numTrips} trips</p>
              ))}
          </div>
          <div
            style={{
              width: hasPrevData ? '20%' : '45%',
              marginLeft: '4%',
              marginRight: '6%',
              float: 'left',
              textAlign: 'center',
            }}
          >
            <h1>
              {item?.metric?.secs !== null && item?.metric?.secs !== undefined
                ? `${Math.round(item?.metric?.secs)}s`
                : 'N/A'}
            </h1>
            <p>
              {`${startDate.slice(5).replace('-', '/')} - ${endDate
                .slice(5)
                .replace('-', '/')}`}
            </p>
          </div>
          {hasPrevData && (
            <div style={{ width: '25%', float: 'left', textAlign: 'center' }}>
              {item?.metric?.change > 0 ? (
                <h1 style={{ color: '#ff4d4f' }}>
                  {`+${Math.round(item?.metric?.change)}`}s
                </h1>
              ) : (
                <h1 style={{ color: '#52c41a' }}>
                  {`${Math.round(item?.metric?.change)}`}s
                </h1>
              )}

              <p>
                {numDays === 0
                  ? `from previous day`
                  : `from previous ${numDays} days`}
              </p>
            </div>
          )}
        </Card>
      );
    },
    [startDate, endDate, totalTrips, numDays]
  );

  // Remove the open chart on filter change
  useEffect(() => {
    onSelectedMarkerChange(null);
  }, [onSelectedMarkerChange]);

  return (
    <div className="map-container">
      <Map
        selectedMapItemState={[selectedMarker, onSelectedMarkerChange]}
        onViewportBoundsChanged={onBoundsChange}
        tooltips={{ marker }}
        gmapsShapes={shapes}
        {...props}
      >
        {children}
      </Map>
    </div>
  );
};

export default MetricsMap;
