import React, { useState, useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Draggable from 'react-draggable';
import { Button, Row, Col, Select, Switch } from 'antd';
import {
  DeleteOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';
import ApproachesHelpModal from '../ApproachesHelpModal';
import {
  setAssignedApproaches,
  setIntersectionSaved,
  setEditableInput,
  updateApproachMapEditor,
  updateSelectedSegment,
  updateIntersectionApproachMapSegment,
} from '../../store/slice';
import ApproachesKeystrokesModal from '../ApproachesKeystrokesModal';
import { ApproachChannels } from '../../enums';
import { APPROACH_COLORS } from '../../constants';
import Back from '../../../../common/icons/Back';
import openNotification from '../../../../common/components/notification';

import './style.css';
import { UserRole } from '../../../../common/enums';
import useUser from '../../../userAuth/hooks/useUser';

const spanStyleMouseOver = {
  cursor: 'pointer',
  color: '#1890ff',
  textDecoration: 'underline',
};

const spanStyleMouseOff = {
  cursor: 'pointer',
  color: 'rgba(0, 0, 0, 0.85)',
  textDecoration: 'none',
};

const convertToFeet = (data) => parseFloat(data * 3.28084).toFixed(2);
const convertToMeter = (data) => parseFloat(data * 0.3048).toFixed(2);

const ApproachesModalTableItems = ({
  approach,
  selectedApproachChannel,
  selectedSegment,
  onDelete,
  isMeter,
}) => {
  const [deleteSpanStyle, setDeleteSpanStyle] = useState(spanStyleMouseOff);
  const [cancelSpanStyle, setCancelSpanStyle] = useState(spanStyleMouseOff);
  const [saveSpanStyle, setSaveSpanStyle] = useState(spanStyleMouseOff);
  const [isEditingWidth, setIsEditingWidth] = useState(false);
  const [newSegmentWidth, setNewSegmentWidth] = useState(null);

  const { approachSegments } = approach || [];

  const dispatch = useDispatch();

  useEffect(() => {
    if (!selectedSegment) return;

    const { segmentWidth } = selectedSegment;
    setNewSegmentWidth(isMeter ? segmentWidth : convertToFeet(segmentWidth));
  }, [selectedSegment, isMeter]);

  useEffect(() => {
    dispatch(setEditableInput(isEditingWidth));
  }, [dispatch, isEditingWidth]);

  /** @TODO Implement these functions upon approval from Peter & Teigen */
  // const handleRowClick = useCallback(
  //   (index) => {
  //     const { approachSegments } = approach;
  //     const { segmentLength, segmentWidth } = approachSegments[index];

  //     dispatch(
  //       updateSelectedSegment({
  //         index,
  //         segmentLength,
  //         segmentWidth,
  //       })
  //     );
  //   },
  //   [approach, dispatch]
  // );

  // const handleMouseOverRow = () => {};

  const onSave = useCallback(() => {
    const { index } = selectedSegment;
    const parsedWidth = isMeter
      ? parseInt(newSegmentWidth, 10)
      : parseInt(convertToMeter(newSegmentWidth), 10);

    if (parsedWidth < 30 || parsedWidth > 285) {
      const errorMessage =
        'Intersections Approach segment width may have a minimum of 30m(98.43ft) and maximum of 285m(935ft).';
      openNotification({
        message: 'Approach Map Error',
        description: errorMessage,
      });
      return;
    }

    const { approachNum } = approach;
    dispatch(
      updateSelectedSegment({
        segmentWidth: parsedWidth,
      })
    );
    dispatch(
      updateIntersectionApproachMapSegment({
        approachNum,
        index,
        segment: { width: parsedWidth },
      })
    );
    setIsEditingWidth(false);
  }, [approach, dispatch, isMeter, newSegmentWidth, selectedSegment]);

  return (
    <>
      {approachSegments?.map((segment, i) => {
        const isSelectedSegment = selectedSegment?.index === i;
        const { segmentLength, segmentWidth } = isSelectedSegment
          ? selectedSegment
          : segment;

        const color = isSelectedSegment
          ? APPROACH_COLORS[selectedApproachChannel]
          : null;
        return (
          <Row
            key={i}
            style={{
              backgroundColor: color,
            }}
            // onMouseOver={
            //   isSelectedSegment ? null : () => handleMouseOverRow(i)
            // }
            // onClick={isSelectedSegment ? null : () => handleRowClick(i)}
            // onMouseDown={!isEditWidth? null : ()=> handleClickOutside(i)}
          >
            <Col span={6} align="center">
              {i + 1}
            </Col>
            <Col
              span={6}
              align="center"
              className={isSelectedSegment ? 'hover-width-bold' : ''}
              onDoubleClick={
                !isSelectedSegment ? null : () => setIsEditingWidth(true)
              }
            >
              {isEditingWidth && isSelectedSegment ? (
                <input
                  type="text"
                  value={newSegmentWidth}
                  onChange={(event) => setNewSegmentWidth(event.target.value)}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') onSave(); // Call the onSave function when Enter is pressed
                  }}
                  className="editable-input"
                />
              ) : (
                <span>
                  {isMeter ? segmentWidth : convertToFeet(segmentWidth)}
                </span>
              )}
              {isMeter ? 'm' : 'ft'}
            </Col>
            <Col span={6} align="center">
              {isMeter ? segmentLength : convertToFeet(segmentLength)}
              {isMeter ? 'm' : 'ft'}
            </Col>
            <Col span={isEditingWidth ? '2' : 6} align="center">
              {isSelectedSegment && (
                <DeleteOutlined
                  onMouseOver={() => setDeleteSpanStyle(spanStyleMouseOver)}
                  onMouseOut={() => setDeleteSpanStyle(spanStyleMouseOff)}
                  onClick={onDelete}
                  style={deleteSpanStyle}
                />
              )}
            </Col>
            <Col span={2} align="center">
              {isSelectedSegment && isEditingWidth && (
                <CloseCircleOutlined
                  style={cancelSpanStyle}
                  onMouseOver={() => setCancelSpanStyle(spanStyleMouseOver)}
                  onMouseOut={() => setCancelSpanStyle(spanStyleMouseOff)}
                  onClick={() => setIsEditingWidth(false)}
                />
              )}
            </Col>
            <Col span={2} align="center">
              {isSelectedSegment && isEditingWidth && (
                <CheckCircleOutlined
                  style={saveSpanStyle}
                  onMouseOver={() => setSaveSpanStyle(spanStyleMouseOver)}
                  onMouseOut={() => setSaveSpanStyle(spanStyleMouseOff)}
                  onClick={onSave}
                />
              )}
            </Col>
          </Row>
        );
      })}
    </>
  );
};

const ApproachesModal = ({
  onClearSelectedSegment,
  onDelete,
  onCreate,
  generalData,
  drawingManager,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [helpSpanStyle, setHelpSpanStyle] = useState(spanStyleMouseOff);
  const [keystrokeSpanStyle, setKeystrokeSpanStyle] =
    useState(spanStyleMouseOff);
  const [closeSpanStyle, setCloseSpanStyle] = useState(spanStyleMouseOff);
  const [isHelpModalVisible, setIsHelpModalVisible] = useState(false);
  const [isKeystrokesModalVisible, setIsKeystrokesModalVisible] =
    useState(false);
  const [selectedApproachChannel, setSelectedApproachChannel] = useState(
    ApproachChannels.A
  );

  const { userHasRole } = useUser();

  const { approach, selectedSegment, isCreating } = useSelector(
    ({ configuration: { approachMapEditor } }) => approachMapEditor
  );
  const { intersectionSaved } = useSelector((state) => state.configuration);
  const [isMeter, setIsMeter] = useState(true);
  const dispatch = useDispatch();

  const onHelpClose = useCallback(() => {
    setIsHelpModalVisible(false);
  }, []);

  const handleHelpClick = () => {
    setIsHelpModalVisible(true);
  };

  const onKeystrokesClose = useCallback(() => {
    setIsKeystrokesModalVisible(false);
  }, []);

  const handleKeystrokesClick = () => {
    setIsKeystrokesModalVisible(true);
  };

  const updateApproachChannel = useCallback(
    (value) => {
      const { approachNum } = approach;
      dispatch(
        setAssignedApproaches({
          channel: value,
          approachNum,
        })
      );
      setSelectedApproachChannel(value);
      dispatch(
        updateApproachMapEditor({
          approach: { approachChannel: value },
        })
      );

      if (intersectionSaved) dispatch(setIntersectionSaved(false));
    },
    [approach, dispatch, intersectionSaved]
  );

  useEffect(() => {
    setSelectedApproachChannel(approach?.approachChannel);
  }, [approach?.approachChannel]);

  useEffect(() => {
    if (selectedSegment) setIsCollapsed(false);
    if (!selectedSegment) setIsCollapsed(true);
  }, [selectedSegment]);

  useEffect(() => {
    if (!drawingManager) return;

    if (isCreating) {
      // eslint-disable-next-line no-undef
      drawingManager.setDrawingMode(google.maps.drawing.OverlayType.POLYLINE);
    } else drawingManager.setDrawingMode(null);
  }, [isCreating, drawingManager]);

  const isEditor = userHasRole([UserRole.ORG_ADMIN, UserRole.READ_WRITE]);

  return (
    <>
      <Draggable>
        <div className="approaches-modal">
          <div className="approaches-modal-top">
            <Row>
              <p className="approaches-modal-title">
                {!isEditor && (
                  <Link to="/config/intersections" className="backRight">
                    <Back />
                  </Link>
                )}
                {generalData.intersectionName}
              </p>
            </Row>
            <Row>
              <p className="approaches-modal-lat-lng">
                {generalData.latitude}, &nbsp; {generalData.longitude}
              </p>
            </Row>
            <Row justify="space-between">
              <Switch
                key="4"
                style={{ width: '70px' }}
                checkedChildren="Meter"
                unCheckedChildren="Feet"
                checked={isMeter}
                onChange={() => setIsMeter(!isMeter)}
                hidden={!isEditor}
              />
              <Button
                type="secondary"
                size="small"
                onClick={() => onCreate(!isCreating)}
                hidden={!isEditor}
              >
                {isCreating ? 'Cancel Draw' : 'Draw Approach'}
              </Button>
            </Row>
          </div>
          {!isCollapsed && (
            <>
              <div className="approaches-modal-bottom">
                <Row>
                  <Col span={18}>
                    <div style={{ marginLeft: '5px' }}>
                      <h2
                        style={{
                          marginTop: '5px',
                          marginBottom: '5px',
                        }}
                      >
                        Selected Approach
                      </h2>
                      <p style={{ margin: '0px' }}>
                        {isMeter
                          ? `${approach?.approachLength}m`
                          : `${convertToFeet(approach?.approachLength)}ft`}{' '}
                        total length
                      </p>
                    </div>
                  </Col>
                  <Col span={6}>
                    <h3 style={{ marginTop: '8px', marginBottom: '0px' }}>
                      Channel
                    </h3>
                    <Select
                      value={selectedApproachChannel}
                      onSelect={updateApproachChannel}
                    >
                      <Select.Option value={ApproachChannels.A}>
                        A
                      </Select.Option>
                      <Select.Option value={ApproachChannels.B}>
                        B
                      </Select.Option>
                      <Select.Option value={ApproachChannels.C}>
                        C
                      </Select.Option>
                      <Select.Option value={ApproachChannels.D}>
                        D
                      </Select.Option>
                    </Select>
                  </Col>
                </Row>
                <hr color="#8c8c8c" />
                <Row>
                  <Col span={6} align="center">
                    Segment
                  </Col>
                  <Col span={6} align="center">
                    Width
                  </Col>
                  <Col span={6} align="center">
                    Length
                  </Col>
                </Row>
                <ApproachesModalTableItems
                  approach={approach}
                  selectedApproachChannel={selectedApproachChannel}
                  selectedSegment={selectedSegment}
                  onDelete={onDelete}
                  isMeter={isMeter}
                />
              </div>
              <div className="approaches-modal-tools">
                <p
                  className="approaches-modal-tools-text"
                  onMouseOver={() => setHelpSpanStyle(spanStyleMouseOver)}
                  onMouseOut={() => setHelpSpanStyle(spanStyleMouseOff)}
                  onClick={handleHelpClick}
                  style={helpSpanStyle}
                >
                  Control Help
                </p>
                <p
                  className="approaches-modal-tools-text"
                  onMouseOver={() => setKeystrokeSpanStyle(spanStyleMouseOver)}
                  onMouseOut={() => setKeystrokeSpanStyle(spanStyleMouseOff)}
                  onClick={handleKeystrokesClick}
                  style={keystrokeSpanStyle}
                >
                  Additional Keystrokes
                </p>
                <p
                  className="approaches-modal-tools-text"
                  onMouseOver={() => setCloseSpanStyle(spanStyleMouseOver)}
                  onMouseOut={() => setCloseSpanStyle(spanStyleMouseOff)}
                  onClick={() => onClearSelectedSegment()}
                  style={closeSpanStyle}
                >
                  Close
                </p>
              </div>
            </>
          )}
        </div>
      </Draggable>
      <ApproachesHelpModal
        isVisible={isHelpModalVisible}
        onClose={onHelpClose}
      />
      <ApproachesKeystrokesModal
        isVisible={isKeystrokesModalVisible}
        onClose={onKeystrokesClose}
      />
    </>
  );
};

export default ApproachesModal;
