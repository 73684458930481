export const Direction = {
  Northbound: 'Northbound',
  Eastbound: 'Eastbound',
  Southbound: 'Southbound',
  Westbound: 'Westbound',
};

export const ApproachChannels = {
  A: 'Channel A',
  B: 'Channel B',
  C: 'Channel C',
  D: 'Channel D',
};

export const LocalStorageKeys = {
  Agency: 'Agency',
};

export const PreemptionValues = {
  Active: 'active',
  Inactive: 'inactive',
  Pending: 'pending',
};

export const RequesterModel = {
  M764: 'M764',
  G764: 'G764',
  C764: 'C764',
  V764: 'V764',
};

export const RequesterCommunicationType = {
  DISCRETE: 'discrete',
  DISCRETE_NTCIP: 'discreteNtcip',
  DISCRETE_CENTRALIZED: 'discreteCentralized',
  CENTRALIZED: 'centralized',
  NTCIP: 'ntcip',
  OFF: 'off',
};
