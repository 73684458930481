import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'antd';
import openNotification from '../../../../common/components/notification';
import { trim } from '../../../../common/utils/string';

const ConfirmModal = ({
  visible,
  onResponseChange,
  onSelectedChange,
  selected,
  response,
  delete: onDelete,
  enableDelete = true,
  warningMessage = null,
  emptyErrorMessage = 'You must select an agency to delete.',
}) => {
  const [showButtonModal, setShowButtonModal] = useState(false);
  const [showResponseModal, setShowResponseModal] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const { data, isLoading, isSuccess, isError, error } = response;

  useEffect(() => {
    if (visible && selected.length > 0 && !deleting) setShowButtonModal(true);
    if (visible && selected.length === 0) {
      onResponseChange();
      openNotification({
        message: 'Error',
        description: emptyErrorMessage,
      });
    }
  }, [visible, selected.length, deleting, onResponseChange, emptyErrorMessage]);

  useEffect(() => {
    if (isSuccess) {
      setShowButtonModal(false);
      setShowResponseModal(true);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      setDeleting(false);
      onResponseChange();
      openNotification({
        message: 'Deletion Error',
        description: error?.errorMessage,
      });
    }
  }, [isError, error?.errorMessage, onResponseChange]);

  const handleButtonClick = async (event) => {
    setDeleting(true);
    event.preventDefault();
    onDelete(selected);
  };

  const handleClose = () => {
    onResponseChange();
    setShowButtonModal(false);
  };

  const handleResponseClose = () => {
    setDeleting(false);
    setShowResponseModal(false);
    onResponseChange();
    onSelectedChange();
  };

  return (
    <div className="Modals">
      {visible && (
        <div>
          <Modal
            visible={showButtonModal}
            confirmLoading={isLoading}
            onOk={handleButtonClick}
            okButtonProps={{
              disabled: !enableDelete,
            }}
            onCancel={handleClose}
            okText="Submit"
            cancelText="Cancel"
            title="Delete"
          >
            {!warningMessage && (
              <p>
                Are you sure you want to delete{' '}
                {Array.isArray(selected) ? selected.join(', ') : selected}?
              </p>
            )}
            {warningMessage && warningMessage}
          </Modal>
          <Modal
            onCancel={handleResponseClose}
            visible={showResponseModal}
            title="Success"
            footer={[
              <Button key="ok" type="primary" onClick={handleResponseClose}>
                Ok
              </Button>,
            ]}
          >
            {(Array.isArray(data) ? data : [data]).map((e, i) => (
              <p key={i}>{trim(JSON.stringify(e) || '', '"')}</p>
            ))}
          </Modal>
        </div>
      )}
    </div>
  );
};

export default ConfirmModal;
