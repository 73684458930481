import { useMemo } from 'react';
import MetricsMap from '..';
import {
  createMetricTooltip,
  getPaths,
} from '../../../pages/routeDetails/shapes';
import { createFlags } from '../../../../../common/utils/shapes';
import { metricColorMap } from '../../../../../common/constants';
import { Metric } from '../../../../../common/enums';

export const createTravelTimeMarkers = ({ travelTime, stops }) => {
  const middlePoint = stops?.length
    ? stops[Math.round(stops.length / 2)]
    : null;

  if (!middlePoint) return [];

  return [
    createMetricTooltip({
      position: middlePoint,
      minutes: travelTime,
      placement: 'top',
      key: 'travel-time',
      maxSeconds: 59,
      onClick: () => {},
    }),
  ];
};

const TravelTimeMetricsMap = ({ travelTime, segments, stops, ...props }) => {
  const flags = useMemo(() => createFlags(stops), [stops]);
  const paths = useMemo(
    () => getPaths(segments, () => metricColorMap[Metric.TravelTime]),
    [segments]
  );
  const markers = useMemo(
    () => createTravelTimeMarkers({ travelTime, stops }),
    [stops, travelTime]
  );

  const shapes = useMemo(
    () => [...flags, ...paths, ...markers],
    [flags, paths, markers]
  );

  return <MetricsMap shapes={shapes} {...props} />;
};

export default TravelTimeMetricsMap;
