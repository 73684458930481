import { useMemo, useState } from 'react';
import MetricsMap from '..';
import { SignalDelayMapLegend } from '../../MapLegend';
import {
  MAX_NUM_TOOLTIPS,
  createIntersectionIcon,
  createMetricTooltip,
  getPaths,
  visibleMetricComparator,
} from '../../../pages/routeDetails/shapes';
import { createFlags } from '../../../../../common/utils/shapes';
import { metricColorMap } from '../../../../../common/constants';
import { Metric } from '../../../../../common/enums';
import { LOW_CONFIDENCE_THRESHOLD } from '../../../constants';
import { take } from '../../../../../common/utils/array';

const createIntersections = (
  intersections = [],
  totalTrips = 0,
  onClick = () => {}
) =>
  intersections.map((intersection, i) =>
    createIntersectionIcon({
      isLowConfidence:
        intersection.numTrips < totalTrips * LOW_CONFIDENCE_THRESHOLD,
      marker: intersection,
      key: `intersection-${i}-${intersection.lat}-${intersection.lon}`,
      onClick,
    })
  );

const createMarkers = (intersections = [], bounds, onClick = () => {}) => {
  const sortedIntersections = (intersections || []).sort(
    visibleMetricComparator(bounds)
  );
  const intersectionFlagIds = new Set();

  return take(sortedIntersections, MAX_NUM_TOOLTIPS)
    .map((intersection, i) => {
      const key = `intersection-tooltip-${intersection.lat}-${intersection.lon}`;

      if (intersectionFlagIds.has(key)) {
        return null;
      }
      intersectionFlagIds.add(key);

      return createMetricTooltip({
        position: intersection,
        onClick,
        minutes: intersection?.metric?.mins,
        placement: 'top',
        key: `intersection-tooltip-${i}-${intersection.lat}-${intersection.lon}`,
      });
    })
    .filter((x) => !!x);
};

const SignalDelayMetricsMap = ({
  stops,
  intersections,
  segments,
  totalTrips,
  onSelectedMarkerChange,
  ...props
}) => {
  const [bounds, setBounds] = useState();

  const flags = useMemo(() => createFlags(stops), [stops]);
  const icons = useMemo(
    () =>
      createIntersections(intersections, totalTrips, onSelectedMarkerChange),
    [onSelectedMarkerChange, intersections, totalTrips]
  );
  const markers = useMemo(
    () => createMarkers(intersections, bounds, onSelectedMarkerChange),
    [intersections, bounds, onSelectedMarkerChange]
  );
  const paths = useMemo(
    () => getPaths(segments, () => metricColorMap[Metric.SignalDelay]),
    [segments]
  );

  const shapes = useMemo(
    () => [...flags, ...paths, ...icons, ...markers],
    [flags, paths, icons, markers]
  );

  return (
    <MetricsMap
      onBoundsChange={setBounds}
      shapes={shapes}
      onSelectedMarkerChange={onSelectedMarkerChange}
      {...props}
    >
      <SignalDelayMapLegend />
    </MetricsMap>
  );
};

export default SignalDelayMetricsMap;
