import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery, mockQuery } from '../../../redux/utils/client';
import { getVehiclesData } from '../data';
import {
  INTERSECTION_HEALTH_MONITORING_URL,
  VHM_URL,
} from '../../../common/constants';

const api = createApi({
  reducerPath: 'api/healthMonitoring',
  baseQuery,
  // Used to invalidate intersections data
  tagTypes: ['INTERSECTIONS', 'VEHICLES', 'RTRADIOMSGS'],
  endpoints: (builder) => ({
    getIntersectionsList: builder.query({
      query: (data) => ({
        url: `${INTERSECTION_HEALTH_MONITORING_URL}/list`,
        title: 'List intersections with health monitoring data',
        method: 'GET',
      }),
    }),

    getVehicles: builder.query({
      queryFn: () =>
        mockQuery({
          title: 'Vehicles Data',
          query: getVehiclesData,
        }),
    }),

    // GET RTRadio messages
    getRTRadioMessages: builder.query({
      query: () => ({
        url: `${VHM_URL}/rt-radio-messages`,
        title: 'RTRadio Messages',
        method: 'GET',
      }),
      transformResponse: (data) =>
        Object.entries(data?.vehiclevid).reduce(
          (acc, [key, value]) => ({
            ...acc,
            [value]: {
              vid: value,
              vehicleName: data?.vehiclename[key],
              vehicleClass: data?.vehicleclass[key],
              deviceName: data?.devicename[key],
              deviceModel: data?.source_device[key].split('-')[0],
              iotTimestamp: data?.iot_timestamp[key],
            },
          }),
          {}
        ),
      providesTags: ['RTRADIOMSGS'],
    }),

    // GET timestamp for last time RTRadio messages were processed
    getLastTimeAggregatedTimestamp: builder.query({
      query: () => ({
        url: `${VHM_URL}/last-time-data-aggregated`,
        title: 'Last Aggregated Timestamp',
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetIntersectionsListQuery,
  useGetVehiclesQuery,
  useGetRTRadioMessagesQuery,
  useGetLastTimeAggregatedTimestampQuery,
} = api;

export const { resetApiState } = api.util;

export default api;
