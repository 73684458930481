import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  useGetIntersectionsQuery,
  useGetSignalDelayByIntersectionQuery,
} from '../api';
import { selectTimeperiod } from '../../featurePersistence/store/selectors';
import { LOW_CONFIDENCE_THRESHOLD } from '../constants';

const joinMetricsIntersections = ({ locations = [], metrics = [] }) =>
  locations.map(
    ({
      location_id: locationId,
      location_name: locationName,
      latitude: lat,
      longitude: lon,
    }) => {
      const {
        signaldelay,
        num_trips: numTrips,
        locationname: signalDelayLocationName,
      } = metrics[locationId] || {};

      return {
        metric: {
          ...signaldelay,
          secs: signaldelay?.value,
          mins: signaldelay?.value / 60,
        },
        locationId,
        locationName: signalDelayLocationName || locationName,
        name: locationName,
        lat,
        lon,
        numTrips,
      };
    }
  );

const useIntersections = ({ routeName, totalTrips }) => {
  const { dateRange, direction, periods } = useSelector(
    ({ routeFilters }) => routeFilters
  );
  const timeperiod = useSelector(selectTimeperiod);
  const { data: signalDelayByLocation, isFetching: isSignalDelayLoading } =
    useGetSignalDelayByIntersectionQuery({
      routeName,
      dateRange,
      direction,
      periods,
      timeperiod,
    });

  const { data: intersectionLocations, isFetching: isLocationsLoading } =
    useGetIntersectionsQuery({
      routeName,
      direction,
      dateRange,
    });

  // Add signal delay to locations
  const intersections = useMemo(() => {
    if (!intersectionLocations?.length) return [];
    return joinMetricsIntersections({
      locations: intersectionLocations,
      metrics: signalDelayByLocation,
    });
  }, [signalDelayByLocation, intersectionLocations]);

  const isLoading = useMemo(
    () => isSignalDelayLoading || isLocationsLoading,
    [isSignalDelayLoading, isLocationsLoading]
  );

  const lowConfidenceIntersections = intersections.filter(
    (intersection) =>
      intersection.numTrips < LOW_CONFIDENCE_THRESHOLD * totalTrips
  );

  return {
    intersections,
    lowConfidenceIntersections,
    isMetricsEmpty: JSON.stringify(signalDelayByLocation) === '{}',
    isLoading,
  };
};

export default useIntersections;
