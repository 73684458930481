// APIs

import { IS_MIO_APP } from './environment';

export const GMAPS_KEY = process.env.REACT_APP_GMAPS_KEY;

export const MIO_API_PATH = process.env.REACT_APP_MIO_API_PATH;

export const API_URL = IS_MIO_APP
  ? `${process.env.REACT_APP_MIO_API_BASE}/${MIO_API_PATH}`
  : process.env.REACT_APP_API_URL;

export const VPS_URL = `${API_URL}/vps`;
export const CMS_URL = IS_MIO_APP
  ? `${API_URL}/vps/cms/devices/deviceConfig`
  : `${API_URL}/vpsautomations/cms/devices/deviceConfig`;

export const ASSET_LIBRARY_API_URL = `${API_URL}/assets`;
export const LATENESS_URL = `${API_URL}/analytics`;
export const FEATURE_PERSISTENCE_URL = `${API_URL}/features`;
export const USER_ACTIVITY_LOGS_URL = `${API_URL}/useractivity`;
export const INTERSECTIONS_URL = `${API_URL}/configure/intersections`;
export const WHELEN_URL = `${API_URL}/integrations/whelen`;
const VEHICLE_HEALTH_MONITORING_URL = `${API_URL}/monitor/vehicles`;
export const VHM_URL = VEHICLE_HEALTH_MONITORING_URL;
export const INTERSECTION_HEALTH_MONITORING_URL = `${API_URL}/monitor/intersections`;
