import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import HeadingSection from '../../components/HeadingSection';
import './style.css';

const ConfigurationLayout = ({
  children,
  match,
  backToLastPath = false,
  ...props
}) => {
  const { vehname, devname, intersectionName } = match.params;

  return (
    <main className="configuration-layout">
      <HeadingSection
        backToLastPath={backToLastPath}
        {...props}
      />
      {children}
    </main>
  );
};

export default memo(withRouter(ConfigurationLayout));
