import React, { useEffect, useState } from 'react';
import { Segmented } from 'antd';
import { UnorderedListOutlined } from '@ant-design/icons';
import moment from 'moment-timezone';
import StatusCard from '../../components/StatusCard';
import IntersectionsMap from '../../components/IntersectionsMap';
import IntersectionsList from '../../components/IntersectionsList';
import MapIcon from '../../../../common/icons/Map';
import 'antd/dist/antd.css';
import './style.css';
import TabView from '../../../../common/components/TabView';
import { useGetIntersectionsListQuery } from '../../api';
import TableSearch from '../../../../common/components/TableSearch';
import Skeleton from '../../../../common/components/Skeleton';
import HealthMonitoringLayout from '../../../../common/layouts/HealthMonitoringLayout';
import { TooltipText, timezoneMap } from '../../constants';
import useAgency from '../../../configuration/hooks/useAgency';
import { timestampConversion } from '../../../../common/utils/timestampConversion';

const View = {
  Map: 'Map',
  List: 'List',
};

const IntersectionsHealthMonitoringPage = () => {
  const [selectedMapIntersections, setSelectedMapIntersections] = useState([]);
  const [view, setView] = useState(View.Map);
  const [searchKeys, setSearchKeys] = useState([]);
  const { data: intersectionsList, isLoading: isIntersectionsMapLoading } =
    useGetIntersectionsListQuery();
  const [healthStatusValues, setHealthStatusValues] = useState({
    normal: 0,
    warning: 0,
    error: 0,
  });
  const [dataLastUpdated, setDataLastUpdated] = useState();
  const [tableDataFilter, setTableDataFilter] = useState(intersectionsList);
  const { agency: agencyInfo, isLoading: isAgencyInfoLoading } = useAgency();
  const [timezoneUpdatedIntersectionList, setTimezoneUpdatedIntersectionList] =
    useState();

  useEffect(() => {
    if (
      !isIntersectionsMapLoading &&
      intersectionsList &&
      !isAgencyInfoLoading
    ) {
      setHealthStatusValues({
        normal: intersectionsList.filter(
          (intersection) => intersection.status === 'NORMAL'
        ).length,
        warning: intersectionsList.filter(
          (intersection) => intersection.status === 'WARNING'
        ).length,
        error: intersectionsList.filter(
          (intersection) => intersection.status === 'ERROR'
        ).length,
      });
      const lastUpdated = moment(
        [...intersectionsList].sort((a, b) =>
          a.lastHeartbeatHeard > b.lastHeartbeatHeard ? -1 : 1
        )[0].lastHeartbeatHeard
      ).format('MM/DD/YYYY hh:mm:ss A');

      setDataLastUpdated(
        moment
          .utc(lastUpdated, 'MM/DD/YYYY hh:mm:ss A')
          .tz(timezoneMap[agencyInfo.timezone])
          .format('MM/DD/YYYY hh:mm:ss A')
      );
    }
  }, [
    intersectionsList,
    isIntersectionsMapLoading,
    isAgencyInfoLoading,
    agencyInfo,
  ]);

  useEffect(() => {
    setSearchKeys([]);
  }, []);

  useEffect(() => {
    setTableDataFilter(() => {
      if (searchKeys.length > 0) {
        return intersectionsList.filter((index) =>
          searchKeys.some((key) =>
            index.intersectionName.toLowerCase().includes(key.toLowerCase())
          )
        );
      }
      return intersectionsList;
    });
  }, [searchKeys, intersectionsList]);

  useEffect(() => {
    if (intersectionsList && agencyInfo) {
      setTimezoneUpdatedIntersectionList(
        intersectionsList.map((intersection) => ({
          ...intersection,
          lastHeartbeatHeard: intersection.lastHeartbeatHeard
            ? timestampConversion(
                intersection.lastHeartbeatHeard,
                timezoneMap[agencyInfo.timezone]
              )
            : '',
          lastVehicleHeard: intersection.lastVehicleHeard
            ? timestampConversion(
                intersection.lastVehicleHeard,
                timezoneMap[agencyInfo.timezone]
              )
            : '',
        }))
      );
    }
  }, [intersectionsList, dataLastUpdated, agencyInfo]);

  return (
    <main className="IntersectionPage">
      <HealthMonitoringLayout
        title="Health Monitoring"
        tooltip={TooltipText.IntersectionHealthMonitoring}
        headerChildren={
          !isIntersectionsMapLoading ? (
            <StatusCard
              normal={healthStatusValues.normal}
              warning={healthStatusValues.warning}
              error={healthStatusValues.error}
            />
          ) : (
            <Skeleton active={isIntersectionsMapLoading} />
          )
        }
      >
        <TabView
          className="map-list"
          value={view}
          onChange={(value) => setView(value)}
          views={
            <>
              <MapIcon value={View.Map} />
              <UnorderedListOutlined value={View.List} />
            </>
          }
          viewTabs={true}
        >
          <div className="map-list__body">
            <div className="tab__header">
              <div className="updated-time">
                {!isIntersectionsMapLoading ? (
                  <h4>Updated at {dataLastUpdated}</h4>
                ) : (
                  <Skeleton active={isIntersectionsMapLoading} />
                )}
              </div>
              {view === View.List && (
                <div className="tab__header__search">
                  <TableSearch
                    data={intersectionsList}
                    handleSearch={setSearchKeys}
                    itemSearchField="intersectionName"
                    searchKeys={searchKeys}
                    placeholder="Enter intersection name"
                  />
                </div>
              )}
              {
                <Segmented
                  options={[
                    {
                      value: View.Map,
                      label: 'Map',
                      icon: <MapIcon value={View.Map} />,
                    },
                    {
                      value: View.List,
                      label: 'List',
                      icon: <UnorderedListOutlined value={View.List} />,
                    },
                  ]}
                  onChange={(value) => setView(value)}
                />
              }
            </div>
            {isIntersectionsMapLoading && (
              <Skeleton
                className="skeleton-map"
                active={isIntersectionsMapLoading}
              />
            )}
            {view === View.Map && !isIntersectionsMapLoading && (
              <>
                <div className="map__settings">
                  <IntersectionsMap
                    className="map-list__settings"
                    isMarkerShown
                    intersections={intersectionsList}
                    selectedIntersections={selectedMapIntersections}
                  />
                </div>
              </>
            )}
            {view === View.List &&
              !isIntersectionsMapLoading &&
              timezoneUpdatedIntersectionList && (
                <>
                  <IntersectionsList
                    intersections={timezoneUpdatedIntersectionList}
                    isLoading={isIntersectionsMapLoading}
                  />
                </>
              )}
          </div>
          <h4 className="tab__footer">
            Note: All timestamps in {agencyInfo?.timezone} timezone.
          </h4>
        </TabView>
      </HealthMonitoringLayout>
    </main>
  );
};

export default IntersectionsHealthMonitoringPage;
