import React, { useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import TableCard from '../../../../common/components/TableCard';
import CommonChart, {
  ContentType,
  GraphType,
} from '../../../../common/components/CommonChart';
import {
  COLOR_BASE,
  metricColorMap,
  metricsNames,
  TODAY,
} from '../../../../common/constants';
import useMetricChart, { EquipmentState } from '../../hooks/useMetricChart';
import './style.css';
import { lineChartPath } from '../../../../common/components/CommonChart/shapes';
import { SourceDevice } from '../../../../common/enums';
import { humanizeDecimalMinutes } from '../../../../common/utils';
import { pluralize } from '../../../../common/utils/string';

const getColorForMetricAndEquipmentState = (metric, equipmentState) =>
  equipmentState === EquipmentState.EQUIPPED
    ? metricColorMap[metric]
    : COLOR_BASE;

/**
 * Store two whole numbers in a single float
 * @param {number} value1 Number stored in whole places
 * @param {number} value2 Number stored in decimal places
 * @param {number} decimalPlaces Number of decimal places needed to store value2
 * @returns {number} Float
 */
const combineValuesToFloat = (value1, value2, decimalPlaces = 5) =>
  parseInt(value1, 10) + value2 / 10 ** decimalPlaces;

/**
 * Retrieve two whole numbers from a single float
 * @param {number} value Float to retrieve values from
 * @param {number} decimalPlaces Number of decimal places used to store value2
 * @returns {number[]} [value1, value2]
 */
const getValuesFromFloat = (value, decimalPlaces = 5) => {
  const value1 = parseInt(value, 10);
  const value2 = Math.round((value - value1) * 10 ** decimalPlaces, 10);
  return [value1, value2];
};

const createChartConfig =
  ({ metric }) =>
  ({ data }) => {
    const seriesNames = [...new Set(data.map(({ series }) => series))].filter(
      (x) => !!x
    );
    const addSeriesLabel = !(
      seriesNames?.length === 1 &&
      seriesNames[0] !== SourceDevice['RT_GTFS-INTERPOLATED']
    );

    return {
      data: data.map(({ value, series, period, numTrips }) => ({
        value: combineValuesToFloat(value * 60, numTrips),
        series,
        period,
      })),
      xField: 'period',
      yField: 'value',
      color: ({ series }) => getColorForMetricAndEquipmentState(metric, series),
      annotationStroke: '#333333',

      tooltip: {
        showTitle: false,
        formatter: (ref) => {
          const [seconds, numTrips] = getValuesFromFloat(ref.value);

          const name = `${ref.period} (${ref.series})`;
          const rawValue = seconds / 60;

          const value = Number.isNaN(rawValue)
            ? 'No Data'
            : `${humanizeDecimalMinutes(
                rawValue,
                Math.abs(rawValue) < 1 ? 1 : 0
              )} (${numTrips} ${pluralize('trip', numTrips)})`;

          return { name, value };
        },
      },

      // Legend
      legendItems: seriesNames.map((series) => ({
        field: series,
        label: addSeriesLabel
          ? `${metricsNames[metric]}s (${series})`
          : metricsNames[metric],
        symbol: lineChartPath,
        stroke: getColorForMetricAndEquipmentState(metric, series),
      })),
      seriesField: 'series',
    };
  };

const AverageMetricsChart = ({ match, metric }) => {
  const { route: routeName } = match.params;
  const { chart, isLoading } = useMetricChart({ routeName, metric });

  const { dateRange } = useSelector(({ routeFilters }) => routeFilters);

  const createConfig = useCallback(
    (...rest) => createChartConfig({ metric })(...rest),
    [metric]
  );

  const isEmpty = !isLoading && !chart?.length;

  return isEmpty ? (
    <></>
  ) : (
    <TableCard>
      <CommonChart
        metric={metric}
        today={TODAY}
        graphType={GraphType.Line}
        contentType={ContentType.Time}
        data={chart}
        loading={isLoading}
        routeName={routeName}
        dateRange={dateRange}
        createConfig={createConfig}
        removeFrequency
      />
    </TableCard>
  );
};

export default withRouter(AverageMetricsChart);
