import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tabs, Skeleton } from 'antd';
import Map from '../Map';
import Card from '../Card';
import { clearSelectedSegment } from '../../../features/configuration/store/slice';
import './style.css';

const MapTabs = ({
  tabs,
  tabsLoading,
  defaultActiveKey,
  map,
  children,
  ...props
}) => {
  const [activePane, setActivePane] = useState(defaultActiveKey);
  const { selectedSegment } = useSelector(
    ({ configuration: { approachMapEditor } }) => approachMapEditor
  );
  const dispatch = useDispatch();

  const handleFocus = useCallback(() => {
    if (selectedSegment) {
      dispatch(clearSelectedSegment());
    }
  }, [dispatch, selectedSegment]);

  return (
    <Card paddingSize="none" className="map-tabs">
      {map || (
        <Map
          className="map-tabs__map"
          isLoading={false}
          isEmpty={true}
          selectedMapItemState={[]}
          gmapsShapes={[]}
          {...props}
        ></Map>
      )}
      <Card paddingSize="" className="map-tabs__tabs">
        {tabsLoading ? (
          <div className="tabs-skeleton">
            <Skeleton.Button
              active={true}
              style={{ height: 659, width: '100%' }}
            />
          </div>
        ) : (
          <Tabs
            defaultActiveKey={activePane}
            onChange={setActivePane}
            onFocus={handleFocus}
          >
            {tabs}
          </Tabs>
        )}
      </Card>
      {children}
    </Card>
  );
};

export default MapTabs;
