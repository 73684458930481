import React from 'react';
import BusStop from '../../../../common/icons/BusStop';
import Intersection from '../../../../common/icons/Intersection';
import './style.css';

const MapLegend = ({ label, icon, className, children }) => (
  <div className={`map-legend ${className}`}>
    <h3>Legend</h3>
    <p>{label}</p>
    {icon && icon({})}
    {children}
  </div>
);

const LowConfidenceMapLegend = ({ className, label, icon }) => (
  <MapLegend label={label} icon={icon} className={className}>
    <p>Low # of Trips</p>
    {icon({ lowConfidence: true })}
  </MapLegend>
);

const SignalDelayMapLegend = () => (
  <LowConfidenceMapLegend
    className="map-legend-signal-delay"
    label="Intersection"
    icon={({ lowConfidence }) => <Intersection lowConfidence={lowConfidence} />}
  />
);

const DwellTimeMapLegend = () => (
  <LowConfidenceMapLegend
    className="map-legend-dwell-time"
    label="Stop"
    icon={({ lowConfidence }) => (
      <BusStop size={'small'} lowConfidence={lowConfidence} />
    )}
  />
);

const DriveTimeMapLegend = () => (
  <MapLegend
    className="map-legend-drive-time"
    label="Stop"
    icon={() => <BusStop size={'small'} />}
  />
);

export default MapLegend;
export { SignalDelayMapLegend, DriveTimeMapLegend, DwellTimeMapLegend };
