import { Direction, ApproachChannels, RequesterModel } from './enums';

export const APPROACH_DIRECTIONS = {
  [Direction.Northbound]: 90,
  [Direction.Eastbound]: 180,
  [Direction.Southbound]: 270,
  [Direction.Westbound]: 360,
};

export const APPROACH_WIDTH = 60;

export const MARKER_RADIUS = 12;

export const APPROACH_COLORS = {
  [ApproachChannels.A]: 'rgb(100, 143, 255, 0.6)',
  [ApproachChannels.B]: 'rgb(255, 176, 0, 0.6)',
  [ApproachChannels.C]: 'rgb(254, 97, 0, 0.6)',
  [ApproachChannels.D]: 'rgb(227, 82, 153, 0.6)',
};

export const CHANNEL_MAPPINGS = {
  0: ApproachChannels.A,
  1: ApproachChannels.B,
  2: ApproachChannels.C,
  3: ApproachChannels.D,
};

export const CIRCLE_OPTIONS = {
  fillColor: 'White',
  fillOpacity: 1,
  strokeOpacity: 1,
  strokeWeight: 1,
  zIndex: 6,
};

export const APPROACHES_HELP_MODAL_TEXT = [
  'Click on any segment to select it and enter editing mode',
  "Click 'Create Approach' to enter drawing mode. Click on the map to draw a new approach, then double click to stop drawing",
  'Click the trashcan icon next to the highlighted row in the modal to delete the selected segment',
  'Doubleclick to the width value in the item list to open the editable text field',
  'Click the check circle icon or press the Enter key to save the edited width for the selected segment',
  'Click closecircle icon to the cancel the editable width of the selected segment',
];

export const APPROACHES_KEYSTROKES_MODAL_TEXT = [
  'Hit Backspace or Delete to delete a selected segment',
  'Hit Escape to deselect a segment and exit editing mode',
  'Hit Backspace or Delete to clear the eidtable input width value for selected segment',
];

export const CHANNEL_TYPES = ['A', 'B', 'C', 'D'];

export const NO_CONNECTION_ERROR_MESSAGE =
  "No connection is available at this time. Click 'X' to close the popup window or click 'Retry' to retry add intersection.";

export const CANCEL_BUTTON_REDIRECT_URL = '/config/intersections';

export const PHYSICAL_MODELS = [
  RequesterModel.M764,
  RequesterModel.G764,
  RequesterModel.C764,
];
